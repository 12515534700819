import React, { Component } from 'react';

import { Edit, Visibility } from '@material-ui/icons/';
import { CustomDataTable } from '../../components/UI/CustomDataTable';


import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';



export class ReserveWithRecipe extends Component {

    constructor(props) {
        super(props);
        this.state = {
            columns:[],            
        };

        this.dataTableRef = React.createRef();
    } 
    
    async componentDidMount() {

        const columns = [
            {
                name: 'Id',
                selector: 'id',
                sortable: true,
                width: '90px',
                hide: 'sm',

            },
            {
                name: 'Fecha',
                selector: 'date',
                sortable: true,
            },

            {
                name: 'Nombre',
                selector: 'name',
                sortable: true,
            },

            {
                name: 'Apellido',
                selector: 'lastName',
                sortable: true,
                hidden: 'sm'
            },

            {
                name: 'Télefono movil',
                selector: 'mobileNumber',
                sortable: true,
                hidden: 'sm'
            },


            {
                name: 'Email',
                selector: 'email',
                sortable: true,
                hidden: 'sm'
            },

            {
                name: 'Obra social',
                selector: 'prepaidHealth',
                sortable: true,
                hidden: 'sm'
            },

            {
                name: 'Froma de pago',
                selector: 'paymentType',
                sortable: true,
                hidden: 'sm'
            },

            {
                name: 'Opciones',
                button: true,
                cell: row =>
                    <Link to={"/ver-receta/" + row.id}>
                        <Tooltip title="Ver">
                            <IconButton aria-label="Editar">
                                <Visibility />
                            </IconButton>
                        </Tooltip>
                    </Link>
                ,
                allowOverflow: true,
                width: '60px',
            },       

        ];

        this.setState({
            columns: columns,                              
        });

      
    }

    

 



    render() {
      
        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={7} md={8} lg={10}>

                        <h4>
                            Reservas con receta
                            <small >
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Typography color="inherit">
                                        Sitio
                                </Typography>
                                    <Typography color="textPrimary">     Reservas con receta</Typography>
                                </Breadcrumbs>
                            </small>
                        </h4>
                    </Grid>
                </Grid>

                <hr/>

                <CustomDataTable
                    url="/api/reserveWithRecipe"
                    columns={this.state.columns}
                    handleOpenModal={this.handleOpenModal}
                    ref={this.dataTableRef}
                    showHeader={true}
                />
            </div>
        );
    }
}
