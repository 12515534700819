import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import UserContext from '../userContext';
import ExitToApp from '@material-ui/icons/ExitToApp';
import { Divider } from '@material-ui/core';
import { deleteToken } from '../helpers/auth-helper';
const localVersion = localStorage.getItem('adminFarmanobelVersion');



export class LoginMenu extends Component {
	constructor(props) {
		super(props);
	}

	static contextType = UserContext;

	handleCloseSignOut = (e) => {

		const { logoutUser } = this.context;
		deleteToken(e);
		logoutUser();
	}

	render() {
		var { user } = this.context; 
		return (
			<div>
				{user.isAuth ?
					<div style={{ paddingLeft: '16px', paddingRight: '16px' }}>
						Hola {user.email}

						<br />

						<Button

							color="secondary"
							startIcon={<ExitToApp />}
							size="small"
							onClick={this.handleCloseSignOut}
						>
							Cerrar sesión
						</Button>


						<br />
						<br />

						<small style={{ color: '#666' }}>{"v 0." + localVersion + ".25"}</small>

						<Divider />

					</div>
					: null}
				</div>
				);
	}
}