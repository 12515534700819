import React, { Component } from 'react';
import { axiosInstance } from '../../utils/axiosInstance';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import Grid from '@material-ui/core/Grid'
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import store from '../../store';
import { Paper } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { CustomDataTable } from '../../components/UI/CustomDataTable';

import Tooltip from '@material-ui/core/Tooltip';
import { Add } from '@material-ui/icons/';
import IconButton from '@material-ui/core/IconButton';
import { CustomImageGallery } from '../../components/customImageGallery';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow'; 
import { Delete } from '@material-ui/icons';
import { Done } from '@material-ui/icons';
import { Close } from '@material-ui/icons';
import MenuItem from '@material-ui/core/MenuItem';
import Alert from '@material-ui/lab/Alert';

export class FlyerForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ID: 0,
            name: '',
      
            title: '',
            subTitle: '',
            habilit: '',

            imagenes: [],
            selectedProducts: [],

            validFrom: null,
            validUntil: null,

        };

        this.ID = this.props.match.params.value;
        this.isEdit = this.props.match.params.value != undefined;

        this.updateDocumentCallBackRemove = this.updateDocumentCallBackRemove.bind(this)
        this.updateDocumentCallBack = this.updateDocumentCallBack.bind(this)
        
    }


    async executeRESTCall(action) {
        const url = '/api/Flyer/';


        var data = {
            name: this.state.name,
            title: this.state.title,
            subTitle: this.state.subTitle,
            habilit: this.state.habilit,
            imagenes: this.state.imagenes,
            validFrom: this.state.validFrom,
            validUntil: this.state.validUntil,
            selectedProducts: this.state.selectedProducts,
        }

        if (action === "POST") {
            await axiosInstance.post(url, data)
                .then(response => {

                    this.props.history.push('/flyers');
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: "Operación exitosa"
                    })

                }).catch(error => {
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: error.response.data.message
                    })
                });
        }

        if (action === "PUT") {
            await axiosInstance.put(url + this.ID, this.state)
                .then(response => {

                    this.props.history.push('/flyers');

                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: "Operación exitosa"
                    })

                }).catch(error => {
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: error.response.data.message
                    })
                });
        }     

    
    }
   

    onSubmit = e => {

        if (!this.isEdit) {
            this.executeRESTCall("POST");
        }
        else {
            this.executeRESTCall("PUT");
        }
    
        e.preventDefault();
    }


    onChange = (event, value) => {

        if (event.target.type === "checkbox") {
            this.setState({
                [event.target.name]: event.target.checked,
            })
        }
        else {

            this.setState({
                [event.target.name]: event.target.value
            })
        }
    }

    async componentDidMount() {

        const columns = [

            {
                name: 'imagen',
                width: '125px',
                hide: 'sm',
                cell: row =>

                    <img src={row.defaultImage} width="90px" />
            }
            ,

            {
                name: 'Producto',
                selector: 'name',
                sortable: true,
                cell: row => <div>
                    <b>{row.name}</b>
                    <p>{row.shortDescription}</p>
                    <div>{ row.enabled === true ? <Done style={{ color: 'green' }} /> : <Close style={{ color: 'red' }} />}</div>
                </div>
            },



            {
                cell: row =>
                    <Tooltip title="Agregar producto">
                        <IconButton
                            aria-label="add"
                            onClick={this.handleAddClick}
                            data-row_id={row.id}
                            data-row_text={row.name}>
                            <Add color="primary" />
                        </IconButton>
                    </Tooltip>
                ,
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
                width: '60px',
            },

        ];

        this.setState({
            columns: columns,
        });

  

        const discountResponse = await axiosInstance.get(`/api/Discount/GetDiscountsForSelect`);               
        this.setState({
            discounts: discountResponse.data,

        });

        if (this.isEdit) {
            await axiosInstance.get(`api/Flyer/GetByID/${this.ID}`).then(
                response => {
                    this.setState({
                        ID: response.data.id,
                        name: response.data.name,
                        percentage: response.data.percentage,
                        title: response.data.title,
                        subTitle: response.data.subtitle,
                        habilit: response.data.habilit,
                        imagenes: response.data.imagenes,
                        selectedProducts: response.data.selectedProducts,
                        validFrom: response.data.validFrom,
                        validUntil: response.data.validUntil

                    });
                });
        }
    }

    CreateStyles() {
        return {            
            marginRight: '10px',
            marginBottom: '10px',
            float: 'right'
        }
    }

    updateDocumentCallBack(imagen) {


        console.log("updateDocumentCallBack 1");

        this.setState(
            { imagenes: [...this.state.imagenes, imagen] }
        )


        console.log("updateDocumentCallBack 2");

    }

    updateDocumentCallBackRemove(imagen) {

        this.setState({
            imagenes: this.state.imagenes.filter(function (imagen2) {
                return imagen2 !== imagen
            })
        });

    }

    handleAddClick = async e => {

        var productId = parseInt(e.currentTarget.dataset.row_id);
        var text = e.currentTarget.dataset.row_text;


        var productToAdd = {
            id: 0,
            productId: productId,
            text: text,
        };

        const found = this.state.selectedProducts.find(element => element.productId === productId);

        if (found === undefined) {
            this.setState(state => {
                const selectedProducts = state.selectedProducts.concat(productToAdd);

                return {
                    selectedProducts,
                };
            });
        }


    }

    removeProduct(index) {

        let selectedProducts = [...this.state.selectedProducts];
        selectedProducts.splice(index, 1);
        this.setState({
            selectedProducts: selectedProducts
        });

    }

    renderDiscountOptions() {
        return this.state.discounts.map((dt, i) => {
            return (
                <MenuItem
                    label="Seleccione un descuento"
                    value={dt.id}
                    key={i} name={dt.text}>{dt.text}
                </MenuItem>
            );
        });
    }


    onChangeDiscount = (event, index) => {

        let selectedProducts = [...this.state.selectedProducts];

        let selectedProduct = { ...selectedProducts[index] };

        if (event.target.name == "discountID") {
            selectedProduct.discountID = event.target.value;
        }



        selectedProducts[index] = selectedProduct;

        this.setState({ selectedProducts });

    }

    handleHabilitarFlyerClick = async () => {

        await axiosInstance.put('/api/Flyer/Habilitar/' + this.ID)
            .then(response => {

                this.props.history.push('/flyers');


                store.dispatch({
                    type: "SNACKBAR_SUCCESS",
                    message: "Operación exitosa"
                })

            }).catch(error => {
                store.dispatch({
                    type: "SNACKBAR_SUCCESS",
                    message: error.response.data.message
                })
            });
    }

    handleDesHabilitarFlyerClick = async () => {
        await axiosInstance.put('/api/Flyer/DesHabilitar/' + this.ID)
            .then(response => {

                this.props.history.push('/flyers');


                store.dispatch({
                    type: "SNACKBAR_SUCCESS",
                    message: "Operación exitosa"
                })

            }).catch(error => {
                store.dispatch({
                    type: "SNACKBAR_SUCCESS",
                    message: error.response.data.message
                })
            });
    }

    HeaderTitle() {
        let title;

        if (this.isEdit) {
            title = 'Editando flyer';
        }
        else {
            title ='Crea un nuevo flyer';
        }

        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                        <h4>
                            {title}
                            <small >
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Typography color="inherit">
                                        Promociones
                                        </Typography>

                                    <Link color="inherit" to="/flyers" >
                                        <Typography color="textPrimary">Flyers</Typography>
                                </Link>

                                </Breadcrumbs>
                            </small>
                        </h4>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <Link to="/flyers">
                            <Button
                                variant="default"
                                color="default"
                                size="small"
                                startIcon={<CancelIcon />}
                                style={this.CreateStyles()}
                            >
                                Cancelar
                        </Button>
                        </Link>
                    </Grid>
                </Grid>

                <hr />
            </div>
        );

        
    }

    render() {


        return (

            <div>

                {this.HeaderTitle()}

               
            
                    <ValidatorForm ref="form"
                        onSubmit={this.onSubmit}
                        onError={errors => console.log(errors)}
                        style={{ padding: "10px" }}>      

                        <Grid container spacing={2} >

                        <Grid item xs={12} sm={12} md={12} lg={12}>


                            {this.state.habilit ?

                                <Alert severity="warning" className="mt-15 mb-30"><b>Atención</b>Para editar el flyer primero tiene que deshabilitarlo en la grilla de flyers </Alert>


                                :

                                <>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="medioum"
                                        startIcon={<SaveIcon />}
                                        type="submit"
                                    >
                                        Guardar
                                </Button>

                                </>
                            }



                             
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={4}>
                                <Paper style={{ padding: '15px' }} elevation={1}>

                                    <Grid container spacing={2} >
                       
                                        <Grid item sm={12} xs={12}>
                                            <TextValidator id="name"
                                                style={{ width: "100%" }}
                                                name="name"
                                                label="Referencia"
                                                variant="outlined"
                                                size="small"
                                                onChange={this.onChange}
                                                value={this.state.name}
                                                validators={['required', 'maxStringLength:80']}
                                                errorMessages={['El nombre es requerido', 'Máximo 80 carácteres']}
                                                autoFocus 
                                                autoComplete="off"
                                            />
                                        </Grid>
                                  
                                    <Grid item sm={12} xs={12}>

                                        <TextValidator id="title"
                                            style={{ width: "100%" }}
                                            name="title"
                                            label="Título"
                                            variant="outlined"
                                            size="small"
                                            onChange={this.onChange}
                                            value={this.state.title}
                                            validators={['required', 'maxStringLength:140']}
                                            errorMessages={['Requerido', 'Máximo 140 carácteres']}
                                            autoComplete="off"
                                        />


                                    </Grid>

                                    <Grid item sm={12} xs={12}>

                                        <TextValidator id="subTitle"
                                            style={{ width: "100%" }}
                                            name="subTitle"
                                            label="Subtítulo"
                                            variant="outlined"
                                            size="small"
                                            onChange={this.onChange}
                                            value={this.state.subTitle}
                                            validators={['maxStringLength:80']}
                                            errorMessages={['Máximo 80 carácteres']}
                                            autoComplete="off"
                                        />


                                    </Grid>

                               



                                    <Grid item sm={12} xs={12}>
                                        <TextValidator
                                            InputLabelProps={{ shrink: true }}
                                            label="Válido desde"
                                            className="iron-form-input-wrap"
                                            onChange={this.onChange}
                                            name="validFrom"
                                            type="date"
                                            value={this.state.validFrom}
                                            size="small"
                                            placeholder="DD/MM/YYYY"
                                            variant="outlined"
                                            validators={['required']}
                                            errorMessages={['La fecha es requerida']}
                                        />
                                    </Grid>


                                    <Grid item sm={12} xs={12}>



                                        <TextValidator
                                            InputLabelProps={{ shrink: true }}
                                            label="Válido desde"
                                            className="iron-form-input-wrap"
                                            onChange={this.onChange}
                                            name="validUntil"
                                            type="date"
                                            value={this.state.validUntil}
                                            size="small"
                                            placeholder="DD/MM/YYYY"
                                            variant="outlined"
                                            validators={['required']}
                                            errorMessages={['La fecha es requerida']}

                                        />

                                    </Grid>

                              

                                        <Grid item xs={12} md={12}>
                                       

                                </Grid>
                          
                          
                
                                </Grid>

                            </Paper>

                  
                        </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={8}>
                                <Paper style={{ padding: '15px' }} elevation={1}>
                                    <CustomImageGallery
                                        updateDocumentCallBack={this.updateDocumentCallBack}
                                        updateDocumentCallBackRemove={this.updateDocumentCallBackRemove}
                                        imagenes={this.state.imagenes}
                                        cantidadLimite={6}
                                        action="SaveFlyerImage"
                                        deleteAction="DeleteFlyerImage"
                                        updateOrderAction="SetFlyerImageOrder"
                                        title="Folder digital"
                                    />

                                    </Paper>

                            </Grid>


                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Paper style={{ padding: '15px' }} elevation={1}>

                                <h4>Buscador de productos</h4>

                                <CustomDataTable
                                    url="api/Product/"
                                    columns={this.state.columns}
                                    handleOpenModal={this.handleOpenModal}
                                    ref={this.dataTableRef}
                                    showHeader={true}
                                />
                            </Paper>
                        </Grid>


                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Paper style={{ padding: '15px' }} elevation={1}>

                                <h4>Productos del flyer</h4>

                                <Table aria-label="simple table" size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Producto</TableCell>
                                            <TableCell align="center"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            this.state.selectedProducts.map((productGroup, index) => {

                                                return (
                                                    <TableRow key={index}>

                                                        <TableCell>{productGroup.text}</TableCell>
                                                        <TableCell>
                                                            <SelectValidator
                                                                style={{ width: "100%" }}
                                                                name="discountID"
                                                                variant="outlined"
                                                                size="small"
                                                                label="Descuento"
                                                                value={productGroup.discountID}
                                                                validators={['required']}
                                                                errorMessages={['El descuento es requerido']}
                                                                onChange={(e) => this.onChangeDiscount(e, index)}>
                                                                {this.renderDiscountOptions()}

                                                            </SelectValidator>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Tooltip title="Eliminar producto">
                                                                <IconButton style={{ padding: '0px' }}
                                                                    aria-label="add"
                                                                    onClick={(e) => this.removeProduct(index)}
                                                                >
                                                                    <Delete color="secondary" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Grid>
                        
                    </Grid>

                    </ValidatorForm>
            </div>
        )
    }
}