import React, { Component } from 'react';
import { axiosInstance } from '../../utils/axiosInstance';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import Grid from '@material-ui/core/Grid'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import store from '../../store';
import { Paper, FormControlLabel, Checkbox } from '@material-ui/core';


export class UserForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: 0,
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            sellerPlataformaCode: '',
            showPlataformaSellerCodeInput: '',
            roles: [],



        };;

        this.id = this.props.match.params.value;
        this.isEdit = this.props.match.params.value != undefined;
        this.onSubmit = this.onSubmit.bind(this);

    }


  
   

    async onSubmit(e) {
        if (!this.isEdit) {
            this.executeRESTCall("POST");
        }
        else {
            this.executeRESTCall("PUT");
        }

        e.preventDefault();
    }


    onChange = (event, value) => {



            this.setState({
                [event.target.name]: event.target.value
            })
        
    }

    async componentDidMount() {

 
        const rolesResponse = await axiosInstance.get(`/api/RoleAdmin/GetRoles`);               

        this.setState({
            roles: rolesResponse.data,
        });


        if (this.isEdit) {
            await axiosInstance.get(`api/UserAdmin/GetByID/${this.id}`).then(
                response => {

                    this.setState({
                        id: response.data.id,
                        firstName: response.data.firstName,
                        lastName: response.data.lastName,
                        email: response.data.email,
                        roles: response.data.roles,
                        showPlataformaSellerCodeInput: response.data.isSeller,
                        sellerPlataformaCode: response.data.sellerPlataformaCode,
                    });

                });
        }
    }

    CreateStyles() {
        return {                 
            float: 'right'
        }
    }

    HeaderTitle() {
        let title;

        if (this.isEdit) {
            title = 'Editando usuario';
        }
        else {
            title ='Crea un nuevo usuario';
        }

        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                        <h4>
                            {title}
                            <small >
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Typography color="inherit">
                                        Configuración
                                        </Typography>

                                    <Link color="inherit" to="/Marcas" >
                                        <Typography color="textPrimary">Usuarios</Typography>
                                </Link>

                                </Breadcrumbs>
                            </small>
                        </h4>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <Link to="/Usuarios">
                            <Button
                                variant="default"
                                color="default"
                                size="small"
                                startIcon={<CancelIcon />}
                                style={this.CreateStyles()}
                            >
                                Cancelar
                        </Button>
                        </Link>
                    </Grid>
                </Grid>

                <hr />
            </div>
        );

        
    }

    onChangeCheckBox = (event) => {




        if (event.target.name === "Vendedor" && event.target.checked) {
            this.setState({
                showPlataformaSellerCodeInput: true,
            });
        } else {
            this.setState({
                showPlataformaSellerCodeInput: false,
            });
		}

        let roles = this.state.roles;

        roles.forEach(role => {
            if (role.name === event.target.name)
                role.isChecked = event.target.checked
        })

        this.setState({ roles: roles })
    }


    async executeRESTCall(action) {


        if (action === "POST") {
            await axiosInstance.post('/api/UserAdmin/', this.state)
                .then(response => {

                    this.props.history.push('/usuarios');

                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: "Operación exitosa"
                    })

                }).catch(error => {
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: error.response.data.message
                    })
                });
        }

        if (action === "PUT") {
            await axiosInstance.put('/api/UserAdmin/', this.state)
                .then(response => {

                    this.props.history.push('/usuarios');

                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: "Operación exitosa"
                    })

                }).catch(error => {
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: error.response.data.message
                    })
                });
        }






    }   

    render() {

       
        return (

            <div>

                {this.HeaderTitle()}
            
                <Paper style={{ padding: '15px' }} elevation={1}>
                    <ValidatorForm ref="form"
                        onSubmit={this.onSubmit}
                        onError={errors => console.log(errors)}
                        style={{ padding: "10px" }}>               

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Grid container spacing={2}>         
                                    
                                        <Grid item sm={12} xs={12}>
                                            <TextValidator id="firstName"
                                                style={{ width: "100%" }}
                                                name="firstName"
                                                label="Nombre"
                                                variant="outlined"
                                                size="small"
                                                onChange={this.onChange}
                                                value={this.state.firstName}
                                                validators={['required', 'maxStringLength:50']}
                                        errorMessages={['El nombre es requerido', 'Máximo 50 carácteres']}
                                        autoFocus 
                                        autoComplete="off"
                                            />
                                        </Grid>

                                        <Grid item sm={12} xs={12}>
                                            <TextValidator id="lastName"
                                                style={{
                                                    width: "100%"
                                                }}
                                                name="lastName"
                                                label="Apellido"
                                                variant="outlined"
                                                size="small"
                                                onChange={this.onChange}
                                                value={this.state.lastName}
                                            validators={['required', 'maxStringLength:50']}
                                            errorMessages={['El nombre es requerido', 'Máximo 50 carácteres']}
                                                autoComplete="off"
                                            />
                                        </Grid>


                                    <Grid item sm={12} xs={12}>
                                        <TextValidator id="email"
                                            style={{
                                                width: "100%"
                                            }}
                                            name="email"
                                            label="E-mail"
                                            variant="outlined"
                                            size="small"
                                            onChange={this.onChange}
                                            value={this.state.email}
                                            validators={['required', 'maxStringLength:50']}
                                            errorMessages={['El email es requerido', 'Máximo 50 carácteres']}
                                            autoComplete="off"
                                            type="email"
                                        />
                                    </Grid>

                                    {this.isEdit ? null :
                                        <Grid item sm={12} xs={12}>
                                            <TextValidator id="password"
                                                style={{
                                                    width: "100%"
                                                }}
                                                name="password"
                                                label="Contraseña"
                                                variant="outlined"
                                                size="small"
                                                onChange={this.onChange}
                                                value={this.state.password}
                                                validators={['required', 'maxStringLength:50']}
                                                errorMessages={['La contraseña es requerida', 'Máximo 50 carácteres']}
                                                autoComplete="off"
                                            />
                                        </Grid>
                                    }


                                    <Grid item sm={12} xs={12}>
                                        <h4>Perfiles</h4>

                                        {
                                            this.state.roles.map((role, i) => {
                                                return (
                                                    <div key={i}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                checked={role.isChecked}
                                                                onChange={this.onChangeCheckBox}                                                            
                                                                name={role.name}
                                                                />}
                                                            label={role.name}
                                                            />
                                                    </div>

                                                );

                                            })
                                        }
                                    </Grid>

                                    {this.state.showPlataformaSellerCodeInput ?

                                        <Grid item sm={12} xs={12}>
                                            <TextValidator id="sellerPlataformaCode"
                                                style={{
                                                    width: "100%"
                                                }}
                                                name="sellerPlataformaCode"
                                                label="Código vendedor plataforma"
                                                variant="outlined"
                                                size="small"
                                                onChange={this.onChange}
                                                value={this.state.sellerPlataformaCode}
                                                validators={['required', 'maxStringLength:50']}
                                                errorMessages={['La código de vendedor es requerido', 'Máximo 50 carácteres']}
                                                autoComplete="off"
                                            />
                                        </Grid>

                                        : null
                                    }

                                   
                                    
                                    </Grid>
                            </Grid>                        

                                              
                        </Grid>

                        <Grid container spacing={2}>

                            <Grid item xs={12} md={12}>

                                <hr style={{marginTop:'30px'}}/>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="medium"
                                    startIcon={<SaveIcon />}
                                    type="submit"
                                    style={this.CreateStyles()}
                                >
                                    Guardar
                                </Button>
                            </Grid>
                        </Grid>

                    </ValidatorForm>
                </Paper>
            </div>
        )
    }
}