import React, { Component } from 'react';
import { axiosInstance } from '../../utils/axiosInstance';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import Done from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import Grid from '@material-ui/core/Grid'
import { Paper } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { IconButton, Tooltip } from '@material-ui/core/';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import { Visibility, Edit, Save, Payment } from '@material-ui/icons/';
import MenuItem from '@material-ui/core/MenuItem';

import { Delete } from '@material-ui/icons';
import store from '../../store';


export class OrderForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            orderID: 0,
            name: '',
            details: [],
            order: null,
            dialogOpen: false,
            orderStateID: '',
            ordersStates: [],


        };;

        this.orderID = this.props.match.params.value;

    }


 


    async componentDidMount() {
        await this.getEntity();
        const ordersStatesResponse = await axiosInstance.get('/api/Order/GetOrdersStatesForSelect');

        this.setState({
            ordersStates: ordersStatesResponse.data
        });

    }

    async getEntity() {
        await axiosInstance.get(`api/Order/GetByID/${this.orderID}`).then(
            response => {
                this.setState({
                    order: response.data,
                });
            });
	}

    CreateStyles() {
        return {            
            marginRight: '10px',
            marginBottom: '10px',
            float: 'right'
        }
    }

    HeaderTitle() {
        let title;

    
            title ='Pedido';
    

        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                        <h4>
                            {title}
                            <small >
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Typography color="inherit">
                                        Pedido
                                        </Typography>


                                </Breadcrumbs>
                            </small>
                        </h4>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <Link to="/Pedidos">
                            <Button
                                variant="default"
                                color="default"
                                size="small"
                                startIcon={<CancelIcon />}
                                style={this.CreateStyles()}
                            >
                                Volver
                        </Button>
                        </Link>
                    </Grid>
                </Grid>

                <hr />
            </div>
        );

        
    }

    renderMailCompany() {

        const { order } = this.state;

        if (order.retire.retireType !== 'Retiro por farmacia') {
            return (
                <div>
                    <h5>{order.retire.retireType}</h5>
                    <ul style={{ listStyle: 'none', paddingLeft: '0px' }}>

                        <li>
                            A cargo de: {order.retire.mailCompany}
                        </li>
                        <li>
                            Código postal: {order.retire.postalCode}
                        </li>
                        <li>
                            Dirección: {order.retire.deliveryAddress} 
                        </li>
                 
                        <li>
                            {order.retire.housingType}
                        </li>
                        <li>
                            Llega el: {order.retire.estimatedDeliveryDate}
                        </li>
                        <li>Recibe: {order.retire.receivePersonName}</li>
                        <li>Costo: $ {order.retire.shippingCost}</li>
                        <li>
                            Peso total: {order.totalWeight} grs
                            </li>
                    </ul>
                </div>
            );
        }
        else {
            return null;
        }


    }

    render2() {

        const { order } = this.state;

        if (order.retire.retireType === 'Retiro por farmacia') {
            return (
                <div>
                    <h5>{order.retire.retireType}</h5>
                    <ul style={{ listStyle: 'none', paddingLeft: '0px' }}>

                        <li>
                            Retira: {order.retire.nameOfWhoWithdraws}
                        </li>
                        <li>
                            Farmacia: {order.retire.selectedStore}
                        </li>
                    </ul>
                </div>
            );
        }
        else {
            return null;
        }


    }

    async handleDeleteClick(row) {



        await axiosInstance.delete('/api/Order/DeleteOrderState/' + row.orderStateID)
            .then(response => {

                this.getEntity();

                //store.dispatch({
                //    type: "SNACKBAR_SUCCESS",
                //    message: "Operación exitosa"
                //})

            }).catch(error => {
                //store.dispatch({
                //    type: "SNACKBAR_SUCCESS",
                //    message: error.response.data.message
                //})
            });




    }

    handleDialogClose = (event, newValue) => {

        this.setState({
            dialogOpen: false,
        })
    };


    renderOrdersStatesOptions() {
        return this.state.ordersStates.map((dt, i) => {
            return (


                <MenuItem
                    label="Seleccione un estado"
                    value={dt.id}
                    key={i} name={dt.text}>{dt.text}
                </MenuItem>

            );
        });
    }

    handleClickDialogOpen() {

    

        this.setState({
       
            dialogOpen: true,
        })
    };

    onChange = (event, value) => {

        this.setState({
            [event.target.name]: event.target.value
        })
    }


    onSubmit = async (e) => {


        let formData = {
            orderID: this.state.order.orderID,
            orderStateID: this.state.orderStateID,
        };

        await axiosInstance.put('/api/Order/UpdateOrderState', formData)
            .then(response => {

                this.setState({
                    dialogOpen: false,
                });

                this.getEntity();


            }).catch(error => {
                store.dispatch({
                    type: "SNACKBAR_SUCCESS",
                    message: error.response.data.message
                })
            });







        e.preventDefault();


    }

    render() {


        const { order, dialogOpen } = this.state;


        return (

            <div>

                {this.HeaderTitle()}

                {this.state.order === null ? "" :


                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Paper style={{ padding: '15px' }} elevation={1}>

                                <h5>
                                    Pedido #{order.orderID}
                                    <br />
                                    <small style={{ fontSize: '0.9rem' }}>{order.date}</small>
                                    <br />
                                    <small>Estado: {order.state}</small>

                                </h5>

                                <hr />

                                <br />


                                <Grid container spacing={2} >
                                    <Grid item xs={12} sm={12} md={4} lg={3}>

                                        <h5>Cliente</h5>

                                        <ul style={{ listStyle: 'none', paddingLeft: '0px' }}>

                                            <li>
                                                Nombre: {order.client.lastName}, {order.client.firstName}
                                            </li>
                                            <li>
                                                Teléfono móvil: {order.client.mobileNumber}
                                            </li>
                                            <li>
                                                Correo: {order.client.email}
                                            </li>
                                            <li>
                                                Número de documento: {order.client.docNumber}
                                            </li>
                                        </ul>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={4} lg={3}>
                                        {this.renderMailCompany()}
                                        {this.render2()}
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={4} lg={3}>
                                        <h5>
                                            Mercado pago
                                        </h5>

                                        <ul style={{ listStyle: 'none', paddingLeft: '0px' }}>

                                            <li>
                                               PaymenthID: #{order.paymentID}
                                            </li>
                                            <li>
                                                PaymentType: {order.paymentType}
                                            </li>
                                            <li>
                                                PaymentMetohodId: {order.paymentMetohodId}
                                            </li>
                                          
                                            <li>
                                                Estado: {order.paymentStatus} {order.paymentStatus !== 'approved' ? <CancelIcon color="secondary" /> : <Done style={{ color: 'green' }} />}
                                            </li>
                                            <li>
                                                Detalle estado: {order.paymentStatusDetail}
                                            </li>
                                        
                                       
                                            <li>
                                                Fecha de aprobación: {order.paymentDateApproved}
                                            </li>
                                            <li>
                                                Hora de aprobación: {order.paymentHourApproved}
                                            </li>
                                            <li>
                                                Cuotas: {order.installment}
                                            </li>

                                            <li>
                                                Fecha de notificación: {order.notificationDate}
                                            </li>

                                        </ul>


                                    </Grid>

                                    <Grid item xs={12} sm={12} md={4} lg={3}>
                                        <h5>
                                            Estados    <Tooltip title="Editar estado">
                                                <IconButton aria-label="" onClick={() => this.handleClickDialogOpen()}>
                                                    <Edit />
                                                </IconButton>
                                            </Tooltip>

                                        </h5>

                                    

                                        <Table aria-label="simple table" size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center">Fecha</TableCell>
                                                    <TableCell align="center">Estado</TableCell>
                                                    <TableCell></TableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>

                                        {order.orderStatesLogs.map((row) => (
                                            <TableRow key={1}>
                                                <TableCell align="center">{row.date}</TableCell>
                                                <TableCell align="center">{row.state}</TableCell>
                                                <TableCell>

                                                    <Tooltip title="Eliminar">
                                                        <IconButton
                                                            aria-label="delete"
                                                            onClick={()  => this.handleDeleteClick(row)}
                                                            
                                                        
                                                        >
                                                            <Delete color="secondary" />
                                                        </IconButton>
                                                    </Tooltip>

                                                </TableCell>
                                            </TableRow>
                                        ))}

                                            </TableBody>
                                        </Table>


                                    </Grid>

                                </Grid>

                                <Grid container spacing={2}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>

                                                <TableCell>Producto</TableCell>
                                                <TableCell align="right">Cantidad</TableCell>
                                                <TableCell align="right">Total</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {order.details.map((row) => (
                                                <TableRow key={1}>
                                                    <TableCell component="th" scope="row">
                                                        <img src={row.image} style={{ width: '125px' }} />
                                                      
                                                    </TableCell>
                                                    
                                                    <TableCell component="th" scope="row">
                                             
                                                        <h6>{row.product}</h6>
                                                        <p>{row.shortDescription}</p>
                                                        <p><b>Código: </b>{row.barCode}</p>
                                                        <p><b>Categoría: </b>{row.productCategory}</p>
                                                        <p><b>Marca: </b>{row.productBrand}</p>
                                                    </TableCell>
                                                    <TableCell align="right">{row.quantity}</TableCell>
                                                    <TableCell align="right">{row.price}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Grid>

                                <Grid container spacing={2} style={{ paddingTop: '15px' }}>
                                    <Grid item xs={12}>
                                        <strong style={{ float: 'right' }}>Subtotal: {order.subTotal}</strong>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <strong style={{ float: 'right' }}>Envío: ${order.retire.shippingCost}</strong>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <h5 style={{ float: 'right' }}>Total: {order.total}</h5>
                                    </Grid>
                                </Grid>


                           


                            </Paper>
                        </Grid>
                    </Grid>
                }















                <Dialog
                    open={dialogOpen}
                    onClose={this.handleDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth="md"
                >
                    <DialogTitle id="alert-dialog-title">{"Estado del pedido"}</DialogTitle>
                    <ValidatorForm ref="form"
                        onSubmit={this.onSubmit}
                        onError={errors => console.log(errors)}
                    >
                        <DialogContent>

                           

                            <SelectValidator
                                style={{ width: "100%" }}
                                name="orderStateID"
                                variant="outlined"
                                size="small"
                                label="Estado"
                                value={this.state.orderStateID}
                                validators={['required']}
                                errorMessages={['El estado es requerido']}
                                onChange={this.onChange}>
                                {this.renderOrdersStatesOptions()}

                            </SelectValidator>
                        </DialogContent>

                        <DialogActions>

                            <Button type="submit" color="primary" autoFocus
                                variant="contained"

                            >
                                <Save /> Guardar
                        </Button>

                            <Button onClick={this.handleDialogClose} color="default">
                                Cancelar
                        </Button>


                        </DialogActions>
                    </ValidatorForm>
                </Dialog>














            </div>
        )
    }
}