import React, { Component } from 'react';
import { CustomDataTable } from '../../components/UI/CustomDataTable';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

export class Client extends Component {

    constructor(props) {
        super(props);
        this.state = {
            columns:[],            
        };

        this.dataTableRef = React.createRef();
    } 
    
    async componentDidMount() {

        const columns = [
            {
                name: 'Id',
                selector: 'id',
                sortable: true,
                width: '90px',
                hide: 'sm',

            },

        
            {
                name: 'Nombre',
                selector: 'firstName',
                sortable: true,
            },

            {
                name: 'Apellido',
                selector: 'lastName',
                sortable: true,
            },

            {
                name: 'Correo',
                selector: 'email',
                sortable: true,
            },
            {
                name: 'Número celular',
                selector: 'mobileNumber',
                sortable: true,
            },           
        ];

        this.setState({
            columns: columns,                              
        });      
    }
   
    render() {
      
        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={7} md={8} lg={10}>

                        <h4>
                            Sitio
                            <small >
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Typography color="inherit">
                                        Usuarios
                                </Typography>
                                    <Typography color="textPrimary">Usuarios</Typography>
                                </Breadcrumbs>
                            </small>
                        </h4>
                    </Grid>
                    <Grid item xs={12} sm={5} md={4} lg={2}>

                    
                    </Grid>
                </Grid>

                <hr/>

                <CustomDataTable
                    url="api/Client"
                    columns={this.state.columns}
                    handleOpenModal={this.handleOpenModal}
                    ref={this.dataTableRef}
                    showHeader={true}
                />
            </div>
        );
    }
}
