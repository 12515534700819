import React, { Component } from 'react';

import { axiosInstance } from '../../utils/axiosInstance';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import Grid from '@material-ui/core/Grid'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import store from '../../store';
import { Paper } from '@material-ui/core';
import ImageUploader from 'react-images-upload';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export class BrandForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: 0,
            description: '',
            pageSubtitle: '',
            url: '',

            image: '',
            defaultImage: '',
            form: new FormData(),
            showInHome: false,
        };;

        this.id = this.props.match.params.value;
        this.isEdit = this.props.match.params.value != undefined;
        this.onDropImage = this.onDropImage.bind(this);
        this.onDropImageThumbnail = this.onDropImageThumbnail.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        
    }


  
   

    async onSubmit(e) {
        e.preventDefault();
        await this.addImage();
    }


    onChange = (event, value) => {


        if (event.target.name === "description") {
            var str = event.target.value;
            str = str.replace(/\s+/g, '-').toLowerCase();

            this.setState({
                [event.target.name]: event.target.value,
                url: str,
            })
        } else {
            this.setState({
                [event.target.name]: event.target.value
            })
        }
    }

    async componentDidMount() {

        if (this.isEdit) {
            await axiosInstance.get(`api/Brand/GetByID/${this.id}`).then(
                response => {
                    this.setState({
                        id: response.data.id,
                        description: response.data.description,
                        url: response.data.url,
                        pageSubtitle: response.data.pageSubtitle,
                        defaultImage: response.data.imagePath,
                        defaultImageThumbnail: response.data.imageThumbnailPath,
                        showInHome: response.data.showInHome,
                    });
                });
        }
    }

    CreateStyles() {
        return {                 
            float: 'right'
        }
    }

    HeaderTitle() {
        let title;

        if (this.isEdit) {
            title = 'Editando marca';
        }
        else {
            title ='Crea un nueva marca';
        }

        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                        <h4>
                            {title}
                            <small >
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Typography color="inherit">
                                        Productos
                                        </Typography>

                                    <Link color="inherit" to="/Marcas" >
                                        <Typography color="textPrimary">Marcas</Typography>
                                </Link>

                                </Breadcrumbs>
                            </small>
                        </h4>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <Link to="/Marcas">
                            <Button
                                variant="default"
                                color="default"
                                size="small"
                                startIcon={<CancelIcon />}
                                style={this.CreateStyles()}
                            >
                                Cancelar
                        </Button>
                        </Link>
                    </Grid>
                </Grid>

                <hr />
            </div>
        );

        
    }



    onDropImage(pictureFiles, pictureDataURLs) {

        let form = this.state.form;
        var element = pictureFiles[0];

        form.append('image', element);

        this.setState({
            form: form,
            image: element === undefined ? '' : element.name,
        });
    }

    addImage = async (image) => {

        this.setState({
            warningImageText: '',
        });

        var form = this.state.form;
        var validation = true;


        form.append('description', this.state.description);
        form.append('url', this.state.url);
        form.append('pageSubtitle', this.state.pageSubtitle);
        form.append('showInHome', this.state.showInHome);

        var response = null;

        if (this.isEdit) {

            form.append('id', this.state.id);

            await axiosInstance.put('/api/Brand/Update', form)
                .then(response => {

                    this.props.history.push('/Marcas');
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: "Operación exitosa"
                    })

                }).catch(error => {
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: error.response.data.message
                    })
                });
        }
        else {

            await axiosInstance.post('/api/Brand/Save', form)
                .then(response => {

                    this.props.history.push('/Marcas');
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: "Operación exitosa"
                    })

                }).catch(error => {
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: error.response.data.message
                    })
                });
        }

        this.setState({
            form: new FormData(),
        });


    

    }


    

    onDropImageThumbnail(pictureFiles, pictureDataURLs) {

        let form = this.state.form;
        var element = pictureFiles[0];

        form.append('imageThumbnail', element);

        this.setState({
            form: form,
            imageThumbnail: element === undefined ? '' : element.name,
        });
    }


    handleShowInHomeChange = (e) => {
        this.setState({
            showInHome: e.target.checked,
        })
    };


    render() {

        var recommendedImageSize = "Tamaño recomendado 3000 x 680";

        return (

            <div>

                {this.HeaderTitle()}

              
                    <ValidatorForm ref="form"
                        onSubmit={this.onSubmit}
                        onError={errors => console.log(errors)}
                    style={{ padding: "10px" }}>          

                    <Button
                        variant="contained"
                        color="primary"
                        size="medium"
                        startIcon={<SaveIcon />}
                        type="submit"

                    >
                        Guardar
                                </Button>

                <Paper style={{ padding: '15px', marginTop:'10px' }} elevation={1}>
                       

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Grid container spacing={2} >                       
                                        <Grid item sm={12} xs={12}>
                                            <TextValidator id="description"
                                                style={{ width: "100%" }}
                                                name="description"
                                                label="Nombre"
                                                variant="outlined"
                                                size="small"
                                                onChange={this.onChange}
                                                value={this.state.description}
                                                validators={['required', 'maxStringLength:50']}
                                        errorMessages={['El nombre de la categoría es requerido', 'Máximo 50 carácteres']}
                                        autoFocus 
                                        autoComplete="off"
                                            />
                                        </Grid>

                                        <Grid item sm={12} xs={12}>
                                            <TextValidator id="url"
                                                style={{
                                                    width: "100%"
                                                }}
                                                name="url"
                                                label="Url"
                                                variant="outlined"
                                                size="small"
                                                onChange={this.onChange}
                                                value={this.state.url}
                                                validators={[
                                                    'required'
                                                    , 'matchRegexp:^[a-zA-Z\-0-9]+$'
                                                ]}
                                                errorMessages={['El url es requerido', 'Carácteres no válidos (& / ? . , _)']}
                                                autoComplete="off"
                                            />
                                        </Grid>

                                        <Grid item sm={12} xs={12}>
                                            <TextValidator id="pageSubtitle"
                                                style={{ width: "100%" }}
                                                name="pageSubtitle"
                                                label="Subtítulo de la página"
                                                variant="outlined"
                                                size="small"
                                                onChange={this.onChange}
                                                value={this.state.pageSubtitle}
                                                validators={['maxStringLength:50']}
                                                errorMessages={['Máximo 50 carácteres']}
                                                autoComplete="off"
                                            />
                                    </Grid>   
                                    <Grid item xs={12}>

                                        <FormControlLabel control={<Checkbox checked={this.state.showInHome} onChange={this.handleShowInHomeChange} />} label="Mostrar en home" />
                                    </Grid>
                                    
                                    </Grid>
                            </Grid>                        

                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Grid container spacing={2}>
                                   

                                    <Grid item sm={12} xs={12}>

                                        <Typography variant="h5">Banner</Typography>


                                        {
                                            this.state.defaultImage != null ?
                                                <center>
                                                    <Grid item sm={12} xs={12}>
                                                        <img src={this.state.defaultImage} style={{ width: '100%' }} />
                                                    </Grid>
                                                </center>
                                                :
                                                null
                                        }


                                            <ImageUploader
                                                withPreview
                                                withIcon={false}
                                                buttonClassName="primary-color bg-base border-circle add-Button"
                                                buttonText="Seleccione una imágen tipo banner"
                                                onChange={this.onDropImage}
                                                imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                                                maxFileSize={5242880}
                                                label={recommendedImageSize}
                                                singleImage={true}
                                            />
                                        <FormHelperText style={{ color: '#f44336' }}>{this.state.warningImageText}</FormHelperText>

                                        <br/>
                                        <Divider />
                                        <br />

                                        <Typography variant="h5">Miniatura</Typography>

                                        {
                                            this.state.defaultImageThumbnail != null ?
                                                <center>
                                                    <Grid item sm={12} xs={12}>
                                                        <img src={this.state.defaultImageThumbnail} style={{ width: '200px' }} />
                                                    </Grid>
                                                </center>
                                                :
                                                null
                                        }

                                        

                                        <ImageUploader
                                            withPreview
                                            withIcon={false}
                                            buttonClassName="primary-color bg-base border-circle add-Button"
                                            buttonText="Seleccione una imágen tipo miniatura"
                                            onChange={this.onDropImageThumbnail}
                                            imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                                            maxFileSize={5242880}
                                            label="Tamaño recomendado 200 x 200"
                                            singleImage={true}
                                        />
                                        <FormHelperText style={{ color: '#f44336' }}>{this.state.warningImageTextThumbnail}</FormHelperText>




                                        </Grid>
                                    </Grid>
                            </Grid>            

                     
                        </Grid>

                    

                </Paper>
                    </ValidatorForm>
            </div>
        )
    }
}