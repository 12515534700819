import React, { Component } from 'react';
import { axiosInstance } from '../../utils/axiosInstance';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import Grid from '@material-ui/core/Grid'
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import store from '../../store';
import { Paper } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


export class OfferForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            products: [],
            discounts: [],
            productCategories: [],
            productSubCategories:[],
            productLaboratories: [],
            productBrands: [],
            offerTypes: [],


            offerID: 0,
            offerTypeID: 0,
            productCategoryID: 0,
            productSubCategoryID: 0,
            laboratoryID: 0,
            productBrandID: 0,
            productID: 0,
            discountID: 0,

            validFrom: null,
            validUntil: null,
            enabled: null,

            enabledMonday: false,
            enabledTuesday: false,
            enabledWednesday:false,
            enabledThursday: false,
            enabledFriday: false,
            enabledSaturday: false,
            enabledSunday: false,

        };;

        this.offerID = this.props.match.params.value;
        this.isEdit = this.props.match.params.value != undefined;
    }


    async executeRESTCall(action) {

        let data = {
            offerID: this.state.offerID,
            offerTypeID: this.state.offerTypeID,
            productCategoryID: this.state.productCategoryID,
            productSubCategoryID: this.state.productSubCategoryID,
            laboratoryID: this.state.laboratoryID,
            productBrandID: this.state.productBrandID,
            productID: this.state.productID,
            discountID: this.state.discountID,

            validFrom: this.state.validFrom,
            validUntil: this.state.validUntil,

            enabled: this.state.enabled,

            enabledMonday: this.state.enabledMonday,
            enabledThursday: this.state.enabledThursday,
            enabledWednesday: this.state.enabledWednesday,
            enabledTuesday: this.state.enabledTuesday,
            enabledFriday: this.state.enabledFriday,
            enabledSaturday: this.state.enabledSaturday,
            enabledSunday: this.state.enabledSunday,
        }

        const url = '/api/offer/';

        if (action === "POST") {
            await axiosInstance.post(url, data)
                .then(response => {

                    this.props.history.push('/ofertas');
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: "Operación exitosa"
                    })

                }).catch(error => {
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: error.response.data.message
                    })
                });
        }

        if (action === "PUT") {
            await axiosInstance.put(url, data)
                .then(response => {

                    this.props.history.push('/ofertas');
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: "Operación exitosa"
                    })

                }).catch(error => {
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: error.response.data.message
                    })
                });
        }    

      

      
    }
   

    onSubmit = e => {

        if (!this.isEdit) {
            this.executeRESTCall("POST");
        }
        else {
            this.executeRESTCall("PUT");
        }
    
        e.preventDefault();
    }


    onChange = (event, value) => {        

        if (event.target.type === "checkbox") {
            this.setState({
                [event.target.name]: event.target.checked,
            })
        }
        else {
            if (event.target.name === "name") {
                var str = event.target.value;
                str = str.replace(/\s+/g, '-').toLowerCase();

                this.setState({
                    [event.target.name]: event.target.value,
                    url: str,
                })
            }


            this.setState({
                [event.target.name]: event.target.value
            })
        }
    }

    async componentDidMount() {


        const discountResponse = await axiosInstance.get(`/api/Discount/GetDiscountsForSelect`);               
        const productResponse = await axiosInstance.get(`/api/Product/GetProductsForSelect`);       
        const productCategoryResponse = await axiosInstance.get(`/api/ProductCategory/GetproductCategoryForSelect`);
        const productSubCategoryResponse = await axiosInstance.get(`/api/ProductSubCategory/GetProductSubCategoryForSelect`);

        const productLaboratoryResponse = await axiosInstance.get(`/api/Laboratory/GetlaboratoriesForSelect`);
        const productBrandResponse = await axiosInstance.get(`/api/ProductBrand/GetBrandsForSelect`);     
        const offerTypesResponse = await axiosInstance.get(`/api/Offer/GetOfferTypeForSelect`);     

        this.setState({

            discounts: discountResponse.data,
            products: productResponse.data,
            productCategories: productCategoryResponse.data,
            productSubCategories: productSubCategoryResponse.data,
            productLaboratories: productLaboratoryResponse.data,
            productBrands: productBrandResponse.data,
            offerTypes: offerTypesResponse.data,
        });


        if (this.isEdit) {
            await axiosInstance.get(`api/Offer/GetByID/${this.offerID}`).then(
                response => {
                    this.setState({
                        offerID: response.data.offerID,
                        offerTypeID: response.data.offerTypeID,

                        productID: response.data.productID,
                        productCategoryID: response.data.productCategoryID,
                        laboratoryID: response.data.laboratoryID,
                        productBrandID: response.data.productBrandID,

                        discountID: response.data.discountID,

                        enabled: response.data.enabled,

                        validFrom: response.data.validFrom,
                        validUntil: response.data.validUntil,

                        enabledMonday: response.data.enabledMonday,
                        enabledThursday: response.data.enabledThursday,
                        enabledWednesday: response.data.enabledWednesday,
                        enabledTuesday: response.data.enabledTuesday,
                        enabledFriday: response.data.enabledFriday,
                        enabledSaturday: response.data.enabledSaturday,
                        enabledSunday: response.data.enabledSunday,

                    });
                });
        }
    }

    CreateStyles() {
        return {            
            marginRight: '10px',
            marginBottom: '10px',
            float: 'right'
        }
    }

    HeaderTitle() {
        let title;

        if (this.isEdit) {
            title = 'Editando oferta';
        }
        else {
            title ='Crea un nueva oferta';
        }

        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                        <h4>
                            {title}
                            <small >
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Typography color="inherit">
                                        Productos
                                        </Typography>

                                    <Link color="inherit" to="/ofertas" >
                                        <Typography color="textPrimary">ofertas</Typography>
                                </Link>

                                </Breadcrumbs>
                            </small>
                        </h4>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <Link to="/ofertas">
                            <Button
                                variant="default"
                                color="default"
                                size="small"
                                startIcon={<CancelIcon />}
                                style={this.CreateStyles()}
                            >
                                Cancelar
                        </Button>
                        </Link>
                    </Grid>
                </Grid>

                <hr />
            </div>
        );

        
    }

    renderDiscountOptions() {
        return this.state.discounts.map((dt, i) => {
            return (
                <MenuItem
                    label="Seleccione un descuento"
                    value={dt.id}
                    key={i} name={dt.text}>{dt.text}
                </MenuItem>
            );
        });
    }

    renderProductOptions() {
        return this.state.products.map((dt, i) => {
            return (
                <MenuItem
                    label="Seleccione un producto"
                    value={dt.id}
                    key={i} name={dt.text}>{dt.text}
                </MenuItem>
            );
        });
    }

    renderCategoryOptions() {
        return this.state.productCategories.map((dt, i) => {
            return (
                <MenuItem
                    label="Seleccione una categoría"
                    value={dt.id}
                    key={i} name={dt.text}>{dt.text}
                </MenuItem>
            );
        });
    }

    renderSubCategoryOptions() {
        return this.state.productSubCategories.map((dt, i) => {
            return (
                <MenuItem
                    label="Seleccione una subcategoría"
                    value={dt.id}
                    key={i} name={dt.text}>{dt.text}
                </MenuItem>
            );
        });
    }

    renderOfferTypesOptions() {
        return this.state.offerTypes.map((dt, i) => {
            return (
                <MenuItem
                    label="Seleccione un tipo de oferta"
                    value={dt.id}
                    key={i} name={dt.text}>{dt.text}
                </MenuItem>
            );
        });
    }

    renderLaboratoryOptions() {
        return this.state.productLaboratories.map((dt, i) => {
            return (
                <MenuItem
                    label="Seleccione un laboratorio"
                    value={dt.id}
                    key={i} name={dt.text}>{dt.text}
                </MenuItem>
            );
        });
    }      

    renderBrandOptions() {
        return this.state.productBrands.map((dt, i) => {
            return (
                <MenuItem
                    label="Seleccione una marca"
                    value={dt.id}
                    key={i} name={dt.text}>{dt.text}
                </MenuItem>
            );
        });
    }

    render() {

        return (
            <div>
                {
                    this.HeaderTitle()
                }               
            
                    <ValidatorForm ref="form"
                        onSubmit={this.onSubmit}
                        onError={errors => console.log(errors)}
                        style={{ padding: "10px" }}>               

                        <Grid container spacing={2} >
                            <Grid item xs={12} sm={12} md={6} lg={4}>
                                <Paper style={{ padding: '15px' }} elevation={1}>

                                    <Grid container spacing={2} >

                                    <Grid item sm={12} xs={12}>

                                        <div className="padd50">
                                            <SelectValidator
                                                style={{ width: "100%" }}
                                                name="offerTypeID"
                                                variant="outlined"
                                                size="small"
                                                label="Tipo de oferta"
                                                value={this.state.offerTypeID}
                                                validators={['required']}
                                                errorMessages={['El tipo de oferta es requerida']}
                                                onChange={this.onChange}>
                                                {this.renderOfferTypesOptions()}

                                            </SelectValidator>
                                        </div>



                                    </Grid>

                                    {
                                        this.state.offerTypeID === 2
                                            ?
                                            <Grid item sm={12} xs={12}>

                                                <div className="padd50">
                                                    <SelectValidator
                                                        style={{ width: "100%" }}
                                                        name="productCategoryID"
                                                        variant="outlined"
                                                        size="small"
                                                        label="Categoría"
                                                        value={this.state.productCategoryID}
                                                        validators={['required']}
                                                        errorMessages={['La categoría es requerida']}
                                                        onChange={this.onChange}>
                                                        {this.renderCategoryOptions()}

                                                    </SelectValidator>
                                                </div>



                                            </Grid>
                                            :
                                            null
                                    }


                                    {
                                        this.state.offerTypeID === 3
                                            ?
                                            <Grid item sm={12} xs={12}>

                                                <div className="padd50">
                                                    <SelectValidator
                                                        style={{ width: "100%" }}
                                                        name="productSubCategoryID"
                                                        variant="outlined"
                                                        size="small"
                                                        label="Subcategoría"
                                                        value={this.state.productSubCategoryID}
                                                        validators={['required']}
                                                        errorMessages={['La subcategoría es requerida']}
                                                        onChange={this.onChange}>
                                                        {this.renderSubCategoryOptions()}

                                                    </SelectValidator>
                                                </div>



                                            </Grid>
                                            :
                                            null
                                    }


                                    {
                                        this.state.offerTypeID === 4
                                            ?
                                            <Grid item sm={12} xs={12}>
                                                <div className="padd50">
                                                    <SelectValidator
                                                        style={{ width: "100%" }}
                                                        name="laboratoryID"
                                                        variant="outlined"
                                                        size="small"
                                                        label="Laboratorio"
                                                        value={this.state.laboratoryID}
                                                        validators={['required']}
                                                        errorMessages={['El laboratorio es requerido']}
                                                        onChange={this.onChange}>
                                                        {this.renderLaboratoryOptions()}
                                                    </SelectValidator>
                                                </div>
                                            </Grid>
                                            :
                                            null
                                    }

                                    {
                                        this.state.offerTypeID === 5
                                            ?
                                            <Grid item sm={12} xs={12}>
                                                <div className="padd50">
                                                    <SelectValidator
                                                        style={{ width: "100%" }}
                                                        name="productBrandID"
                                                        variant="outlined"
                                                        size="small"
                                                        label="Marca"
                                                        value={this.state.productBrandID}
                                                        validators={['required']}
                                                        errorMessages={['La marca es requerida']}
                                                        onChange={this.onChange}>
                                                        {this.renderBrandOptions()}
                                                    </SelectValidator>
                                                </div>
                                            </Grid>
                                            :
                                            null
                                    }

                                    {
                                        this.state.offerTypeID === 1
                                            ?
                                            <Grid item sm={12} xs={12}>
                                                <SelectValidator
                                                    style={{ width: "100%" }}
                                                    name="productID"
                                                    variant="outlined"
                                                    size="small"
                                                    label="Producto"
                                                    value={this.state.productID}
                                                    validators={['required']}
                                                    errorMessages={['El producto es requerido']}
                                                    onChange={this.onChange}>
                                                    {this.renderProductOptions()}
                                                </SelectValidator>
                                            </Grid>
                                            :
                                            null

                                    }                                                                                                         
                                                                          
                                  
                                        <Grid item sm={12} xs={12}>

                                        <SelectValidator
                                            style={{ width: "100%" }}
                                            name="discountID"
                                            variant="outlined"
                                            size="small"
                                            label="Descuento"
                                            value={this.state.discountID}
                                            validators={['required']}
                                            errorMessages={['El descuento es requerido']}
                                            onChange={this.onChange}>
                                            {this.renderDiscountOptions()}

                                        </SelectValidator>


                                    </Grid>



                                    <Grid item sm={12} xs={12}>
                                        <TextValidator
                                            InputLabelProps={{ shrink: true }}
                                            label="Válido desde"
                                            className="iron-form-input-wrap"
                                            onChange={this.onChange}
                                            name="validFrom"
                                            type="date"
                                            value={this.state.validFrom}
                                            size="small"
                                            placeholder="DD/MM/YYYY"
                                            variant="outlined"
                                        />
                                    </Grid>


                                    <Grid item sm={12} xs={12}>
                                     


                                        <TextValidator
                                            InputLabelProps={{ shrink: true }}
                                            label="Válido desde"
                                            className="iron-form-input-wrap"
                                            onChange={this.onChange}
                                            name="validUntil"
                                            type="date"
                                            value={this.state.validUntil}
                                            size="small"
                                            placeholder="DD/MM/YYYY"
                                            variant="outlined"
                                        />

                                    </Grid>




                                    <Grid item sm={12} xs={12}>
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.enabled} onChange={this.onChange} name="enabled" />}
                                            label="Habilitado"
                                        />
                                    </Grid>

                                        <Grid item xs={12} md={12}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="normal"
                                                startIcon={<SaveIcon />}
                                                type="submit"
                                                style={this.CreateStyles()}
                                            >
                                                Guardar
                                        </Button>

                                </Grid>
                          
                          
                
                                </Grid>

                                </Paper>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={4}>
                            <Paper style={{ padding: '15px' }} elevation={1}>
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.enabledMonday} onChange={this.onChange} name="enabledMonday" />}
                                    label="Lunes"
                            />

                            <FormControlLabel
                            control={<Checkbox checked={this.state.enabledTuesday} onChange={this.onChange} name="enabledTuesday" />}
                                label="Martes"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={this.state.enabledWednesday} onChange={this.onChange} name="enabledWednesday" />}
                                label="Miércoles"
                            />
                            <FormControlLabel
                                    control={<Checkbox checked={this.state.enabledThursday} onChange={this.onChange} name="enabledThursday" />}
                                label="Jueves"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={this.state.enabledFriday} onChange={this.onChange} name="enabledFriday" />}
                                label="Viernes"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={this.state.enabledSaturday} onChange={this.onChange} name="enabledSaturday" />}
                                label="Sábado"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={this.state.enabledSunday} onChange={this.onChange} name="enabledSunday" />}
                                label="Domingo"
                            />
                            </Paper>

                            </Grid>
                        </Grid>
                    </ValidatorForm>
            </div>
        )
    }
}
