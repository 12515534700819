import React, { Component } from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import Done from '@material-ui/icons/Done';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import Grid from '@material-ui/core/Grid'
import store from '../../store';
import { Paper } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

export class ClientForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            orderID: 0,
            name: '',
            details: [],
            order: null,
        };;

        this.orderID = this.props.match.params.value;

    }


 


    async componentDidMount() {

        await axios.get(`api/Order/GetByID/${this.orderID}`).then(
            response => {
                this.setState({
                    order: response.data,
                });
            });
    }

    CreateStyles() {
        return {            
            marginRight: '10px',
            marginBottom: '10px',
            float: 'right'
        }
    }

    HeaderTitle() {
        let title;

    
            title ='Pedido';
    

        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                        <h4>
                            {title}
                            <small >
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Typography color="inherit">
                                        Pedido
                                        </Typography>


                                </Breadcrumbs>
                            </small>
                        </h4>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <Link to="/Pedidos">
                            <Button
                                variant="default"
                                color="default"
                                size="small"
                                startIcon={<CancelIcon />}
                                style={this.CreateStyles()}
                            >
                                Volver
                        </Button>
                        </Link>
                    </Grid>
                </Grid>

                <hr />
            </div>
        );

        
    }

    renderMailCompany() {

        const { order } = this.state;

        if (order.retire.retireType !== 'Retiro por farmacia') {
            return (
                <div>
                    <h5>{order.retire.retireType}</h5>
                    <ul style={{ listStyle: 'none', paddingLeft: '0px' }}>

                        <li>
                            A cargo de: {order.retire.mailCompany}
                        </li>
                        <li>
                            Código postal: {order.retire.postalCode}
                        </li>
                        <li>
                            Dirección: {order.retire.streetName} {order.retire.streetLevel}
                        </li>
                        <li>
                            Departamento: {order.retire.department}
                        </li>
                        <li>
                            {order.retire.housingType}
                        </li>
                        <li>
                            Llega el: {order.retire.estimatedDeliveryDate}
                        </li>
                        <li>Recibe: {order.retire.receivePersonName}</li>
                        <li>Costo: $ {order.retire.shippingCost}</li>
                        <li>
                            Peso total: {order.totalWeight} grs
                            </li>
                    </ul>
                </div>
            );
        }
        else {
            return null;
        }


    }

    render2() {

        const { order } = this.state;

        if (order.retire.retireType === 'Retiro por farmacia') {
            return (
                <div>
                    <h5>{order.retire.retireType}</h5>
                    <ul style={{ listStyle: 'none', paddingLeft: '0px' }}>

                        <li>
                            Retira: {order.retire.nameOfWhoWithdraws}
                        </li>
                        <li>
                            Farmacia: {order.retire.selectedStore}
                        </li>
                    </ul>
                </div>
            );
        }
        else {
            return null;
        }


    }

    render() {


        const { order } = this.state;


        return (

            <div>

                {this.HeaderTitle()}

                {this.state.order === null ? "" :


                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Paper style={{ padding: '15px' }} elevation={1}>

                                <h5>
                                    Pedido #{order.orderID}
                                    <br />
                                    <small style={{ fontSize: '0.9rem' }}>{order.date}</small>

                                </h5>

                                <hr />

                                <br />


                                <Grid container spacing={2} >
                                    <Grid item xs={12} sm={12} md={4} lg={4}>

                                        <h5>Cliente</h5>

                                        <ul style={{ listStyle: 'none', paddingLeft: '0px' }}>

                                            <li>
                                                Nombre: {order.client.lastName}, {order.client.firstName}
                                            </li>
                                            <li>
                                                Teléfono móvil: {order.client.mobileNumber}
                                            </li>
                                            <li>
                                                Correo: {order.client.email}
                                            </li>
                                            <li>
                                                Número de documento: {order.client.docNumber}
                                            </li>
                                        </ul>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        {this.renderMailCompany()}
                                        {this.render2()}
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <h5>
                                            Mercado pago
                                        </h5>

                                        <ul style={{ listStyle: 'none', paddingLeft: '0px' }}>

                                            <li>
                                                #{order.paymentID}
                                            </li>
                                            <li>
                                                Cuotas: {order.installment}
                                            </li>
                                            <li>
                                                Estado: { order.paymentStatus } {order.paymentStatus !== 'approved' ? <CancelIcon color="secondary" /> : <Done style={{ color: 'green' }} />}
                                            </li>
                                            <li>
                                                Detalle estado: {order.paymentStatusDetail}
                                            </li>
                                            <li>
                                                Fecha de aprobación: {order.paymentDateApproved}
                                            </li>

                                        </ul>


                                    </Grid>

                                </Grid>

                                <Grid container spacing={2}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Producto</TableCell>
                                                <TableCell align="right">Cantidad</TableCell>
                                                <TableCell align="right">Total</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {order.details.map((row) => (
                                                <TableRow key={1}>
                                                    
                                                    <TableCell component="th" scope="row">
                                                        <img src={row.image} style={{ width: '125px' }} />
                                                        <b style={{marginLeft:'10px'}}>{row.product}</b>
                                                    </TableCell>
                                                    <TableCell align="right">{row.quantity}</TableCell>
                                                    <TableCell align="right">{row.price}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Grid>

                                <Grid container spacing={2} style={{ paddingTop: '15px' }}>
                                    <Grid item xs={12}>
                                        <strong style={{ float: 'right' }}>Subtotal: {order.subTotal}</strong>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <strong style={{ float: 'right' }}>Envío: ${order.retire.shippingCost}</strong>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <h5 style={{ float: 'right' }}>Total: {order.total}</h5>
                                    </Grid>
                                </Grid>


                           


                            </Paper>
                        </Grid>
                    </Grid>
                }
            </div>
        )
    }
}