import React, { Component } from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { axiosInstance } from '../../utils/axiosInstance';

import CancelIcon from '@material-ui/icons/Cancel';
import Grid from '@material-ui/core/Grid'
import { Paper } from '@material-ui/core';


export class ReserveWithRecipeForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
        };;

        this.orderID = this.props.match.params.value;

    }


 


    async componentDidMount() {

        await axiosInstance.get(`api/reserveWithRecipe/GetByID/${this.orderID}`).then(
            response => {
                this.setState({
                    data: response.data,
                });
            });
    }

    CreateStyles() {
        return {            
            marginRight: '10px',
            marginBottom: '10px',
            float: 'right'
        }
    }

    HeaderTitle() {
        let title;

    
            title ='Pedido';
    

        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                        <h4>
                            {title}
                            <small >
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Typography color="inherit">
                                        Pedido
                                        </Typography>


                                </Breadcrumbs>
                            </small>
                        </h4>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <Link to="/Pedidos">
                            <Button
                                variant="default"
                                color="default"
                                size="small"
                                startIcon={<CancelIcon />}
                                style={this.CreateStyles()}
                            >
                                Volver
                        </Button>
                        </Link>
                    </Grid>
                </Grid>

                <hr />
            </div>
        );

        
    }

  
   

    render() {




        return (

            <div>

                {this.HeaderTitle()}

                {this.state.data === null ? "" :


                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Paper style={{ padding: '15px' }} elevation={1}>

                                <h5>
                                    Pedido #{this.state.data.id}
                                    <br />
                                    <small style={{ fontSize: '0.9rem' }}>{this.state.data.date}</small>

                                    <br />
                                    <small style={{ fontSize: '0.9rem' }}>{this.state.data.paymentType}</small>

                                    <br />
                                    <small style={{ fontSize: '0.9rem' }}>{this.state.data.store}</small>

                                  

                                </h5>

                                <hr />

                                <br />


                                <Grid container spacing={2} >
                                    <Grid item xs={12} sm={4} md={4} lg={4}>

                                        <h5>Cliente</h5>

                                        <ul style={{ listStyle: 'none', paddingLeft: '0px' }}>

                                            <li>
                                                Nombre: {this.state.data.lastName}, {this.state.data.firstName}
                                            </li>
                                            <li>
                                                Teléfono móvil: {this.state.data.mobileNumber}
                                            </li>
                                            <li>
                                                Correo: {this.state.data.email}
                                            </li>
                                        </ul>
                                    </Grid>

                                    <Grid item xs={12} sm={4} md={4} lg={4}>
                                        <img src={this.state.data.documentFront} style={{ maxWidth:'250px' }} />
                                    </Grid>

                                    <Grid item xs={12} sm={4} md={4} lg={4}>
                                        <img src={this.state.data.documentBack} style={{ maxWidth: '250px' }} />
                                    </Grid>
           

                    

                                </Grid>

                                <hr />

                                <br />

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>

                                        <h5>Obra social / Prepaga</h5>

                                        <ul style={{ listStyle: 'none', paddingLeft: '0px' }}>

                                            <li>
                                                {this.state.data.prepaidHealth}
                                            </li>
                                        </ul>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <img src={this.state.data.credentialFront} style={{ maxWidth: '250px' }} />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <img src={this.state.data.credentialBack} style={{ maxWidth: '250px' }} />
                                    </Grid>

                                </Grid>


                                <hr />

                                <br />

                                        <h5>Recetas</h5>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={4} lg={3}>
                                        <img src={this.state.data.recipe1} style={{ maxWidth: '250px' }} />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={4} lg={3}>
                                        <img src={this.state.data.recipe2} style={{ maxWidth: '250px' }} />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={4} lg={3}>
                                        <img src={this.state.data.recipe3} style={{ maxWidth: '250px' }} />
                                    </Grid>         <Grid item xs={12} sm={12} md={4} lg={3}>
                                        <img src={this.state.data.recipe4} style={{ maxWidth: '250px' }} />
                                    </Grid>

                                </Grid>


                            </Paper>
                        </Grid>
                    </Grid>
                }
            </div>
        )
    }
}