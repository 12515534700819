import React, { Component } from 'react';
import { axiosInstance } from '../../utils/axiosInstance';


import { Edit, AddCircleOutline } from '@material-ui/icons/';
import { CustomDataTable } from '../../components/UI/CustomDataTable';

import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { Delete } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import swal from 'sweetalert'



export class PrepaidHealth extends Component {

    constructor(props) {
        super(props);
        this.state = {
            columns:[],            
        };

        this.dataTableRef = React.createRef();
    } 
    
    async componentDidMount() {

        const columns = [
            {
                name: 'Id',
                selector: 'id',
                sortable: true,
                width: '90px',
                hide: 'sm',

            },
            {
                name: 'Nombre',
                selector: 'name',
                sortable: true,
            },

            {
                name: 'Url',
                selector: 'url',
                sortable: true,
                hide: 'sm'
            },

            {
                name: 'Subtítulo de la página',
                selector: 'pageSubtitle',
                sortable: true,
                hide: 'sm',

            },

            {
                name: 'Opciones',
                button: true,
                cell: row =>
                    <Link to={"/EditandoMarca/" + row.id}>
                        <Tooltip title="Editar">
                            <IconButton aria-label="Editar">
                                <Edit />
                            </IconButton>
                        </Tooltip>
                    </Link>
                ,
                allowOverflow: true,
                width: '60px',
            },       
            {
                cell: row =>
                    <Tooltip title="Eliminar">
                        <IconButton
                            aria-label="delete"
                            onClick={this.handleDeleteClick}
                            data-row_id={row.id}
                            data-row_text={row.PrePaidHealthName}>
                            <Delete color="secondary" />
                        </IconButton>
                    </Tooltip>
                ,
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
                width: '60px',
            },
        ];

        this.setState({
            columns: columns,                              
        });

      
    }

    

    handleDeleteClick = async e =>{

 

        var id = e.currentTarget.dataset.row_id;
        var text = e.currentTarget.dataset.row_text;

        const deleteRow = await swal({
            title: "¿Confirma la operación?",
            text: text,
            icon: "warning",
            dangerMode: true,
            buttons: true,

        });

        if (deleteRow) {
            await axiosInstance.delete('api/PrePaidHealth/delete/' + id)
                .then(response => {

                    swal("Se eliminó el registro", {
                        icon: "success",
                    });
                    this.dataTableRef.current.refreshDataTable();

                }).catch(error => {

                    swal({
                        icon: 'error',
                        title: 'Oops, algo sucedió...',
                        text: error.response.data.message
                    })

                });
          
        }
    }

    onChange = (event, value) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    onSubmit = e => {
        console.log("submiting");
        e.preventDefault();
    }

    render() {
      
        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={7} md={8} lg={10}>

                        <h4>
                            Prepagas
                            <small >
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Typography color="inherit">
                                        Farmanobel
                                </Typography>
                                    <Typography color="textPrimary">Prepagas</Typography>
                                </Breadcrumbs>
                            </small>
                        </h4>
                    </Grid>
                    <Grid item xs={12} sm={5} md={4} lg={2}>

                        <Link to="/nueva-prepaga">
                            <Button variant="contained" color="primary" startIcon={<AddCircleOutline />} style={{ float: 'right', width:'100%' }}>
                                Nueva prepaga
                        </Button>
                        </Link>
                    </Grid>
                </Grid>

                <hr/>

                <CustomDataTable
                    url="api/PrePaidHealth"
                    columns={this.state.columns}
                    handleOpenModal={this.handleOpenModal}
                    ref={this.dataTableRef}
                    showHeader={true}
                />
            </div>
        );
    }
}
