import React, { Component } from 'react';
import { axiosInstance } from '../../utils/axiosInstance';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import Grid from '@material-ui/core/Grid'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import store from '../../store';
import { Paper } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export class DeliveryZoneForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: 0,
            name: '',
            price: '',
            enabled: null,

            postalCode: '',
        };

        this.deliveryZoneId = this.props.match.params.value;
        this.isEdit = this.props.match.params.value != undefined;
    }


    async executeRESTCall(action) {
        const url = '/api/DeliveryZone/';


        if (action === "POST") {
            await axiosInstance.post(url, this.state)
                .then(response => {

                    this.props.history.push('/zonas-de-entrega-propia');
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: "Operación exitosa"
                    })

                }).catch(error => {
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: error.response.data.message
                    })
                });
        }

        if (action === "PUT") {
            await axiosInstance.put(url, this.state)
                .then(response => {

                    this.props.history.push('/zonas-de-entrega-propia');
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: "Operación exitosa"
                    })

                }).catch(error => {
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: error.response.data.message
                    })
                });
        }     
    }
   

    onSubmit = e => {

        if (!this.isEdit) {
            this.executeRESTCall("POST");
        }
        else {
            this.executeRESTCall("PUT");
        }
    
        e.preventDefault();
    }


    onChange =(event, value) => {
        if (event.target.type === "checkbox") {
            this.setState({
                [event.target.name]: event.target.checked,
            })
        }
        else {

            this.setState({
                [event.target.name]: event.target.value
            })
        }
    }

    async componentDidMount() {

        if (this.isEdit) {
            await axiosInstance.get(`api/DeliveryZone/GetByID/${this.deliveryZoneId}`).then(
                response => {
                    this.setState({
                        id: response.data.id,
                        name: response.data.name,
                        price: response.data.price,
                        enabled: response.data.enabled,
                        postalCode: response.data.postalCode,
                    });
                });
        }
    }

    CreateStyles() {
        return {            
            marginRight: '10px',
            marginBottom: '10px',
            float: 'right'
        }
    }

    HeaderTitle() {
        let title;

        if (this.isEdit) {
            title = 'Editando zona de entrega';
        }
        else {
            title ='Crea un nueva zona de entrega';
        }

        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                        <h4>
                            {title}
                            <small >
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Typography color="inherit">
                                        Correo
                                        </Typography>

                                    <Link color="inherit" to="/zonas-de-entrega-propia" >
                                        <Typography color="textPrimary">Zonas de entrega propia</Typography>
                                </Link>

                                </Breadcrumbs>
                            </small>
                        </h4>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <Link to="/zonas-de-entrega-propia">
                            <Button
                                variant="default"
                                color="default"
                                size="small"
                                startIcon={<CancelIcon />}
                                style={this.CreateStyles()}
                            >
                                Cancelar
                        </Button>
                        </Link>
                    </Grid>
                </Grid>

                <hr />
            </div>
        );

        
    }

    render() {


        return (

            <div>

                {this.HeaderTitle()}

               
            
                    <ValidatorForm ref="form"
                onSubmit={this.onSubmit}
                onError={errors => console.log(errors)}
                style={{ padding: "10px" }}>               

                        <Grid container spacing={2} >

                            <Grid item xs={12} sm={6} md={6} lg={4}>
                            <Paper style={{ padding: '15px' }} elevation={1}>

                                    <Grid container spacing={2} >
                       
                                        <Grid item sm={12} xs={12}>
                                            <TextValidator id="name"
                                                style={{ width: "100%" }}
                                                name="name"
                                                label="Nombre"
                                                variant="outlined"
                                                size="small"
                                                onChange={this.onChange}
                                                value={this.state.name}
                                                validators={['required', 'maxStringLength:50']}
                                                errorMessages={['El nombre es requerida', 'Máximo 50 carácteres']}
                                                autoFocus 
                                                autoComplete="off"
                                            />
                                        </Grid>
                                  
                                        <Grid item sm={12} xs={12}>

                                        <TextValidator id="postalCode"
                                            style={{ width: "100%" }}
                                            name="postalCode"
                                            label="Código postal"
                                            variant="outlined"
                                            size="small"
                                            onChange={this.onChange}
                                            value={this.state.postalCode}
                                            validators={['required', 'matchRegexp:^[0-9]+$']}
                                            errorMessages={['El código postal es requerido', 'Solo números']}
                                            autoComplete="off"
                                        />


                                    </Grid>

                                    <Grid item sm={12} xs={12}>

                                        <TextValidator id="price"
                                            style={{ width: "100%" }}
                                            name="price"
                                            label="Precio"
                                            variant="outlined"
                                            size="small"
                                            onChange={this.onChange}
                                            value={this.state.price}
                                            validators={['required', 'matchRegexp:^[0-9]+$']}
                                            errorMessages={['El precio es requerido', 'Solo números']}
                                            autoComplete="off"
                                        />


                                    </Grid>

                                  
                                    <Grid item sm={12} xs={12}>
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.enabled} onChange={this.onChange} name="enabled" />}
                                            label="Habilitado"
                                        />
                                    </Grid>
                              

                                        <Grid item xs={12} md={12}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="medioum"
                                                startIcon={<SaveIcon />}
                                                type="submit"
                                                style={this.CreateStyles()}
                                            >
                                                Guardar
                                        </Button>

                                </Grid>
                          
                          
                
                                </Grid>

                                </Paper>
                            </Grid>
                        </Grid>

                    </ValidatorForm>
            </div>
        )
    }
}