import React, { Component } from 'react';
import { axiosInstance } from '../../utils/axiosInstance';


import { Edit } from '@material-ui/icons/';

import { Button, Paper, TextField, Grid } from '@material-ui/core/';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormHelperText from '@material-ui/core/FormHelperText';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import SaveIcon from '@material-ui/icons/Save';
import ImageUploader from 'react-images-upload';

import store from '../../store';


export class Images extends Component {

    constructor(props) {
        super(props);
        this.state = {
            images: [],
            textFilter: '',
            dialogOpen: false,


            form: new FormData(),

        };

        this.onChange = this.onChange.bind(this);
        this.handleEditImageClick = this.handleEditImageClick.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.onDropImage = this.onDropImage.bind(this);
        this.onSubmitModal = this.onSubmitModal.bind(this);
        
    }



    async componentDidMount() {

        await this.getProducts();
    }

    async getProducts() {


        var filters = {
            textFilter: this.state.textFilter,
        };

        console.log(filters);

        await axiosInstance.post('/api/Product/GetImages/', filters)
            .then(response => response.data)
            .then(data =>
                this.setState({
                images: data,
            }));


	}



    async handleEditImageClick(e) {

        var id = e.currentTarget.dataset.row_id;

        await axiosInstance.get('/api/Product/GetProductImage/' + id)
            .then(response => response.data)
            .then(data =>
                this.setState({
                    dialogOpen: true,
                    productID: data.productID,
                    isImageEdit: true,
                    imageID: data.id,
                    imageOrder: data.order,
                    image: '',
                    imageDescription: data.description,
                    defaultImage: data.url,
                }));
    }

    onChange = (e) => {


        this.setState({
            textFilter: e.target.value,
            page: 1,
        }, () => {
            this.getProducts();
        });
       
	}


    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    async onSubmitModal(e) {
        e.preventDefault();
        await this.addImage(this.state.file);
    }

    addImage = async (image) => {

        this.setState({
            warningImageText: '',
        });

        var form = this.state.form;
        var validation = true;

        form.append('imageOrder', this.state.imageOrder);
        form.append('productID', this.state.productID);
        form.append('imageDescription', this.state.imageDescription);


        if (this.state.isImageEdit) {
            form.append('imageID', this.state.imageID);

            await axiosInstance.put('/api/Product/UpdateImage', form)
                .then(response => {

                    this.setState({
                        form: new FormData(),
					})

                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: "Operación exitosa"
                    })

                }).catch(error => {
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: error.response.data.message
                    })
                });

            this.setState({
                dialogOpen: false,
            });

            await this.getProducts();
        }
        else {
            if (this.state.image === '') {
                validation = false;
                this.setState({
                    warningImageText: "Las imagen es requerida"
                });
            }
            else {


                await axiosInstance.post('/api/Product/SaveImage', form)
                    .then(response => {

                        store.dispatch({
                            type: "SNACKBAR_SUCCESS",
                            message: "Operación exitosa"
                        })

                    }).catch(error => {
                        store.dispatch({
                            type: "SNACKBAR_SUCCESS",
                            message: error.response.data.message
                        })
                    });



                this.setState({
                    dialogOpen: false,
                });

                await this.getImages();
            }
        }



    }



    onDropImage(pictureFiles, pictureDataURLs) {

        let form = this.state.form;
        var element = pictureFiles[0];

        form.append('image', element);

        this.setState({
            form: form,
            image: element === undefined ? '' : element.name,
        });

        if (this.state.documentFront !== '' && this.state.documentBack !== '') {
            this.setState({
                warningTextDocument: '',
            });
        }
    }


    closeDialog() {
        this.setState({
            dialogOpen: false,
        });
    }

    renderDialog() {
        return (<Dialog
            open={this.state.dialogOpen}
            onClose={this.closeDialog}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth='md'
        >
            <DialogTitle id="form-dialog-title">Editando imagen</DialogTitle>

            <ValidatorForm ref="form"
                onSubmit={this.onSubmitModal}
                onError={errors => console.log(errors)}
            >

                <DialogContent>
                    <DialogContentText>

                    </DialogContentText>

                    <Grid container spacing={2} >
                        <Grid item sm={12} xs={12}>

                            <TextValidator id="imageOrder"
                                style={{
                                    width: "100%"

                                }}
                                name="imageOrder"
                                label="Orden"
                                variant="outlined"
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.imageOrder}
                                validators={['required', 'minNumber:0', 'maxNumber:255', 'matchRegexp:^[0-9]+$']}
                                errorMessages={['El orden de la imagen es requerido', 'No puede ser 0', 'No puede ser mayor a 255', 'Solo números']}
                                autoFocus
                                autoComplete="off"

                            />

                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <TextValidator id="imageDescription"
                                style={{
                                    width: "100%"

                                }}
                                name="imageDescription"
                                label="Descripción"
                                variant="outlined"
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.imageDescription}
                                validators={['required']}
                                errorMessages={['La descripción de la imagen es requerida']}
                                autoComplete="off"

                            />

                        </Grid>
                    </Grid>

                    {
                        this.state.isImageEdit ?
                            <Grid container spacing={2} >
                                <Grid item sm={6} xs={6}>
                                    <img src={this.state.defaultImage} style={{ width: '300px' }} />
                                </Grid>
                                <Grid item sm={6} xs={6}>
                                    <ImageUploader
                                        withPreview
                                        withIcon={false}
                                        buttonClassName="primary-color bg-base border-circle add-Button"
                                        buttonText="Seleccione una imágen"
                                        onChange={this.onDropImage}
                                        imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                                        maxFileSize={5242880}
                                        label="Tamaño recomendado 300 x 300"
                                        singleImage={true}
                                        defaultImage={this.state.defaultImage}
                                    />
                                    <FormHelperText style={{ color: '#f44336' }}>{this.state.warningImageText}</FormHelperText>
                                </Grid>
                            </Grid>
                            :


                            <Grid container spacing={2} >

                                <Grid item sm={12} xs={12}>
                                    <ImageUploader
                                        withPreview
                                        withIcon={false}
                                        buttonClassName="primary-color bg-base border-circle add-Button"
                                        buttonText="Seleccione una imágen"
                                        onChange={this.onDropImage}
                                        imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                                        maxFileSize={5242880}
                                        label="Tamaño recomendado 300 x 300"
                                        singleImage={true}
                                        defaultImage={this.state.defaultImage}
                                    />
                                    <FormHelperText style={{ color: '#f44336' }}>{this.state.warningImageText}</FormHelperText>
                                </Grid>
                            </Grid>

                    }








                </DialogContent>

                <DialogActions>

                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<SaveIcon />}
                        type="submit"

                    >
                        Editar imagen del producto
                        </Button>

                    <Button onClick={this.closeDialog} color="primary">
                        Cancelar
                        </Button>

                </DialogActions>
            </ValidatorForm>
        </Dialog>);
	}

    render() {
      
        return (
            <div>

                <TextField
                    id="textFilter"
                    name="textFilter"
                    value={this.state.textFilter}
                    variant="outlined"
                    label="Buscar producto"
                    onChange={this.onChange}
                    style={{width:'300px'}}
                />

                <br />
                <br />


                {this.renderDialog()}

            <Grid container spacing={2}>



                    {
                    this.state.images.map((img, index) => {
                            return(
                                <Grid item lg={1} key={index}>
                                    <Paper style={{padding:'10px'}}>
                                        <p style={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            height: '4.0em',
                                            display: '-webkit-box',

                                        }}
                                        >
                                            {img.name}
                                        </p>

                                        <p style={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            height: '2.7em',
                                            display: '-webkit-box',

                                        }}
                                        >
                                            {img.shortDescription}
                                        </p>
                                        <img src={img.defaultImage} width="100%" />

                                        <Button
                                            className="w-100 mt-10"
                                            style={{marginTop:'30px'}}
                                            color="primary"
                                            variant="outlined"
                                            size="small"
                                            onClick={this.handleEditImageClick}
                                            data-row_id={img.imageId}
                                        >
                                            <Edit />
                                        </Button>

                                    </Paper>
    
                            </Grid>)
                    })
                }

                </Grid>


                </div>
        );
    }
}
