import axios from "axios";
import { getToken } from '../helpers/auth-helper';


export const version = "1.0.24";


//export const baseURL = "https://localhost:44344";

export const baseURL = "https://admin.farmanobel.com";




export const axiosInstance = axios.create({	

});



axiosInstance.interceptors.request.use(
    async config => {

        var token = await getToken();


        if (token !== null) {
            //config.headers.Authorization = `Bearer ${token}`;
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        else {
            config.headers["Authorization"] = `Bearer 1`;
        }




        return config;
    });

axiosInstance.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status === 401 || error.response.status === 403) {
            window.location = "/error-403";
        } else {
            return Promise.reject(error);
        }
    }
);