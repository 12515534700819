import React, { Component } from 'react';
import { axiosInstance } from '../../utils/axiosInstance';


import { Sync, Description } from '@material-ui/icons/';
import { CustomDataTable } from '../../components/UI/CustomDataTable';


import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import swal from 'sweetalert'
import store from '../../store';
import { CircularProgress } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Chip from '@material-ui/core/Chip';

import Done from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';


import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';





export class Price extends Component {

    constructor(props) {
        super(props);
        this.state = {
            columns: [],            
            selectedFile: null,
            isExecutingRequest: false,
            buttonDisabled: false,
            selectedFileEYD: null,
            tabValue: '0',
            dialogOpen: false,

            productsToUpdatePrice: [],
            syncFileName: '',
            syncFileType: 0,
            syncOnlyPrices: true,
            ignoreCheaperPrices: true,
            ignoreProductStatus: true
        };

        this.dataTableRef = React.createRef();
    } 

    //cannon
    onFileChange = event => {
        
        this.setState({
            selectedFile: event.target.files[0],
            syncExcelFileEnum: 1,
        }, () => {
            this.onFileUpload();
        });

    };

    //jesels
    onFileChangeJesels = event => {

        this.setState({
            selectedFile: event.target.files[0],
            syncExcelFileEnum: 2,

        }, () => {
            this.onFileUpload();
        });
    };

    //siap
    onFileChangeSIAP = event => {        

        this.setState({
            selectedFile: event.target.files[0],
            syncExcelFileEnum: 3,

        }, () => {
                this.onFileUpload();
        });

    };

    onFileUpload = () => {

     


        if (this.state.selectedFile != null) {

            const formData = new FormData();
            formData.append("Image", this.state.selectedFile, this.state.selectedFile.name);
            formData.append("SyncExcelFileEnum", this.state.syncExcelFileEnum);
     

            axiosInstance.post('/api/product/uploadDiscounts', formData)
                .then(response => {

                    console.log(response.data)

                    if (response.data.isOK) {

                        this.setState({
                            dialogOpen: true,
                            productsToUpdatePrice: response.data.productsToUpdatePrice,
                            syncFileName: response.data.fileName,
                        })
                    }
                    else {
                        store.dispatch({
                            type: "SNACKBAR_SUCCESS",
                            message: response.data.error
                        })
					}

                }).catch(error => {
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: error.response.data.message
                    })
                });                   

            this.setState({
                selectedFile: null,
            })
        }
    };













    async componentDidMount() {

        const columns = [
            {
                name: 'Id',
                selector: 'id',
                sortable: true,
                width: '110px',
                hide: 'sm',

            },

            {
                name: 'EAN13',
                selector: 'barCode',
                sortable: true,
            },


            {
                name: 'Nombre',
                selector: 'name',
                sortable: true,
            },


       


            {
                name: 'Precio',
                selector: 'price',
                sortable: true,
                hide: 'md',
                mawidth: '90px',
            },



            {
                name: 'Stock',
                selector: 'stock',
                sortable: true,
                hide: 'md',
                mawidth: '90px',
            },


            {
                name: 'Fecha actualización',
                selector: 'priceUpdateDate',
                sortable: true,
            },

            
          
       
        ];

        const columnsSync = [
            {
                name: 'Id',
                selector: 'id',
                sortable: true,
                width: '110px',
                hide: 'sm',

            },

            {
                name: 'Archivo',
                selector: 'fileName',
                sortable: true,
            },
            {
                name: 'Inicio',
                selector: 'startDate',
                hide: 'md',
                sortable: true,
            },
            {
                name: 'Fin',
                selector: 'finishDate',
                sortable: true,
                hide: 'md',
                mawidth: '90px',
            },



            {
                name: 'Ok',
                selector: 'resultOk',
                sortable: true,
                
                mawidth: '90px',
                cell: d => d.resultOk === true ? <div><Done style={{ color: 'green' }} /> </div> : <div><CancelIcon color="secondary" /></div>
            },

            {
                name: 'Error',
                selector: 'errorMessage',
                sortable: true,
                hide: 'md',
            },

            


        ];

        this.setState({
            columns: columns,              
            columnsSync: columnsSync,
        });

      
    }

    syncPlataformaPVPPrices = async e => {

        this.setState({
            isExecutingRequest: true,
        });


        await axiosInstance.post(
            '/api/product/SyncPlataformaPVPPrices'
            , { headers: { "Access-Control-Allow-Origin": "*" } }
            
        )
            .then(response => {
                store.dispatch({
                    type: "SNACKBAR_SUCCESS",
                    message: "Operación exitosa"
                })
                this.setState({
                    isExecutingRequest: false,
                });

                this.dataTableRef.current.refreshDataTable();


            }).catch(error => {

                console.log("error: ", error);

                store.dispatch({
                    type: "SNACKBAR_SUCCESS",
                    message: error.response.data.message
                })
                this.setState({
                    isExecutingRequest: false,
                });

            });

   
    }

    handleDeleteClick = async e =>{

 

        var id = e.currentTarget.dataset.row_id;
        var text = e.currentTarget.dataset.row_text;

        const deleteRow = await swal({
            title: "¿Confirma la operación?",
            text: text,
            icon: "warning",
            dangerMode: true,
            buttons: true,

        });

        if (deleteRow) {
            await axiosInstance.delete('api/Offer/delete/' + id)
                .then(response => {

                    swal("Se eliminó el registro", {
                        icon: "success",
                    });
                    this.dataTableRef.current.refreshDataTable();

                }).catch(error => {

                    swal({
                        icon: 'error',
                        title: 'Oops, algo sucedió...',
                        text: error.response.data.message
                    })

                });
        }
    }

    handleTabChange = (event, newValue) => {

        this.setState({
            tabValue: newValue,
        })
    };

    onChange = (event, value) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    onSubmit = e => {
        console.log("submiting");
        e.preventDefault();
    }

    handleClickOpenDialog = () => {
        this.setState({
            dialogOpen: true,
		})
    };

    handleCloseDialog = () => {
        this.setState({
            dialogOpen: false,
        })
    };

    updatePricesAndStock = () => {

        var data = {
            productsToUpdatePrice: this.state.productsToUpdatePrice
            , fileName: this.state.syncFileName
            , syncOnlyPrices: this.state.syncOnlyPrices
            , ignoreCheaperPrices: this.state.ignoreCheaperPrices
            , ignoreProductStatus: this.state.ignoreProductStatus
        };

        axiosInstance.post('/api/product/UpdatePricesAndStock', data)
            .then(response => {

                if (response.data.isOK) {
                    this.setState({
                        dialogOpen: false,                        
                    })
                }

                store.dispatch({
                    type: "SNACKBAR_SUCCESS",
                    message: "Operación exitosa"
                })

                this.dataTableRef.current.refreshDataTable();

            }).catch(error => {
                store.dispatch({
                    type: "SNACKBAR_SUCCESS",
                    message: error.response.data.message
                })
            });   

        this.setState({
            dialogOpen: false,
        })
    };


    handleSyncOnlyPricesChange = (e) => {
        this.setState({
            syncOnlyPrices: e.target.checked,
        })
    };

    handleSyncIgnoreCheaperPricesChange = (e) => {
        this.setState({
            ignoreCheaperPrices: e.target.checked,
        })
    }

    handleSyncIgnoreProductStatusChange = (e) => {
        this.setState({
            ignoreProductStatus: e.target.checked,
        })
	}

    render() {
      
        return (
            <div>

                <Dialog
                    open={this.state.dialogOpen}
                    onClose={this.handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth
                    maxWidth="md"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Archivo: " + this.state.syncFileName}
                    </DialogTitle>

                    <div style={{ padding: '10px' }}>
                        

                        <FormControlLabel control={<Checkbox checked={this.state.syncOnlyPrices} onChange={this.handleSyncOnlyPricesChange}/>} label="Sincronizar sólo precios" />

                        <br />

                        <FormControlLabel control={<Checkbox checked={this.state.ignoreCheaperPrices} onChange={this.handleSyncIgnoreCheaperPricesChange} />} label="Ignorar productos con precios a la baja" />
                        <br />
                        <FormControlLabel control={<Checkbox checked={this.state.ignoreProductStatus} onChange={this.handleSyncIgnoreProductStatusChange} />} label="Ignorar estados de productos" />
                        <br />

                        {
                            this.state.productsToUpdatePrice.length > 0 ?

                                <Button variant="contained" color="primary" onClick={this.updatePricesAndStock} autoFocus>
                                    Sincronizar
                                </Button>

                                : null
                        }


                        <Button onClick={this.handleCloseDialog}>
                            Cancelar
                        </Button>
                    </div>

                    <DialogContent>

           

                        

                        <DialogContentText id="alert-dialog-description">

                            {
                                this.state.productsToUpdatePrice.length > 0 ?


                                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell colspan={2} align="left"></TableCell>
                                                <TableCell colspan={3} align="left" style={{ backgroundColor: '#eee' }}>Precio</TableCell>                                      
                                                <TableCell colspan={3}> {this.state.syncOnlyPrices ? <p style={{ color: '#999' }}>Stock</p> : <p>Stock</p>}</TableCell>

                                            </TableRow>
                                            <TableRow>
                                                <TableCell  align="left">#</TableCell>
                                                <TableCell align="left">Producto</TableCell>
                                                <TableCell align="left" style={{ backgroundColor: '#eee' }}>Actual</TableCell>
                                                <TableCell align="left" style={{ backgroundColor: '#eee' }}>Nuevo</TableCell>
                                                <TableCell align="right" style={{ backgroundColor: '#eee' }}>%</TableCell>

                                                <TableCell align="center"> {this.state.syncOnlyPrices ? <p style={{ color: '#999' }}>Actual</p> : <p>Actual</p>}</TableCell>
                                                <TableCell align="center"> {this.state.syncOnlyPrices ? <p style={{ color: '#999' }}>Nuevo</p> : <p>Nuevo</p>}</TableCell>

                                                <TableCell>Estado</TableCell>

                                           
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>


                                            {
                                                this.state.productsToUpdatePrice.map((product, index) => {
                                                    return (
                                                        <TableRow
                                                            key={index}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="left">{product.productID}</TableCell>
                                                            <TableCell align="left">
                                                                {product.productName}
                                                                <p style={{ color: '#666', fontSize: '12' }}>{product.eanCode}</p>
                                                                {product.isFlyer ? <Chip label="Es flyer, este producto no se actualizara" style={{backgroundColor:'red', color:'#fff'}}></Chip> : null }
                                                            </TableCell>
                                                            <TableCell align="right" style={{backgroundColor:'#eee'}} >{"$ " + product.price.toFixed(2)}</TableCell>
                                                            <TableCell align="right" style={{ backgroundColor: '#eee' }}>{"$ " + product.newPrice}</TableCell>
                                                            <TableCell align="right" style={{ backgroundColor: '#eee', color: product.style }}>
                                                                {product.percentageIncrease.toFixed(2)}
                                                            </TableCell>

                                                            <TableCell align="center"> {this.state.syncOnlyPrices ? <p style={{ color:'#999'}}>{product.stock}</p> : <p>{product.stock}</p>}</TableCell>
                                                            <TableCell align="center"> {this.state.syncOnlyPrices ? <p style={{ color: '#999' }}>{product.newStock}</p> : <p>{product.newStock}</p>}</TableCell>
                                                            <TableCell>{product.status }</TableCell>

                                                        </TableRow>
                                                    )
                                                })
                                            }


                                        </TableBody>
                                    </Table>


                                    : <p>No se encontraron productos para sincronizar</p>
                            }


                    </DialogContentText>
                    </DialogContent>
                  
                </Dialog>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>

                        <h4>
                            Actualización de precios y stock
                            <small >
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Typography color="inherit">
                                        Productos
                                </Typography>
                                    <Typography color="textPrimary">Precios</Typography>
                                </Breadcrumbs>
                            </small>
                        </h4>

                        <hr />

                        <Tabs
                            value={this.state.tabValue}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={this.handleTabChange}
                            aria-label="disabled tabs example"
                            scrollButtons="on"
                        >
                            <Tab label="Sincronizaciones" value="0" />
                            <Tab label="Productos" value="1" />
                        </Tabs>



                        {this.state.tabValue == 0 ?

                            <div>

                                <br/>

                              

                              


                                <input
                                    //accept="image/*"
                                    style={{ display: 'none' }}
                                    id="contained-button-file-siap"
                                    multiple
                                    type="file"
                                    onChange={this.onFileChangeSIAP}
                                />
                                <label htmlFor="contained-button-file-siap">

                                    <Button
                                        startIcon={<Description />}
                                        variant="contained"
                                        component="span"
                                        onClick={this.onFileUploadSiap}
                                        style={{ marginLeft: '8px' }}
                                    >
                                        Archivos SIAP
                            </Button>
                                </label>

                                <br />
                                <br />

                                <CustomDataTable
                                    url="api/Product/PriceStockSync"
                                    columns={this.state.columnsSync}
                                    handleOpenModal={this.handleOpenModal}
                                    ref={this.dataTableRef}
                                    showHeader={true}
                                />

                             
                            </div>
                            :

                            null
                        }
           


                      
                        {this.state.tabValue == 1 ?

                            <div>

                                <br />

                                <CustomDataTable
                                    url="api/Product/"
                                    columns={this.state.columns}
                                    handleOpenModal={this.handleOpenModal}
                                    ref={this.dataTableRef}
                                    showHeader={true}
                                />
    
                            </div>
                            :

                            null
                        }


                    </Grid>
                </Grid>


             
            </div>
        );
    }








    onFileChangeEYD = event => {
        console.log("onFileChangeEYD");

        // Update the state
        this.setState({ selectedFileEYD: event.target.files[0] });


        this.setState({
            selectedFileEYD: event.target.files[0]
        }, () => {
                this.onFileUploadEYD();
        });

    };

    onFileUploadEYD = () => {

        console.log("onFileUploadEYD");

        if (this.state.selectedFileEYD != null) {

            const formData = new FormData();
            formData.append(
                "Image",
                this.state.selectedFileEYD,
                this.state.selectedFileEYD.name
            );




            axiosInstance.post('/api/product/uploadDiscountsEYD', formData)
                .then(response => {

                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: "Operación exitosa"
                    })

                    this.dataTableRef.current.refreshDataTable();

                }).catch(error => {
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: error.response.data.message
                    })
                });

            this.setState({
                selectedFileEYD: null,
            })
        }
    };
}
