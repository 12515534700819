import React, { Component } from 'react';
import { axiosInstance } from '../../utils/axiosInstance';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import Grid from '@material-ui/core/Grid'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import store from '../../store';
import { Paper } from '@material-ui/core';



export class ResetPasswordForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: 0,
            email: '',
            password: '',
        };;

        this.id = this.props.match.params.value;
        this.isEdit = this.props.match.params.value != undefined;
        this.onSubmit = this.onSubmit.bind(this);
    }
   

    async onSubmit(e) {
        this.executeRESTCall("PUT");
        e.preventDefault();
    }


    onChange = (event, value) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    async componentDidMount() {


        if (this.isEdit) {
            await axiosInstance.get(`api/UserAdmin/GetByID/${this.id}`).then(
                response => {

                    this.setState({
                        id: response.data.id,
                        firstName: response.data.firstName,
                        lastName: response.data.lastName,
                        email: response.data.email,
                        roles: response.data.roles
                    });

                });
        }
    }

    CreateStyles() {
        return {                 
            float: 'right'
        }
    }

    HeaderTitle() {
        let title;

        if (this.isEdit) {
            title = 'Reseteando contraseña';
        }

        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                        <h4>
                            {title}
                            <small >
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Typography color="inherit">
                                        Configuración
                                        </Typography>

                                    <Link color="inherit" to="/Marcas" >
                                        <Typography color="textPrimary">Usuarios</Typography>
                                </Link>

                                </Breadcrumbs>
                            </small>
                        </h4>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <Link to="/Usuarios">
                            <Button
                                variant="default"
                                color="default"
                                size="small"
                                startIcon={<CancelIcon />}
                                style={this.CreateStyles()}
                            >
                                Cancelar
                        </Button>
                        </Link>
                    </Grid>
                </Grid>

                <hr />
            </div>
        );

        
    }

   


    async executeRESTCall(action) {


    
        await axiosInstance.put('/api/UserAdmin/resetPassword', this.state)
            .then(response => {

                this.props.history.push('/usuarios');

                store.dispatch({
                    type: "SNACKBAR_SUCCESS",
                    message: "Se cambió la contraseña"
                })

            }).catch(error => {
                store.dispatch({
                    type: "SNACKBAR_SUCCESS",
                    message: error.response.data.message
                })
            });






    }   

    render() {

       
        return (

            <div>

                {this.HeaderTitle()}
            
                <Paper style={{ padding: '15px' }} elevation={1}>
                    <ValidatorForm ref="form"
                        onSubmit={this.onSubmit}
                        onError={errors => console.log(errors)}
                        style={{ padding: "10px" }}>               

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Grid container spacing={2}>         
                                    
                                        <Grid item sm={12} xs={12}>
                                            {this.state.email}
                                        </Grid>





                                    <Grid item sm={12} xs={12}>
                                        <TextValidator id="password"
                                            style={{
                                                width: "100%"
                                            }}
                                            name="password"
                                            label="Contraseña"
                                            variant="outlined"
                                            size="small"
                                            onChange={this.onChange}
                                            value={this.state.password}
                                            validators={['required', 'maxStringLength:50', 'minStringLength:6']}
                                            errorMessages={['La contraseña es requerida', 'Máximo 50 carácteres', 'Mínimo 6 carácteres']}
                                            autoComplete="off"
                                        />
                                    </Grid>


                                    <Grid item xs={12} md={12}>

                                        <hr style={{ marginTop: '30px' }} />

                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="medium"
                                            startIcon={<SaveIcon />}
                                            type="submit"
                                            style={this.CreateStyles()}
                                        >
                                            Cambiar contraseña
                                </Button>
                                    </Grid>
                                   
                                    
                                    </Grid>
                            </Grid>                        

                                              
                        </Grid>

                    

                    </ValidatorForm>
                </Paper>
            </div>
        )
    }
}