import React, { Component } from 'react';
import { Route, Redirect } from 'react-router';
import axios from 'axios';
//import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';

import Layout  from './components/Layout';

import { Home } from './components/Home';
import { Counter } from './components/Counter';

import { Product } from './routes/product/Product';
import { ProductForm } from './routes/product/ProductForm';

import { Discount } from './routes/discount/Discount';
import { DiscountForm } from './routes/discount/DiscountForm';

import { PaymentPlatform } from './components/PaymentPlatform';

import { ProductCategory } from './routes/category/ProductCategory';
import { ProductCategoryForm } from './routes/category/ProductCategoryForm';

import { ProductSubCategory } from './routes/subCategory/ProductSubCategory';
import { ProductSubCategoryForm } from './routes/subCategory/ProductSubCategoryForm';

import { SiteText } from './routes/siteText/SiteText';
import { SiteTextForm } from './routes/siteText/SiteTextForm';

import { Laboratory } from './routes/laboratory/Laboratory';
import { LaboratoryForm } from './routes/laboratory/LaboratoryForm';

import { Gallery } from './routes/gallery/Gallery';
import { GalleryForm } from './routes/gallery/GalleryForm';

import { GalleryBrandOffers } from './routes/galleryBrandsOffers/GalleryBrandOffers';
import { GalleryBrandOffersForm } from './routes/galleryBrandsOffers/GalleryBrandOffersForm';

import { Slider } from './routes/slider/Slider';
import { SliderForm } from './routes/slider/SliderForm';


import { Brand } from './routes/brand/Brand';
import { BrandForm } from './routes/brand/BrandForm';

import { Offer } from './routes/offer/Offer';
import { OfferForm } from './routes/offer/OfferForm';


import { ReserveWithRecipe } from './routes/reserveWithRecipe/ReserveWithRecipe';
import { ReserveWithRecipeForm } from './routes/reserveWithRecipe/ReserveWithRecipeForm';


import { Order } from './routes/order/Order';
import { OrderForm } from './routes/order/OrderForm';

import { Client } from './routes/client/Client';
import { ClientForm } from './routes/client/ClientForm';

import { DeliveryZone } from './routes/deliveryZone/DeliveryZone';
import { DeliveryZoneForm } from './routes/deliveryZone/DeliveryZoneForm';

import { ProductGroup } from './routes/productGroup/ProductGroup';
import { ProductGroupForm } from './routes/productGroup/ProductGroupForm';


import { ProductGroupFlyer } from './routes/productGroupFlyer/ProductGroupFlyer';
import { ProductGroupFlyerForm } from './routes/productGroupFlyer/ProductGroupFlyerForm';


import { EmailCompany } from './routes/emailCompany/EmailCompany';
import { EmailCompanyForm } from './routes/emailCompany/EmailCompanyForm';

import { Stock } from './routes/stock/Stock';
import { StockForm } from './routes/stock/StockForm';

import { Store } from './routes/store/Store';
import { StoreForm } from './routes/store/StoreForm';

import { PrepaidHealth } from './routes/prepaidHealth/PrepaidHealth';
import { PrepaidHealthForm } from './routes/prepaidHealth/PrepaidHealthForm';


import { ProductSegment } from './routes/segment/ProductSegment';
import { ProductSegmentForm } from './routes/segment/ProductSegmentForm';

import { Login } from './routes/login/index';

import { setToken, getCurrentUser, deleteToken, getToken } from './helpers/auth-helper';


import { User } from './routes/user/User';
import { UserForm } from './routes/user/UserForm';
import { ResetPasswordForm } from './routes/user/ResetPasswordForm';

import { Role } from './routes/role/Role';
import { RoleForm } from './routes/role/RoleForm';
import { Page403 } from './routes/page-403/index';

import { Images } from './routes/images/Images';
import { Price } from './routes/price/Price';


import { Flyer } from './routes/flyer/Flyer';
import { FlyerForm } from './routes/flyer/FlyerForm';

import './assets/_widgets.scss';

import { UserProvider } from './userContext';
//import PrivateRoute  from './auth/privateRoute';

import 'typeface-roboto';
import './custom.css'

function PrivateRoute({ component: Component, authed, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) => authed === true
                ? <Component {...props} />
                : <Redirect to='/login' />}
        />
    )
}


export default class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: null,      
        }

        this.logout = this.logout.bind(this);
        this.login = this.login.bind(this);
	}

    static displayName = App.name;

    login(data) {

        setToken(data.token);

        this.setState({
            user: {
                ...this.state.user,
                isAuth: true,
                token: data.token,
                email: data.email,
            }
        });




    }

    logout() {
        
        this.setState({
            user: {
                ...this.state.user,
                isAuth: false,
                token: '',
                email: '',
            }
        });
        
    }

    async componentDidMount() {


        await axios.get('/api/auth/GetAppVersion')
            .then(response => {
                var serverVersion = response.data;

                var localVersion = localStorage.getItem('adminFarmanobelVersion');
                

                if (localVersion === null) {
                    localStorage.setItem('adminFarmanobelVersion', serverVersion);
                    console.log('empty cookie version');
                    window.location.reload(true);
                }
                else {
                    if (serverVersion > localVersion) {
                        localStorage.setItem('adminFarmanobelVersion', serverVersion);

                        this.deleteCacheAndReloadPage();

                    }
                    else {
                        console.log('current version');
					}
                }




            })
            .catch(() => {
                console.log("Error getting version");
            });


        var token = await getToken();

        if (token === null) {

            this.setState({
                user: {
                    ...this.state.user,
                    isAuth: false,
                    email: '',
                    token: '',
                }
            });
        }
        else {

         
                



            await axios.get('/api/auth/GetUserProfile'
                , { headers: { "Authorization": `Bearer ${token}` } })
                .then(response => {
                    this.setState({
                        user: {
                            ...this.state.user,
                            isAuth: true,
                            email: response.data.email,
                        }
                    });
                })
                .catch(() => {
                    this.setState({
                        user: {
                            ...this.state.user,
                            isAuth: false,
                            email: '',
                            token: '',
                        }
                    });
                });
		}

    }


    async deleteCacheAndReloadPage() {
        console.log('new version2');

        if (caches) {

            const names = await caches.keys();
            console.log(names);

            await Promise.all(names.map(name => caches.delete(name)));
        }
        window.location.reload();
    }

    render() {



        const userValue = {
            user: this.state.user,
            logoutUser: this.logout,
            loginUser: this.login,
        }

        


        if (this.state.user === null) { return null }

        return (

            <UserProvider value={userValue}>

                <Layout>
                    <Route exact path="/login" name="Inicie sesi�n" component={Login} />
                    <PrivateRoute authed={userValue.user.isAuth} exact path='/' component={Home} />                    
                    <PrivateRoute authed={userValue.user.isAuth} path='/Productos' component={Product} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/NuevoProducto' component={ProductForm}/>
                    <PrivateRoute authed={userValue.user.isAuth} path='/EditandoProducto/:value' component={ProductForm} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/CategoriasDeProductos' component={ProductCategory} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/NuevaCategoriaDeProducto' component={ProductCategoryForm} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/EditandoCategoriaDeProducto/:value' component={ProductCategoryForm} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/SubCategoriasDeProductos' component={ProductSubCategory} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/NuevaSubCategoriaDeProducto' component={ProductSubCategoryForm} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/EditandoSubCategoriaDeProducto/:value' component={ProductSubCategoryForm} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/Laboratorios' component={Laboratory} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/NuevoLaboratorio' component={LaboratoryForm} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/EditandoLaboratorio/:value' component={LaboratoryForm} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/Marcas' component={Brand} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/NuevaMarca' component={BrandForm} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/EditandoMarca/:value' component={BrandForm} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/Ofertas' component={Offer} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/NuevaOferta' component={OfferForm} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/EditandoOferta/:value' component={OfferForm} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/Pedidos' component={Order} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/VerPedido/:value' component={OrderForm} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/Cliente' component={Client} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/VerCliente/:value' component={ClientForm} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/Descuentos/' component={Discount} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/NuevoDescuento/' component={DiscountForm} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/EditandoDescuento/:value' component={DiscountForm} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/Textos/' component={SiteText} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/NuevoTexto/' component={SiteTextForm} />   
                    <PrivateRoute authed={userValue.user.isAuth} path='/EditandoTexto/:value' component={SiteTextForm} />

                    <PrivateRoute authed={userValue.user.isAuth} path='/Galeria/' component={Gallery} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/NuevaGaleria/' component={GalleryForm} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/EditandoGaleria/:value' component={GalleryForm} />

                    <PrivateRoute authed={userValue.user.isAuth} path='/Slider/' component={Slider} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/NuevoSlider/' component={SliderForm} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/EditandoSlider/:value' component={SliderForm} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/PlataformasDePago/' component={PaymentPlatform} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/zonas-de-entrega-propia/' component={DeliveryZone} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/nuevaZonaDeEntrega/' component={DeliveryZoneForm} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/editandoZonaDeEntrega/:value' component={DeliveryZoneForm} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/productos-en-home/' component={ProductGroup} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/nuevosProductosEnHome/' component={ProductGroupForm} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/editandoProductosEnHome/:value' component={ProductGroupForm} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/empresas-de-correo/' component={EmailCompany} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/nuevaEmpresaDeCorreo/' component={EmailCompanyForm} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/editandoEmpresaDeCorreo/:value' component={EmailCompanyForm} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/stock/' component={Stock} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/editando-stock/:value' component={StockForm} />  

                    <PrivateRoute authed={userValue.user.isAuth} path='/reservas-con-receta/' component={ReserveWithRecipe} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/ver-receta/:value' component={ReserveWithRecipeForm} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/Usuarios' component={User} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/NuevoUsuario' component={UserForm} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/EditandoUsuario/:value' component={UserForm} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/resetearpassword/:value' component={ResetPasswordForm} />


                    <PrivateRoute authed={userValue.user.isAuth} path='/Perfiles' component={Role} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/NuevoPerfil' component={RoleForm} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/EditandoPerfil/:value' component={RoleForm} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/error-403/' component={Page403} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/images/' component={Images} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/precios/' component={Price} />

                    <PrivateRoute authed={userValue.user.isAuth} path='/sucursales/' component={Store} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/editando-sucursal/:value' component={StoreForm} />  
                    <PrivateRoute authed={userValue.user.isAuth} path='/nueva-sucursal/' component={StoreForm} />  


                    <PrivateRoute authed={userValue.user.isAuth} path='/prepagas/' component={PrepaidHealth} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/editando-prepaga/:value' component={PrepaidHealthForm} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/nueva-prepaga/' component={PrepaidHealthForm} />  




                    <PrivateRoute authed={userValue.user.isAuth} path='/ofertas-por-marcas/' component={GalleryBrandOffers} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/nueva-imagen-oferta-por-marca/' component={GalleryBrandOffersForm} />  
                    <PrivateRoute authed={userValue.user.isAuth} path='/editando-imagen-oferta-por-marca/:value' component={GalleryBrandOffersForm} />  

                
                    <PrivateRoute authed={userValue.user.isAuth} path='/flyers' component={Flyer} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/editando-flyer/:value' component={FlyerForm} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/nuevo-flyer' component={FlyerForm} />


                    <PrivateRoute authed={userValue.user.isAuth} path='/segmentos' component={ProductSegment} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/editando-segmento/:value' component={ProductSegmentForm} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/nuevo-segmento' component={ProductSegmentForm} />


                    <PrivateRoute authed={userValue.user.isAuth} path='/productos-flyers/' component={ProductGroupFlyer} />
                    <PrivateRoute authed={userValue.user.isAuth} path='/nuevo-productos-flyers/' component={ProductGroupFlyerForm} />

                </Layout>
            </UserProvider>
    );
  }
}
