import React, { Component } from 'react';
import { axiosInstance } from '../../utils/axiosInstance';
import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import store from '../../store';
import { Add } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ImageUploader from 'react-images-upload';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Edit, Delete } from '@material-ui/icons';
import swal from 'sweetalert'



export class ProductForm extends Component {

    constructor(props) {
        super(props);

        this.state = {

            productCategories: [],
            productSubcategories: [],
            productSegments: [],

            productLaboratories: [],
            productBrands:[],


        
            productID: this.props.match.params.value,
            barCode: '',
            name: '',
            url:'',
            shortDescription:'',
            productCategoryID: '',
            productSubCategoryID: 0,
            productSegmentID: 0,

            productBrandID: '',
            laboratoryID: '',
            stock: '',
            price: '',            
            weight: '',            
            order: '',            
            descriptions: [],
            images:[],
            enabled: false,
            isFlyer: false,

            isEdit: this.props.match.params.value != undefined,
            tabValue: '0',

            //image modal
            
            form: new FormData(),
            isImageEdit:false,
            modalTitle: '',
            imageID: '',
            imageOrder: '',
            image: '',
            warningImageText: '',


        };

        this.onDropImage = this.onDropImage.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.onSubmitModal = this.onSubmitModal.bind(this);   

        this.handleNewImageClick = this.handleNewImageClick.bind(this);
        this.handleEditImageClick = this.handleEditImageClick.bind(this);   
        this.handleDeleteImageClick = this.handleDeleteImageClick.bind(this);   
        this.getImages = this.getImages.bind(this);   
        
    }

    CreateStyles() {
        return {
            //marginRight: '10px',
            //marginBottom: '10px',
            float: 'right'
        }
    }

    async executeRESTCall(action) {

        let formData = {
            productID: this.state.productID,

            barCode: this.state.barCode,

            name: this.state.name,
            url: this.state.url,

            shortDescription: this.state.shortDescription,


            productCategoryID: this.state.productCategoryID,
            productSubCategoryID: this.state.productSubCategoryID,
            productSegmentID: this.state.productSegmentID,

            productBrandID: this.state.productBrandID,
            laboratoryID: this.state.laboratoryID,
            stock: this.state.stock,
            price: this.state.price,
            weight: this.state.weight,
            order: this.state.order,
            descriptions: this.state.descriptions,
            enabled: this.state.enabled,
            isFlyer: this.state.isFlyer

        };

        if (action === "POST") {
            await axiosInstance.post('/api/Product/', formData)
                .then(response => {

                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: "Operación exitosa"
                    })

                }).catch(error => {
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: error.response.data.message
                    })
                });
        }

        if (action === "PUT") {
            await axiosInstance.put('/api/Product/', formData)
                .then(response => {

                    this.props.history.push('/productos');
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: "Operación exitosa"
                    })

                }).catch(error => {
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: error.response.data.message
                    })
                });
        }     

     

      
  

    }   

    onSubmit = e => {

        if (!this.state.isEdit) {
            this.executeRESTCall("POST");
        }
        else {
            this.executeRESTCall("PUT");
        }
    
        e.preventDefault();
    }

    onChange = (event, value) => {

        if (event.target.type === "checkbox") {
            this.setState({
                [event.target.name]: event.target.checked,
            })
        }
        else {
            if (event.target.name === "name") {
                var str = event.target.value;
                str = str.replace(/\s+/g, '-').toLowerCase();

                this.setState({
                    [event.target.name]: event.target.value,
                    url: str,
                })
            }


            this.setState({
                [event.target.name]: event.target.value
            });
        }


    
    }     

    onChangeProductCategory = (event, value) => {

        this.setState({
            productCategoryID: event.target.value,
            productSubCategoryID: 0,
            productSegmentID: 0,

            productSubcategories:[],
            productSegments:[]

        }, () => {
            this.getProductSubCategoryForSelect();
        });
    }


    onChangeProductSubCategory = (event, value) => {

        this.setState({
            productSubCategoryID: event.target.value,
            productSegmentID: 0,

            
            productSegments: []

        }, () => {
                this.getProductSegmentForSelect();
        });
    }


   

    handleTabChange = (event, newValue) => {

        this.setState({
            tabValue: newValue,
        })
    };

    handleChange(e, index) {


        let descriptions = [...this.state.descriptions];

        let description = { ...descriptions[index] };

        if (e.target.name == 'description') {
            description.description = e.target.value;
        }

        if (e.target.name == 'language') {
            description.languageID = e.target.value;
        }


        descriptions[index] = description;

        this.setState({ descriptions });

    }

    renderOptions() {
        return this.state.productCategories.map((dt, i) => {            
            return (
                <MenuItem
                    label="Seleccione una categoría"
                    value={dt.id}
                    key={i} name={dt.text}>{dt.text}
                </MenuItem>
            );
        });
    }

    renderLaboratoryOptions() {
        return this.state.productLaboratories.map((dt, i) => {
            return (
                <MenuItem
                    label="Seleccione un laboratorio"
                    value={dt.id}
                    key={i} name={dt.text}>{dt.text}
                </MenuItem>
            );
        });
    }

    renderLanguageOptions() {
        return this.state.languages.map((dt, i) => {
            return (
                <MenuItem
                    label="Seleccione un idioma"
                    value={dt.id}
                    key={i}
                    name={dt.text}>{dt.text}
                </MenuItem>
            );
        });
    }

    renderBrandOptions() {
        return this.state.productBrands.map((dt, i) => {
            return (
                <MenuItem
                    label="Seleccione una marca"
                    value={dt.id}
                    key={i} name={dt.text}>{dt.text}
                </MenuItem>
            );
        });
    }

    renderSubCategoryOptions() {
        return this.state.productSubcategories.map((dt, i) => {
            return (

             
                <MenuItem
                    label="Seleccione una subcategoría"
                    value={dt.id}
                    key={i} name={dt.text}>{dt.text}
                    </MenuItem>
            
            );
        });
    }

    renderSegmentOptions() {
        return this.state.productSegments.map((dt, i) => {
            return (


                <MenuItem
                    label="Seleccione un segmento"
                    value={dt.id}
                    key={i} name={dt.text}>{dt.text}
                </MenuItem>

            );
        });
    }

    async componentDidMount() {

        const productCategoryResponse = await axiosInstance.get(`/api/Common/GetproductCategoryForSelect`);               
        const productLaboratoryResponse = await axiosInstance.get(`/api/Common/GetlaboratoriesForSelect`);               
        const productBrandResponse = await axiosInstance.get(`/api/Common/GetBrandsForSelect`);               

        this.setState({
            productCategories: productCategoryResponse.data,
            productLaboratories: productLaboratoryResponse.data,
            productBrands: productBrandResponse.data,   

        });

     

        if (this.state.isEdit) {

            await axiosInstance.get(`api/Product/GetByID/${this.state.productID}`).then(
                response => {
                    this.setState({
                        productID: response.data.productID,
                        barCode: response.data.barCode,
                        name: response.data.name,
                        url: response.data.url,
                        shortDescription: response.data.shortDescription,
                        productCategoryID: response.data.productCategoryID,
                        productSubCategoryID: response.data.productSubCategoryID,
                        productSegmentID: response.data.productSegmentID,
                        productBrandID: response.data.productBrandID,
                        laboratoryID: response.data.laboratoryID,
                        latitude: response.data.latitude,
                        longitude: response.data.longitude,
                        checkIn: response.data.checkIn == null ? '' : response.data.checkIn,
                        checkOut: response.data.checkOut == null ? '' : response.data.checkOut,
                        descriptions: response.data.descriptions,
                        images: response.data.images,
                        price: response.data.price,
                        stock: response.data.stock,
                        enabled: response.data.enabled,
                        isFlyer: response.data.isFlyer,
                        weight: response.data.weight,
                        order: response.data.order,

                    }, () => {

                        this.getProductSubCategoryForSelect();
                        this.getProductSegmentForSelect();
                    });
                });   

       
        }      


    }

    async getImages() {
        await axiosInstance.get(`api/Product/GetByID/${this.state.productID}`).then(
            response => {
                this.setState({        
                    images: response.data.images, 

                });
            });         
    }


    async getProductSubCategoryForSelect() {



        if (this.state.productCategoryID != "") {

            const productSubCategoryResponse = await axiosInstance.get(`/api/Product/GetProductSubCategoryForSelect/${this.state.productCategoryID}`);

            this.setState({
                productSubcategories: productSubCategoryResponse.data,

            });
		}

      
    }


    async getProductSegmentForSelect() {

        if (this.state.productSubCategoryID != 0) {

            const productSegmentResponse = await axiosInstance.get(`/api/Product/GetProductSegmentForSelect/${this.state.productSubCategoryID}`);

            this.setState({
                productSegments: productSegmentResponse.data,

            });
        }
        else {
            console.log("asdsdaadsasdadsadss 4343 ");
		}
    }
  

    renderHeaderTitle() {
        let title;

        if (this.state.isEdit) {
            title = 'Editando producto ' + this.state.name ;
        }
        else {
            title = 'Crea un nuevo producto';
        }

        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <h4>
                            {title}
                            <small >
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Typography color="inherit">
                                        Productos
                                        </Typography>

                                    <Link color="inherit" to="/Productes" >
                                        <Typography color="textPrimary">Administración</Typography>
                                    </Link>

                                </Breadcrumbs>
                            </small>
                        </h4>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Link to="/Productos">
                            <Button                      
                                color="default"
                                size="small"
                                startIcon={<CancelIcon />}
                                style={this.CreateStyles()}
                            >
                                Cancelar
                        </Button>
                        </Link>
                    </Grid>
                </Grid>

                <hr />
            </div>
        );
    }

    

    
    renderGeneralInformation() {


        if (this.state.tabValue == '0') {
            return (
                <Paper square style={{ padding: "15px" }}>
                    <ValidatorForm ref="form"
                        onSubmit={this.onSubmit}
                        onError={errors => console.log(errors)}
                        >

                        <Grid container spacing={2} >
                            <Grid item xs={12} sm={12} md={5}>

                                <Grid container spacing={2} >

                                    <Grid item sm={12} xs={12}>
                                        <TextValidator id="barCode"
                                            style={{
                                                width: "100%"

                                            }}
                                            name="barCode"
                                            label="Código de barras"
                                            variant="outlined"
                                            size="small"
                                            onChange={this.onChange}
                                            value={this.state.barCode}
                                            validators={['maxStringLength:20']}
                                            errorMessages={['Máximo 20 carácteres']}
                                            autoFocus
                                            autoComplete="off"

                                        />
                                    </Grid>


                                    <Grid item sm={12} xs={12}>
                                        <TextValidator id="Name"
                                            style={{
                                                width: "100%"

                                            }}
                                            name="name"
                                            label="Nombre"
                                            variant="outlined"
                                            size="small"
                                            onChange={this.onChange}
                                            value={this.state.name}
                                            validators={['required', 'maxStringLength:80']}
                                            errorMessages={['El nombre del producto es requerido', 'Máximo 80 carácteres']}
                                            
                                            autoComplete="off"

                                        />
                                    </Grid>


                                        <Grid item sm={12} xs={12}>

                                            <TextValidator id="url"
                                                style={{
                                                    width: "100%"
                                                }}
                                                name="url"
                                                label="Url"
                                                variant="outlined"
                                                size="small"
                                                onChange={this.onChange}
                                                value={this.state.url}
                                                validators={[
                                                    'required'
                                                    , 'matchRegexp:^[a-zA-Z\-0-9]+$'
                                                ]}
                                                errorMessages={['El url es requerido', 'Carácteres no válidos (& / ? . , _)']}
                                                autoComplete="off"
                                            />

                                        </Grid>



                                   

                                    <Grid item sm={12} xs={12}>
                                        <TextValidator id="shortDescription"
                                            style={{
                                                width: "100%"

                                            }}
                                            name="shortDescription"
                                            label="Detalle"
                                            variant="outlined"
                                            size="small"
                                            onChange={this.onChange}
                                            value={this.state.shortDescription}
                                            validators={['required', 'maxStringLength:140']}
                                            errorMessages={['La descripción corta es requerida', 'Máximo 140 carácteres']}
                                            autoComplete="off"

                                        />
                                    </Grid>

                                    <Grid item sm={12} xs={12}>

                                        <Divider />
                                    </Grid>

                                    <Grid item sm={12} xs={12}>

                                        <div className="padd50">
                                            <SelectValidator
                                                style={{ width: "100%" }}
                                                name="productCategoryID"
                                                variant="outlined"
                                                size="small"
                                                label="Categoría"
                                                value={this.state.productCategoryID}
                                                validators={['required']}
                                                errorMessages={['La categoría es requerida']}
                                                onChange={this.onChangeProductCategory}>
                                                {this.renderOptions()}

                                            </SelectValidator>
                                        </div>



                                    </Grid>

                                    <Grid item sm={12} xs={12}>
                                        <div className="padd50">
                                            <SelectValidator
                                                style={{ width: "100%" }}
                                                name="productSubCategoryID"
                                                variant="outlined"
                                                size="small"
                                                label="Subcategoría"
                                                value={this.state.productSubCategoryID}
                                  
                                                onChange={this.onChangeProductSubCategory}>
                                                {this.renderSubCategoryOptions()}
                                                </SelectValidator>
                                        </div>
                                    </Grid>

                                    <Grid item sm={12} xs={12}>
                                        <div className="padd50">
                                            <SelectValidator
                                                style={{ width: "100%" }}
                                                name="productSegmentID"
                                                variant="outlined"
                                                size="small"
                                                label="Segmento"
                                                value={this.state.productSegmentID}

                                                onChange={this.onChange}>
                                                {this.renderSegmentOptions()}
                                            </SelectValidator>
                                        </div>
                                    </Grid>

                                    <Grid item sm={12} xs={12}>

                                        <Divider />
                                    </Grid>


                                    <Grid item sm={12} xs={12}>

                                        <div className="padd50">
                                            <SelectValidator
                                                style={{ width: "100%" }}
                                                name="laboratoryID"
                                                variant="outlined"
                                                size="small"
                                                label="Laboratorio"
                                                value={this.state.laboratoryID}
                                                validators={['required']}
                                                errorMessages={['El laboratorio es requerido']}
                                                onChange={this.onChange}>
                                                {this.renderLaboratoryOptions()}

                                            </SelectValidator>
                                        </div>



                                    </Grid>

                                    <Grid item sm={12} xs={12}>

                                        <div className="padd50">
                                            <SelectValidator
                                                style={{ width: "100%" }}
                                                name="productBrandID"
                                                variant="outlined"
                                                size="small"
                                                label="Marca"
                                                value={this.state.productBrandID}
                                                validators={['required']}
                                                errorMessages={['La marca es requerida']}
                                                onChange={this.onChange}>
                                                {this.renderBrandOptions()}

                                            </SelectValidator>
                                        </div>



                                    </Grid>                                                               

                                    <Grid item sm={4} xs={6}>
                                        <TextValidator id="price"
                                            style={{ width: "100%" }}
                                            name="price"
                                            label="Precio"
                                            variant="outlined"
                                            size="small"
                                            onChange={this.onChange}
                                            value={this.state.price}
                                            autoComplete="off"
                                            validators={['required', 'matchRegexp:^[0-9]+(\.[0-9]{1,2})?$', 'minNumber:1']}
                                            errorMessages={['El precio es requerido', 'Solo números', 'No puede ser 0']}
                                        />

                                        <FormHelperText style={{ color: '#777' }}>Utilizar '.' para separación de decimales</FormHelperText>

                                    </Grid>

                                    <Grid item sm={4} xs={6}>
                                        <TextValidator id="stock"
                                            style={{ width: "100%" }}
                                            name="stock"
                                            label="Stock"
                                            variant="outlined"
                                            size="small"
                                            onChange={this.onChange}
                                            value={this.state.stock}
                                            autoComplete="off"

                                            validators={['required', 'matchRegexp:^[0-9]+$']}
                                            errorMessages={['El stock es requerido', 'Solo números']}

                                        />
                                    </Grid>

                                    <Grid item sm={4} xs={12}>
                                        <TextValidator id="weight"
                                            style={{ width: "100%" }}
                                            name="weight"
                                            label="Peso (gramos)"
                                            variant="outlined"
                                            size="small"
                                            onChange={this.onChange}
                                            value={this.state.weight}
                                            autoComplete="off"

                                            validators={['required', 'matchRegexp:^[0-9]+$', 'minNumber:1']}
                                            errorMessages={['El peso es requerido', 'Solo números', 'No puede ser 0']}

                                        />
                                    </Grid>

                                    <Grid item sm={4} xs={12}>
                                        <TextValidator id="order"
                                            style={{ width: "100%" }}
                                            name="order"
                                            label="Orden"
                                            variant="outlined"
                                            size="small"
                                            onChange={this.onChange}
                                            value={this.state.order}
                                            autoComplete="off"

                                            validators={['required', 'matchRegexp:^[0-9]+$']}
                                            errorMessages={['El orden requerido', 'Solo números']}

                                        />
                                    </Grid>


                                    <Grid item sm={12} xs={12}>
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.enabled} onChange={this.onChange} name="enabled" />}
                                            label="Habilitado"
                                        />
                                    </Grid>

                                    <Grid item sm={12} xs={12}>
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.isFlyer} onChange={this.onChange} name="isFlyer" />}
                                            label="es Flyer"
                                        />
                                    </Grid>

                                    <Grid item sm={6} xs={6}>

                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            startIcon={<SaveIcon />}
                                            type="submit"

                                        >
                                            Guardar
                                    </Button>


                                    </Grid>

                                </Grid>

                            </Grid>

                            <Grid item xs={12} sm={12} md={7}>

                                <Grid container spacing={1}>
                                    <Grid item xs={6} sm={4} md={4} >
                                        <h6 style={{ marginTop: '10px'}}>Descripciones</h6>
                                    </Grid>
                                    <Grid item xs={6} sm={8} md={8}>
                                        <Button
                                            color="default"
                                            size="small"
                                            startIcon={<Add />}
                                            style={this.CreateStyles()}
                                            onClick={(e) => this.addDescription(e)}
                                        >
                                            Agregar
                                        </Button>
                                    </Grid> 
                                </Grid>

                                
                                <hr style={{marginTop:'0px'}}/>

                                {
                                    this.state.descriptions.map((description, index) => {

                                        return (
                                            <div key={index}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12} md={2}>
                                                       
                                                    </Grid>

                                                    <Grid item xs={10} md={9}>

                                                        <TextValidator
                                                            style={{
                                                                width: "100%"
                                                            }}
                                                            name="description"
                                                            label="Descripción"
                                                            size="small"
                                                            onChange={(e) => this.handleChange(e, index)}
                                                            value={description.description}
                                                            validators={['required', 'maxStringLength:1500']}
                                                            errorMessages={['La descripción es requerida', 'Máximo 1500 carácteres']}
                                                            multiline
                                                            rows={7}
                                                            variant="outlined"

                                                        />
                                                    </Grid>
                                                    <Grid item xs={2} md={1}>
                                                      

                                                            <Tooltip title="Eliminar descripción">
                                                            <IconButton                                                                
                                                                onClick={(e) => this.removeDescription(index)}
                                                                color="secondary"
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                                            
                                                            </Tooltip>

                                                       
                                                    </Grid>

                                                </Grid>

                                                <hr />


                                                </div>


                                            )

                                    })
                                }

                               

                            </Grid>
                        </Grid>

                    </ValidatorForm>
                </Paper>
            );
        }
    }


    renderImageTab() {
        if (this.state.tabValue == '2') {
            return (
                <Paper square style={{ padding: "15px" }}>



                    <Button
                        color="default"
                        size="small"
                        startIcon={<Add />}
                        onClick={(e) => this.handleNewImageClick(e)}
                    >
                        Agregar imagén
                    </Button>

                    <br />
                    <br />

                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Imágen</TableCell>
                                    <TableCell align="left">Descripción</TableCell>
                                    <TableCell>Orden</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.images.map((img) => (
                                    <TableRow key={img.id}>
                                        <TableCell component="th" scope="row">
                                            <img src={img.url} style={{ width: '100px' }} />
                                        </TableCell>
                                        <TableCell align="left">{img.alternativeText}</TableCell>
                                        <TableCell> {img.order}</TableCell>
                                        <TableCell>
                                            <Tooltip title="Editar">
                                                <IconButton
                                                    aria-label="editar"
                                                    onClick={this.handleEditImageClick}
                                                    data-row_id={img.id}
                                                >
                                                    <Edit color="primary" />
                                                </IconButton>
                                            </Tooltip>

                                        </TableCell>
                                        <TableCell>
                                            <Tooltip title="Eliminar">
                                                <IconButton
                                                    aria-label="delete"
                                                    onClick={this.handleDeleteImageClick}
                                                    data-row_id={img.id}
                                                >
                                                    <Delete color="secondary" />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>



                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>);
        }
    }






    closeDialog() {
        this.setState({
            dialogOpen: false
        })
    }


  
 

   

    hideIfNewProduct() {
        if (this.state.isEdit)
            return { display: '' };
        else {
            return { display: 'none' };
        }
    }


    addDescription() {
        this.setState(
            { descriptions: [...this.state.descriptions, ""] }
        )
    }

    removeDescription(index) {
        let descriptions = [...this.state.descriptions];

        descriptions.splice(index, 1);

        this.setState({
            descriptions: descriptions
        });
    }



    onDropImage(pictureFiles, pictureDataURLs) {

        let form = this.state.form;
        var element = pictureFiles[0];

        form.append('image', element);

        this.setState({
            form: form,
            image: element === undefined ? '' : element.name,
        });

        if (this.state.documentFront !== '' && this.state.documentBack !== '') {
            this.setState({
                warningTextDocument: '',
            });
        }
    }

    addImage = async (image) => {

        this.setState({
            warningImageText: '',
        });

        var form = this.state.form;
        var validation = true;

        form.append('imageOrder', this.state.imageOrder);
        form.append('productID', this.state.productID);
        form.append('imageDescription', this.state.imageDescription);


        if (this.state.isImageEdit) {
            form.append('imageID', this.state.imageID);

            await axiosInstance.put('/api/Product/UpdateImage', form)
                .then(response => {

                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: "Operación exitosa"
                    })

                }).catch(error => {
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: error.response.data.message
                    })
                });

            this.setState({
                dialogOpen: false,
            });

            await this.getImages();
        }
        else {
            if (this.state.image === '') {
                validation = false;
                this.setState({
                    warningImageText: "Las imagen es requerida"
                });
            }
            else {


                await axiosInstance.post('/api/Product/SaveImage', form)
                    .then(response => {

                        store.dispatch({
                            type: "SNACKBAR_SUCCESS",
                            message: "Operación exitosa"
                        })

                    }).catch(error => {
                        store.dispatch({
                            type: "SNACKBAR_SUCCESS",
                            message: error.response.data.message
                        })
                    });


                
                this.setState({
                    dialogOpen: false,
                });

                await this.getImages();
            }            
        }

     

    }


    async onSubmitModal(e) {
        e.preventDefault();
        await this.addImage(this.state.file);
    }


    async handleNewImageClick(e) {
        this.setState({
            isImageEdit: false,
            modalTitle: 'Nueva imagen',
            dialogOpen: true,

            form: new FormData(),
            imageID: '',
            imageOrder: '',
            imageDescription:'',
            image: '',
            defaultImage: '',
            warningImageText: '',
        })
    }

    async handleDeleteImageClick(e) {

        var id = e.currentTarget.dataset.row_id;

        const deleteRow = await swal({
            title: "¿Confirma la operación?",
            text: "",
            icon: "warning",
            dangerMode: true,
            buttons: true,

        });

        if (deleteRow) {



            await axiosInstance.delete('/api/product/deleteImage/' + id)
                .then(response => {

                    swal("Se eliminó el registro", {
                        icon: "success",
                    });
        
                }).catch(error => {

                    swal({
                        icon: 'error',
                        title: 'Oops, algo sucedió...',
                        text: error.response.data.message
                    })

                });


 
                await this.getImages();
        }
    }
    

    async handleEditImageClick(e) {

        var id = e.currentTarget.dataset.row_id;
        console.log("images: " + id);

        const image = this.state.images.filter(img => img.id == id);
        console.log("image: " , image[0]);

        this.setState({
            form: new FormData(),
            isImageEdit: true,
            dialogOpen: true,
            modalTitle: 'Editando imagen',
            imageID: image[0].id,
            imageOrder: image[0].order,
            image: '',
            imageDescription: image[0].alternativeText,
            defaultImage:image[0].url,
            
        })
    }

    render() {      

        return (
            <div>
                {this.renderHeaderTitle()}
                <Paper square>
                    <Tabs
                        value={this.state.tabValue}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={this.handleTabChange}
                        aria-label="disabled tabs example"
                        scrollButtons="on"
                    >
                        <Tab label="Información general" value="0" />       
                        <Tab label="Imágenes" value="2" style={this.hideIfNewProduct()} />/> 
                    </Tabs>
                </Paper>
                
                {this.renderGeneralInformation()}

                {this.renderImageTab()}

                <Dialog open={this.state.dialogOpen} onClose={this.closeDialog} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth='md'>
                    <DialogTitle id="form-dialog-title">{this.state.modalTitle}</DialogTitle>

                    <ValidatorForm ref="form"
                        onSubmit={this.onSubmitModal}
                        onError={errors => console.log(errors)}
                    >

                        <DialogContent>
                            <DialogContentText>
                            
                            </DialogContentText>         

                            <Grid container spacing={2} >
                                <Grid item sm={12} xs={12}>

                                    <TextValidator id="ImageOrder"
                                style={{
                                    width: "100%"

                                }}
                                name="imageOrder"
                                label="Orden"
                                variant="outlined"
                                size="small"
                                onChange={this.onChange}
                                value={this.state.imageOrder}
                                validators={['required','minNumber:0', 'maxNumber:255', 'matchRegexp:^[0-9]+$']}
                                errorMessages={['El orden de la imagen es requerido', 'No puede ser 0', 'No puede ser mayor a 255', 'Solo números']}
                                autoFocus
                                autoComplete="off"

                                    />

                                </Grid>
                                <Grid item sm={12} xs={12}>
                                    <TextValidator id="imageDescription"
                                        style={{
                                            width: "100%"

                                        }}
                                        name="imageDescription"
                                        label="Descripción"
                                        variant="outlined"
                                        size="small"
                                        onChange={this.onChange}
                                        value={this.state.imageDescription}
                                        validators={['required']}
                                        errorMessages={['La descripción de la imagen es requerida']}
                                        autoComplete="off"

                                    />

                                </Grid>
                            </Grid>

                            {
                                this.state.isImageEdit ?
                                      <Grid container spacing={2} >
                                <Grid item sm={6} xs={6}>
                                    <img src={this.state.defaultImage} style={{ width: '300px' }} />
                                </Grid>
                                <Grid item sm={6} xs={6}>
                                    <ImageUploader
                                        withPreview
                                        withIcon={false}
                                        buttonClassName="primary-color bg-base border-circle add-Button"
                                        buttonText="Seleccione una imágen"
                                        onChange={this.onDropImage}
                                        imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                                        maxFileSize={5242880}
                                        label="Tamaño recomendado 300 x 300"
                                        singleImage={true}
                                        defaultImage={this.state.defaultImage}
                                    />
                                    <FormHelperText style={{ color: '#f44336' }}>{this.state.warningImageText}</FormHelperText>
                                </Grid>
                            </Grid>
                            :


                                    <Grid container spacing={2} >
                                      
                                        <Grid item sm={12} xs={12}>
                                            <ImageUploader
                                                withPreview
                                                withIcon={false}
                                                buttonClassName="primary-color bg-base border-circle add-Button"
                                                buttonText="Seleccione una imágen"
                                                onChange={this.onDropImage}
                                                imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                                                maxFileSize={5242880}
                                                label="Tamaño recomendado 300 x 300"
                                                singleImage={true}
                                                defaultImage={this.state.defaultImage}
                                            />
                                            <FormHelperText style={{ color: '#f44336' }}>{this.state.warningImageText}</FormHelperText>
                                        </Grid>
                                    </Grid>

                            }

                        
                         

                     



                        </DialogContent>

                        <DialogActions>                                        

                            <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<SaveIcon />}
                            type="submit"

                        >
                            Guardar imagen
                        </Button>

                            <Button onClick={this.closeDialog} color="primary">
                            Cancelar
                        </Button>

                        </DialogActions>
                    </ValidatorForm>
                </Dialog>
            </div>
        )
    }
}


