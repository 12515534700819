import React, { Component } from 'react';
import { axiosInstance } from '../../utils/axiosInstance';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import Grid from '@material-ui/core/Grid'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import store from '../../store';
import { Paper } from '@material-ui/core';

import TopProductCard from '../../components/TopProductCard';

export class DiscountForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            discountID: 0,
            description: '',
            percentage: '',
            labelFirstText: '',
            labelSecondText: '',
            minimumAmount: '',
        };

        this.discountID = this.props.match.params.value;
        this.isEdit = this.props.match.params.value != undefined;
    }


    async executeRESTCall(action) {
        const url = '/api/Discount/';


        if (action === "POST") {
            await axiosInstance.post(url, this.state)
                .then(response => {

                    this.props.history.push('/Descuentos');
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: "Operación exitosa"
                    })

                }).catch(error => {
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: error.response.data.message
                    })
                });
        }

        if (action === "PUT") {
            await axiosInstance.put(url, this.state)
                .then(response => {

                    this.props.history.push('/Descuentos');
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: "Operación exitosa"
                    })

                }).catch(error => {
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: error.response.data.message
                    })
                });
        }     

    
    }
   

    onSubmit = e => {

        if (!this.isEdit) {
            this.executeRESTCall("POST");
        }
        else {
            this.executeRESTCall("PUT");
        }
    
        e.preventDefault();
    }


    onChange =(event, value) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    async componentDidMount() {

        if (this.isEdit) {
            await axiosInstance.get(`api/Discount/GetByID/${this.discountID}`).then(
                response => {
                    this.setState({
                        discountID: response.data.id,
                        description: response.data.description,
                        percentage: response.data.percentage,
                        labelFirstText: response.data.labelFirstText,
                        labelSecondText: response.data.labelSecondText,
                        minimumAmount: response.data.minimumAmount,
                    });
                });
        }
    }

    CreateStyles() {
        return {            
            marginRight: '10px',
            marginBottom: '10px',
            float: 'right'
        }
    }

    HeaderTitle() {
        let title;

        if (this.isEdit) {
            title = 'Editando descuento';
        }
        else {
            title ='Crea un nuevo descuento';
        }

        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                        <h4>
                            {title}
                            <small >
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Typography color="inherit">
                                        Promociones
                                        </Typography>

                                    <Link color="inherit" to="/descuentos" >
                                        <Typography color="textPrimary">Descuentos</Typography>
                                </Link>

                                </Breadcrumbs>
                            </small>
                        </h4>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <Link to="/descuentos">
                            <Button
                                variant="default"
                                color="default"
                                size="small"
                                startIcon={<CancelIcon />}
                                style={this.CreateStyles()}
                            >
                                Cancelar
                        </Button>
                        </Link>
                    </Grid>
                </Grid>

                <hr />
            </div>
        );

        
    }

    render() {


        return (

            <div>

                {this.HeaderTitle()}

               
            
                    <ValidatorForm ref="form"
                onSubmit={this.onSubmit}
                onError={errors => console.log(errors)}
                style={{ padding: "10px" }}>               

                        <Grid container spacing={2} >

                            <Grid item xs={12} sm={6} md={6} lg={4}>
                            <Paper style={{ padding: '15px' }} elevation={1}>

                                    <Grid container spacing={2} >
                       
                                        <Grid item sm={12} xs={12}>
                                            <TextValidator id="description"
                                                style={{ width: "100%" }}
                                                name="description"
                                                label="Descripción"
                                                variant="outlined"
                                                size="small"
                                                onChange={this.onChange}
                                                value={this.state.description}
                                                validators={['required', 'maxStringLength:50']}
                                                errorMessages={['El descripción es requerida', 'Máximo 50 carácteres']}
                                                autoFocus 
                                                autoComplete="off"
                                            />
                                        </Grid>
                                  
                                        <Grid item sm={12} xs={12}>

                                        <TextValidator id="percentage"
                                            style={{ width: "100%" }}
                                            name="percentage"
                                            label="Porcentaje"
                                            variant="outlined"
                                            size="small"
                                            onChange={this.onChange}
                                            value={this.state.percentage}
                                            validators={['required', 'matchRegexp:^[0-9]+$']}
                                            errorMessages={['El porcentaje es requerido', 'Solo números']}
                                            autoComplete="off"
                                        />


                                    </Grid>

                                    <Grid item sm={12} xs={12}>

                                        <TextValidator id="labelFirstText"
                                            style={{ width: "100%" }}
                                            name="labelFirstText"
                                            label="Texto a mostrar"
                                            variant="outlined"
                                            size="small"
                                            onChange={this.onChange}
                                            value={this.state.labelFirstText}
                                            validators={['required', 'maxStringLength:10']}
                                            errorMessages={['Requerido', 'Máximo 10 carácteres']}
                                            autoComplete="off"
                                        />


                                    </Grid>

                                    <Grid item sm={12} xs={12}>

                                        <TextValidator id="labelSecondText"
                                            style={{ width: "100%" }}
                                            name="labelSecondText"
                                            label="Descripción a mostrar"
                                            variant="outlined"
                                            size="small"
                                            onChange={this.onChange}
                                            value={this.state.labelSecondText}
                                            validators={['maxStringLength:24']}
                                            errorMessages={['Máximo 24 carácteres']}
                                            autoComplete="off"
                                        />


                                    </Grid>

                                    <Grid item sm={12} xs={12}>

                                        <TextValidator id="minimumAmount"
                                            style={{ width: "100%" }}
                                            name="minimumAmount"
                                            label="Cantidad mínima"
                                            variant="outlined"
                                            size="small"
                                            onChange={this.onChange}
                                            value={this.state.minimumAmount}
                                            validators={['required', 'matchRegexp:^[0-9]+$']}
                                            errorMessages={['La cantidad mínima es requerida', 'Solo números']}
                                            autoComplete="off"
                                        />


                                    </Grid>
                              

                                        <Grid item xs={12} md={12}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="medioum"
                                                startIcon={<SaveIcon />}
                                                type="submit"
                                                style={this.CreateStyles()}
                                            >
                                                Guardar
                                        </Button>

                                </Grid>
                          
                          
                
                                </Grid>

                                </Paper>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={8}>
                                <div className="iron-top-products-wrapper pb-0">

                                    <div className="iron-product-wrap my-0">
                                        <Grid container>
                                        <Grid item xs={12} sm={12} md={8} lg={4} className="mb-30 py-0">
                                            <h4>
                                                Ejemplo
                                            </h4>
                                <TopProductCard
                                    discountLabelFirstText={this.state.labelFirstText}
                                    discountLabelSecondText={this.state.labelSecondText}
                                        minimumAmount={this.state.minimumAmount} />

                                            </Grid> 
                                        </Grid> 
                                    </div>
                                </div>

                        </Grid>
                    </Grid>

                    </ValidatorForm>
            </div>
        )
    }
}