import React, { Component } from 'react';
import { axiosInstance } from '../../utils/axiosInstance';
import { Link } from 'react-router-dom';


import { AddCircleOutline, Edit, Delete, Done, Close } from '@material-ui/icons';



import { CustomDataTable } from '../../components/UI/CustomDataTable';


import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import swal from 'sweetalert'


const useStyles = makeStyles(theme => ({
    root: {
        padding:'12px',
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(3),
        },
    },
}));





export class Product extends Component {

    constructor(props) {
        super(props);
        this.state = {
            alertIsOpen: false,
        };

        

        this.dataTableRef = React.createRef();

    
    } 



    handleDeleteClick = async e => {



        var id = e.currentTarget.dataset.row_id;
        var text = e.currentTarget.dataset.row_text;

        const deleteRow = await swal({
            title: "¿Confirma la operación?",
            text: text,
            icon: "warning",
            dangerMode: true,
            buttons: true,

        });

        if (deleteRow) {

            await axiosInstance.delete('api/product/delete/' + id)
                .then(response => {

                    swal("Se eliminó el registro", {
                        icon: "success",
                    });
                    this.dataTableRef.current.refreshDataTable();

                }).catch(error => {

                    swal({
                        icon: 'error',
                        title: 'Oops, algo sucedió...',
                        text: error.response.data.message
                    })

                });
        }
    }


    async componentDidMount() {

        const columns = [

            {
                name: 'Orden',
                selector: 'order',
                sortable: true,
            },

            {
                name: 'Código',
                selector: 'barCode',
                sortable: true,
            },

            {
                name: 'Nombre',
                selector: 'name',
                sortable: true,
                cell: row => (<div style={{padding:'6px'}}><b style={{ marginTop: '8px' }}>{row.name}</b> <br /><span style={{ marginTop: '10px' }}>{row.shortDescription}</span></div>)
            },

         

            
            {
                name: 'Laboratorio',
                selector: 'laboratory',
                sortable: true,
                hide: 'sm',
            },
            {
                name: 'Categoría',
                selector: 'category',
                sortable: true,
                hide: 'md',
                maxwidth: '90px',
            },

            {
                name: 'Precio',
                selector: 'price',
                sortable: true,
                hide: 'md',
                width: '90px',
            },

            {
                name: 'Stock',
                selector: 'stock',
                sortable: true,
                hide: 'md',
                width: '90px',
            },

            {
                name: 'esFlyer',
                selector: 'isFlyer',
                sortable: true,
                hide: 'md',
                width: '90px',
                cell: d => d.isFlyer === true ? <Done style={{ color: 'green' }} /> : <Close style={{ color: 'red' }} />
            },

            {
                name: 'Habilitado',
                selector: 'enabled',
                sortable: true,
                hide: 'md',
                width: '90px',
                cell: d => d.enabled === true ? <Done style={{ color: 'green' }} /> : <Close style={{ color: 'red' }} />
            },

            {
                name: 'Opciones',
                button: true,
                cell: row =>
                    <Link to={"/EditandoProducto/" + row.id}>
                        <Tooltip title="Editar">
                            <IconButton aria-label="habitaciones">
                                <Edit />
                            </IconButton>
                        </Tooltip>
                    </Link>
                ,
                allowOverflow: true,
                width: '50px',


            },
            {
                cell: row =>
                    <Tooltip title="Eliminar">
                        <IconButton
                            aria-label="delete"
                            onClick={this.handleDeleteClick}
                            data-row_id={row.id}
                            data-row_text={row.name}>
                            <Delete color="secondary" />
                        </IconButton>
                    </Tooltip>
                ,
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
                width: '60px',
            },




        ];



        this.setState({
            columns: columns,                              

        });
    }



    async refreshDataTable() {
        this.setState({ pending: true });

        const response = await axiosInstance.get(this.getDataTableUrl());

        this.setState({
            pending: false,
            data: response.data.data,
            totalRows: response.data.total,
        });
    }

    
  

    render() {
        const { data, totalRows, alertIsOpen } = this.state;

        

            return (
                <div>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={7} md={8} lg={10}>
                            <h4>
                                Productos
                                <small >
                                    <Breadcrumbs aria-label="breadcrumb">
                                        <Typography color="inherit">
                                            Productos
                                        </Typography>
                                        <Typography color="textPrimary">Administración</Typography>
                                    </Breadcrumbs>
                                </small>
                            </h4>
                        </Grid>
                        <Grid item xs={12} sm={5} md={4} lg={2}>
                            <Link to="/NuevoProducto">
                                <Button variant="contained" color="primary" startIcon={<AddCircleOutline />} style={{ float: 'right', width:'100%' }}>
                                    Nuevo Producto
                        </Button>
                            </Link>
                        </Grid>
                    </Grid>

                    <hr />
               

                    <CustomDataTable
                        url="api/Product"
                        columns={this.state.columns}
                        ProductoID={this.props.match.params.value}
                        ref={this.dataTableRef}
                        showHeader={true} />

                </div>
                );

    }
}
