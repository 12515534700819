import React, { Component } from 'react';
import { axiosInstance } from '../../utils/axiosInstance';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import Grid from '@material-ui/core/Grid'
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import store from '../../store';
import { Paper } from '@material-ui/core';
import ImageUploader from 'react-images-upload';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import { RedirectTypeSelector } from '../../components/UI/RedirectTypeSelector';



export class GalleryBrandOffersForm extends Component {

    constructor(props) {
        super(props);

        this.state = {

            pageTypes:[],

            galleryBrandOfferID: 0,
            pageTypeRedirectID: '',

            productSubCategoryID: '',
            productCategoryID: '',
            laboratoryID: '',
            productBrandID: '',
            productID: '',

            reference: '',
            title: '',
            subTitle: '',
            enabled: false,
            principal: false,
            defaultImage: '',
            form: new FormData(),

        };;

        this.galleryBrandOfferID = this.props.match.params.value;
        this.isEdit = this.props.match.params.value != undefined;
        this.onDropImage = this.onDropImage.bind(this);

        this.onSubmit = this.onSubmit.bind(this);

    }


    async executeRESTCall(action) {



        const url = '/api/GalleryBrandOffer/';

        if (action === "POST") {
            await axiosInstance.post(url, this.state)
                .then(response => {

                    this.props.history.push('/ofertas-por-marcas');
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: "Operación exitosa"
                    })

                }).catch(error => {
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: error.response.data.message
                    })
                });
        }

        if (action === "PUT") {
            await axiosInstance.put(url, this.state)
                .then(response => {

                    this.props.history.push('/ofertas-por-marcas');
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: "Operación exitosa"
                    })

                }).catch(error => {
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: error.response.data.message
                    })
                });
        }     

      

    }

    async onSubmit(e) {
    
        e.preventDefault();
        await this.addImage();
    }

    onChange = (event, value) => {


        if (event.target.type === "checkbox") {
            this.setState({
                [event.target.name]: event.target.checked,
            })
        }
        else {



            this.setState({
                [event.target.name]: event.target.value
            })
        }
    }

    onDropImage(pictureFiles, pictureDataURLs) {

        let form = this.state.form;
        var element = pictureFiles[0];

        form.append('image', element);

        this.setState({
            form: form,
            image: element === undefined ? '' : element.name,
        });        
    }

    addImage = async (image) => {

        this.setState({
            warningImageText: '',
        });

        var form = this.state.form;
        var validation = true;

        
        form.append('reference', this.state.reference);    
        form.append('enabled', this.state.enabled);
        form.append('pageTypeRedirectID', 2);
        form.append('productBrandID', this.state.productBrandID);
        

        

        var response = null;

        if (this.isEdit) {

            form.append('galleryBrandOfferID', this.state.galleryBrandOfferID);

            await axiosInstance.put('/api/GalleryBrandOffer/Update', form)
                    .then(response => {

                        this.props.history.push('/ofertas-por-marcas');
                        store.dispatch({
                            type: "SNACKBAR_SUCCESS",
                            message: "Operación exitosa"
                        })

                    }).catch(error => {
                        store.dispatch({
                            type: "SNACKBAR_SUCCESS",
                            message: error.response.data.message
                        })
                    });


        }
        else {

            if (this.state.image === '') {
                validation = false;
                this.setState({
                    warningImageText: "Las imagen es requerida"
                });
            }
            else {    
             

                await axiosInstance.post('/api/GalleryBrandOffer/Save', form)
                    .then(response => {

                        this.props.history.push('/ofertas-por-marcas');
                        store.dispatch({
                            type: "SNACKBAR_SUCCESS",
                            message: "Operación exitosa"
                        })

                    }).catch(error => {
                        store.dispatch({
                            type: "SNACKBAR_SUCCESS",
                            message: error.response.data.message
                        })
                    });

            }
        }
        this.setState({
            form: new FormData(),   
        });


      

    }

    async componentDidMount() {

    



        if (this.isEdit) {
            await axiosInstance.get(`api/GalleryBrandOffer/GetByID/${this.galleryBrandOfferID}`).then(
                response => {
                    this.setState({
                        galleryBrandOfferID: response.data.galleryBrandOfferID,
                        reference: response.data.reference,                      
                        enabled: response.data.enabled,                     
                        defaultImage: response.data.defaultImage,       
                        productBrandID: response.data.productBrandID == null ? '' : response.data.productBrandID,                     
                    });
                });
        }
    }

    CreateStyles() {
        return {            
            marginRight: '10px',
            marginBottom: '10px',
            float: 'right'
        }
    }

    HeaderTitle() {
        let title;

        if (this.isEdit) {
            title = 'Editando galería';
        }
        else {
            title ='Crea una nueva imagen de oferta por marca';
        }

        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                        <h4>
                            {title}
                            <small >
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Typography color="inherit">
                                        Home
                                        </Typography>

                                    <Link color="inherit" to="/ofertas-por-marcas" >
                                        <Typography color="textPrimary">Galería</Typography>
                                </Link>

                                </Breadcrumbs>
                            </small>
                        </h4>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <Link to="/ofertas-por-marcas">
                            <Button
                                variant="default"
                                color="default"
                                size="small"
                                startIcon={<CancelIcon />}
                                style={this.CreateStyles()}
                            >
                                Cancelar
                        </Button>
                        </Link>
                    </Grid>
                </Grid>

                <hr />
            </div>
        );

        
    }

    renderPageTypesOptions() {
        return this.state.pageTypes.map((dt, i) => {
            return (
                <MenuItem
                    label="Seleccione un tipo de página"
                    value={dt.id}
                    key={i} name={dt.text}>{dt.text}
                </MenuItem>
            );
        });
    }

    render() {

        var recommendedImageSize = "Tamaño recomendado 700 x 200";
     
        return (

            <div>

                {this.HeaderTitle()}

               
            
                    <ValidatorForm ref="form"
                onSubmit={this.onSubmit}
                onError={errors => console.log(errors)}
                style={{ padding: "10px" }}>               

                        <Grid container spacing={2} >

                            <Grid item xs={12} sm={12} md={6} lg={4}>
                                <Paper style={{ padding: '15px' }} elevation={1}>

                                    <Grid container spacing={2} >
                       
                                        <Grid item sm={12} xs={12}>
                                        <TextValidator id="reference"
                                                style={{ width: "100%" }}
                                            name="reference"
                                                label="Referencia"
                                                variant="outlined"
                                                size="small"
                                                onChange={this.onChange}
                                            value={this.state.reference}
                                                validators={['required', 'maxStringLength:50']}
                                        errorMessages={['El nombre del laboratorio es requerido', 'Máximo 50 carácteres']}
                                        autoFocus 
                                        autoComplete="off"
                                            />
                                    </Grid>

                                                           
                                   
                                  
                                  
                                   

                                        <Grid item sm={12} xs={12}>

                                            <RedirectTypeSelector
                                                pageTypeRedirectID={2}
                                                onChange={this.onChange}
                                                productID={this.state.productID}
                                                productCategoryID={this.state.productCategoryID}
                                                productSubCategoryID={this.state.productSubCategoryID}
                                                laboratoryID={this.state.laboratoryID}
                                                productBrandID={this.state.productBrandID}
                                            />
                                    </Grid>

                                    <Grid item sm={12} xs={12}>
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.enabled} onChange={this.onChange} name="enabled" />}
                                            label="Habilitado"
                                        />
                                    </Grid>

                                     

                                        <Grid item xs={12} md={12}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="medium"
                                                startIcon={<SaveIcon />}
                                                type="submit"
                                                style={this.CreateStyles()}
                                            >
                                                Guardar
                                        </Button>

                                </Grid>
                          
                          
                
                                    </Grid>

                                </Paper>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={4}>
                            <Paper style={{ padding: '15px' }} elevation={1}>

                                <Grid container spacing={2}>

                                    <Grid item sm={12} xs={12}>
                                            <center>
                                                <img src={this.state.defaultImage} style={{ width: '360px' }} />
                                            </center>
                                    </Grid>

                                    <Grid item sm={12} xs={12}>
                                        <ImageUploader
                                            withPreview
                                            withIcon={false}
                                            buttonClassName="primary-color bg-base border-circle add-Button"
                                            buttonText="Seleccione una imágen"
                                            onChange={this.onDropImage}
                                            imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                                            maxFileSize={5242880}
                                            label={recommendedImageSize}
                                            singleImage={true}
                                        />
                                        <FormHelperText style={{ color: '#f44336' }}>{this.state.warningImageText}</FormHelperText>

                                    </Grid>

                                </Grid>
                            </Paper>
                        </Grid>




                        </Grid>

                    </ValidatorForm>
            </div>
        )
    }
}