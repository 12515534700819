import React, { Component } from 'react';
import { axiosInstance } from '../../utils/axiosInstance';

import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import store from '../../store';


export class StockForm extends Component {

    constructor(props) {
        super(props);

        this.state = {


        
            productID: this.props.match.params.value,
            name: '',
            shortDescription:'',
            productCategoryID: '',
            stock: '',

            isEdit: this.props.match.params.value != undefined,

        };

     

        
    }

    CreateStyles() {
        return {
            //marginRight: '10px',
            //marginBottom: '10px',
            float: 'right'
        }
    }

    async executeRESTCall(action) {

        let formData = {
            productID: this.state.productID,       
            stock: this.state.stock,  
        };

        await axiosInstance.put('/api/Product/EditStock', formData)
            .then(response => {

                this.props.history.push('/stock');
                store.dispatch({
                    type: "SNACKBAR_SUCCESS",
                    message: "Operación exitosa"
                })

            }).catch(error => {
                store.dispatch({
                    type: "SNACKBAR_SUCCESS",
                    message: error.response.data.message
                })
            });




    }   

    onSubmit = e => {

        this.executeRESTCall("PUT");
    
        e.preventDefault();
    }

    onChange = (event, value) => {

        if (event.target.type === "checkbox") {
            this.setState({
                [event.target.name]: event.target.checked,
            })
        }
        else {
            if (event.target.name === "name") {
                var str = event.target.value;
                str = str.replace(/\s+/g, '-').toLowerCase();

                this.setState({
                    [event.target.name]: event.target.value,
                    url: str,
                })
            }


            this.setState({
                [event.target.name]: event.target.value
            })
        }
    }      



    handleChange(e, index) {


        let descriptions = [...this.state.descriptions];

        let description = { ...descriptions[index] };

        if (e.target.name == 'description') {
            description.description = e.target.value;
        }

        if (e.target.name == 'language') {
            description.languageID = e.target.value;
        }


        descriptions[index] = description;

        this.setState({ descriptions });

    }

  


   

    async componentDidMount() {


        this.setState({

        });

        if (this.state.isEdit) {

            await axiosInstance.get(`api/Product/GetByID/${this.state.productID}`).then(
                response => {
                    this.setState({
                        productID: response.data.productID,
                        name: response.data.name,
                        shortDescription: response.data.shortDescription,
                        stock: response.data.stock,

                    });
                });         
        }        
    }


  
  

    renderHeaderTitle() {
        let title;

       
        title = 'Editando stock del producto ' + this.state.name ;
 
 

        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <h4>
                            {title}
                            <small >
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Typography color="inherit">
                                        Productos
                                        </Typography>

                                    <Link color="inherit" to="/stock" >
                                        <Typography color="textPrimary">Stock</Typography>
                                    </Link>

                                </Breadcrumbs>
                            </small>
                        </h4>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Link to="/stock">
                            <Button                      
                                color="default"
                                size="small"
                                startIcon={<CancelIcon />}
                                style={this.CreateStyles()}
                            >
                                Cancelar
                        </Button>
                        </Link>
                    </Grid>
                </Grid>

                <hr />
            </div>
        );
    }

    






    







    render() {      

        return (
            <div>
                {this.renderHeaderTitle()}
               
                
                    <ValidatorForm ref="form"
                        onSubmit={this.onSubmit}
                        onError={errors => console.log(errors)}
                    >

                        <Grid container spacing={2} >
                            <Grid item xs={12} sm={12} md={5}>

                                <Paper square style={{ padding: "15px" }}>
                                    <Grid container spacing={2} >
                                        <Grid item sm={12} xs={12}>
                                            <h4>{this.state.name}</h4>
                                        </Grid>

                                        <Grid item sm={12} xs={12}>
                                            <p>{this.state.shortDescription}</p>
                                        </Grid>

                                        <Grid item sm={12} xs={12}>
                                            <TextValidator id="stock"
                                                style={{ width: "150px" }}
                                                name="stock"
                                                label="Stock"
                                                variant="outlined"
                                                size="small"
                                                onChange={this.onChange}
                                                value={this.state.stock}
                                                autoComplete="off"

                                                validators={['required', 'matchRegexp:^[0-9]+$', 'minNumber:1']}
                                                errorMessages={['El stock es requerido', 'Solo números', 'No puede ser 0']}

                                            />
                                        </Grid>


                                        <Grid item sm={12} xs={12}>

                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                startIcon={<SaveIcon />}
                                                type="submit"

                                            >
                                                Guardar
                                        </Button>


                                        </Grid>

                                    </Grid>
                                </Paper>

                            </Grid>

                        </Grid>

                    </ValidatorForm>

           

              
            </div>
        )
    }
}


