import React, { Component } from 'react';
import { axiosInstance } from '../../utils/axiosInstance';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import Grid from '@material-ui/core/Grid'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import store from '../../store';
import { Paper } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { CustomDataTable } from '../../components/UI/CustomDataTable';
import Tooltip from '@material-ui/core/Tooltip';
import { Add } from '@material-ui/icons/';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow'; 
import { Delete } from '@material-ui/icons';

export class ProductGroupForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ID: 0,
            title: '',
            subTitle: '',
            enabled: false,
            selectedProducts:[],
            products: [],
            open: false,
            loading: false,
        };
       // this.dataTableRef = React.createRef();

        this.ID = this.props.match.params.value;
        this.isEdit = this.props.match.params.value != undefined;
    }


    async executeRESTCall(action) {

        const url = '/api/ProductGroupHomeSection/';

        if (action === "POST") {
            await axiosInstance.post(url, this.state)
                .then(response => {

                    this.props.history.push('/productos-en-home');
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: "Operación exitosa"
                    })

                }).catch(error => {
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: error.response.data.message
                    })
                });
        }

        if (action === "PUT") {
            await axiosInstance.put(url, this.state)
                .then(response => {

                    this.props.history.push('/productos-en-home');
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: "Operación exitosa"
                    })

                }).catch(error => {
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: error.response.data.message
                    })
                });
        }     

        

        
    }
   

    onSubmit = e => {

        //console.log("submuit:");


        if (!this.isEdit) {
            this.executeRESTCall("POST");
        }
        else {
            this.executeRESTCall("PUT");
        }
    
        e.preventDefault();
    }


    onChange =(event, value) => {
        if (event.target.type === "checkbox") {
            this.setState({
                [event.target.name]: event.target.checked,
            })
        }
        else {
            this.setState({
                [event.target.name]: event.target.value
            })
        }
    }

    

    handleAddClick = async e => {

        var productId = parseInt(e.currentTarget.dataset.row_id);
        var text = e.currentTarget.dataset.row_text;


        var productToAdd = {
            id: 0,
            productId: productId,
            text: text,
        };

        const found = this.state.selectedProducts.find(element => element.productId === productId);

        if (found === undefined)
        {
            this.setState(state => {
                const selectedProducts = state.selectedProducts.concat(productToAdd);

                return {
                    selectedProducts,
                };
            });
        }


    }

    removeProduct(index) {

        let selectedProducts = [...this.state.selectedProducts];
        selectedProducts.splice(index, 1);
        this.setState({
            selectedProducts: selectedProducts
        });

    }

    async componentDidMount() {

        const response = await axiosInstance.get(`/api/Product/GetProductsForSelect`);
        this.setState({
            products: response.data
        });



        const columns = [
            {
                name: 'Id',
                selector: 'id',
                sortable: true,
                width: '90px',
                hide: 'sm',

            },
            {
                name: 'Producto',
                selector: 'name',
                sortable: true,
            },          
            {
                name: 'Laboratorio',
                selector: 'laboratory',
                sortable: true,
                hide: 'sm',
            },
            {
                name: 'Categoría',
                selector: 'category',
                sortable: true,
                hide: 'md',
                maxwidth: '90px',
            },

            {
                cell: row =>
                    <Tooltip title="Agregar producto">
                        <IconButton
                            aria-label="add"
                            onClick={this.handleAddClick}
                            data-row_id={row.id}
                            data-row_text={row.name}>
                            <Add color="primary" />
                        </IconButton>
                    </Tooltip>
                ,
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
                width: '60px',
            },
          
        ];

        this.setState({
            columns: columns,
        });


        if (this.isEdit) {
            await axiosInstance.get(`api/ProductGroupHomeSection/GetByID/${this.ID}`).then(
                response => {
                    this.setState({
                        ID: response.data.id,
                        title: response.data.title,
                        subTitle: response.data.subTitle,
                        enabled: response.data.enabled,
                        selectedProducts: response.data.selectedProducts,
                    });
                });
        }
    }

    CreateStyles()  {
        return {            
            marginRight: '10px',
            marginBottom: '10px',
            float: 'right'
        }
    }

    HeaderTitle() {
        let title;

        if (this.isEdit) {
            title = 'Editando grupo de productos';
        }
        else {
            title ='Crea un nuevo grupo de productos';
        }

        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                        <h4>
                            {title}
                            <small >
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Typography color="inherit">
                                        Home
                                        </Typography>

                                    <Link color="inherit" to="/productos-en-home/" >
                                        <Typography color="textPrimary">Productos en home</Typography>
                                </Link>

                                </Breadcrumbs>
                            </small>
                        </h4>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <Link to="/productos-en-home/">
                            <Button
                                variant="default"
                                color="default"
                                size="small"
                                startIcon={<CancelIcon />}
                                style={this.CreateStyles()}
                            >
                                Cancelar
                        </Button>
                        </Link>
                    </Grid>
                </Grid>

                <hr />
            </div>
        );

        
    }

    render() {

        var { open, products, loading } = this.state;


        return (

            <div>

                {this.HeaderTitle()}

               
            
                    <ValidatorForm ref="form"
                        onSubmit={this.onSubmit}
                        onError={errors => console.log(errors)}
                        style={{ padding: "10px" }}>               

                        <Grid container spacing={2} >

                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Paper style={{ padding: '15px' }} elevation={1}>

                                    <Grid container spacing={2} >
                       
                                    <Grid item sm={12} xs={12} md={4} lg={4}>
                                        <TextValidator id="title"
                                                style={{ width: "100%" }}
                                            name="title"
                                                label="Título"
                                                variant="outlined"
                                                size="small"
                                                onChange={this.onChange}
                                                value={this.state.title}
                                                validators={['required', 'maxStringLength:50']}
                                                errorMessages={['El título es requerida', 'Máximo 50 carácteres']}
                                                autoFocus 
                                                autoComplete="off"
                                            />
                                        </Grid>

                                    <Grid item sm={12} xs={12} md={4} lg={4}>

                                        <TextValidator id="subTitle"
                                            style={{ width: "100%" }}
                                            name="subTitle"
                                            label="Subtítulo"
                                            variant="outlined"
                                            size="small"
                                            onChange={this.onChange}
                                            value={this.state.subTitle}
                                            validators={['required']}
                                            errorMessages={['El subtítulo es requerido']}
                                            autoComplete="off"
                                        />


                                    </Grid>

                                    <Grid item sm={12} xs={12} md={4} lg={2}>
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.enabled} onChange={this.onChange} name="enabled" />}
                                            label="Habilitado"
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={12} md={4} lg={2}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="medioum"
                                                startIcon={<SaveIcon />}
                                                type="submit"
                                                style={this.CreateStyles()}
                                            >
                                                Guardar
                                        </Button>

                                </Grid>
                          
                          
                
                                </Grid>

                                </Paper>
                            </Grid>
                    </Grid>
                </ValidatorForm>

                <Grid container spacing={2} >
                        <Grid item xs={12} sm={6} md={6} lg={8}>
                            <Paper style={{ padding: '15px' }} elevation={1}>

                                <h4>Buscador de productos</h4>

                                <CustomDataTable
                                    url="api/Product/"
                                    columns={this.state.columns}
                                    handleOpenModal={this.handleOpenModal}
                                    ref={this.dataTableRef}
                                    showHeader={true}
                                />



                            </Paper>
                        </Grid>

           

                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <Paper style={{ padding: '15px' }} elevation={1}>

                                <h4>Productos seleccionados</h4>

                                <Table aria-label="simple table" size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Producto</TableCell>
                                            <TableCell align="center"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>



                                        {


                                            this.state.selectedProducts.map((productGroup, index) => {

                                                return (
                                                    <TableRow key={index}>

                                                        <TableCell>{productGroup.text}</TableCell>
                                                        <TableCell>
                                                            <Tooltip title="Eliminar producto">
                                                            <IconButton style={{padding:'0px'}}
                                                                aria-label="add"                                                               
                                                                onClick={(e) => this.removeProduct(index)}
                                                                >
                                                                <Delete color="secondary" />
                                                                </IconButton>
                                                                </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }


                                    </TableBody>
                                </Table>

                              
                           

                            </Paper>
                        </Grid>
                    </Grid>
            </div>
        )
    }
}