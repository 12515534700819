import React, { Component } from 'react';
import { axiosInstance } from '../../utils/axiosInstance';
import { Link } from 'react-router-dom';


import { Edit, Done, Close } from '@material-ui/icons';



import { CustomDataTable } from '../../components/UI/CustomDataTable';


import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';



const useStyles = makeStyles(theme => ({
    root: {
        padding:'12px',
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(3),
        },
    },
}));





export class Stock extends Component {

    constructor(props) {
        super(props);
        this.state = {
            alertIsOpen: false,
        };

        

        this.dataTableRef = React.createRef();

    
    } 





    async componentDidMount() {

        const columns = [

            {
                name: 'Nombre',
                selector: 'name',
                sortable: true,
            },
            {
                name: 'Detalle',
                selector: 'shortDescription',
                sortable: true,
                hide: 'md',
            },

    

            {
                name: 'Stock',
                selector: 'stock',
                sortable: true,
                maxwidth: '90px',
            },

            {
                name: 'Habilitado',
                selector: 'enabled',
                sortable: true,
                hide: 'md',
                maxwidth: '90px',
                cell: d => d.enabled === true ? <Done style={{ color: 'green' }} /> : <Close style={{ color: 'red' }} />
            },

            {
                name: 'Opciones',
                button: true,
                cell: row =>
                    <Link to={"/editando-stock/" + row.id}>
                        <Tooltip title="Editar stock">
                            <IconButton>
                                <Edit />
                            </IconButton>
                        </Tooltip>
                    </Link>
                ,
                allowOverflow: true,
                width: '80px',


            },
           




        ];



        this.setState({
            columns: columns,                              

        });
    }



    async refreshDataTable() {
        this.setState({ pending: true });

        const response = await axiosInstance.get(this.getDataTableUrl());

        this.setState({
            pending: false,
            data: response.data.data,
            totalRows: response.data.total,
        });
    }

    
  

    render() {
        const { data, totalRows, alertIsOpen } = this.state;

        

            return (
                <div>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={7} md={8} lg={10}>
                            <h4>
                                Stock
                                <small >
                                    <Breadcrumbs aria-label="breadcrumb">
                                        <Typography color="inherit">
                                            Productos
                                        </Typography>
                                        <Typography color="textPrimary">Stock</Typography>
                                    </Breadcrumbs>
                                </small>
                            </h4>
                        </Grid>
                        <Grid item xs={12} sm={5} md={4} lg={2}>
                            
                        </Grid>
                    </Grid>

                    <hr />
               

                    <CustomDataTable
                        url="api/Product"
                        columns={this.state.columns}
                        ProductoID={this.props.match.params.value}
                        ref={this.dataTableRef}
                        showHeader={true} />

                </div>
                );

    }
}
