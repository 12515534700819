import React, { Component } from 'react';
import { axiosInstance } from '../../utils/axiosInstance';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import Grid from '@material-ui/core/Grid'
import { ValidatorForm,  SelectValidator } from 'react-material-ui-form-validator';
import store from '../../store';
import { Paper } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';

import CKEditor from "react-ckeditor-component";



const config = {
    toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],   
}
export class SiteTextForm extends Component {

    constructor(props) {
        super(props);

        this.state = {

            siteTextTypes: [],

            siteTextID:0,
            siteTextTypeID: '',
            text: '',
            secondaryText: '',
        };;

        this.siteTextID = this.props.match.params.value;
        this.isEdit = this.props.match.params.value != undefined;
    }


    async executeRESTCall(action) {
        const url = '/api/SiteText/';

        if(action === "POST") {
            await axiosInstance.post(url, this.state)
                .then(response => {

                    this.props.history.push('/textos');
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: "Operación exitosa"
                    })

                }).catch(error => {
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: error.response.data.message
                    })
                });
        }

        if(action === "PUT") {
            await axiosInstance.put(url, this.state)
                .then(response => {

                    this.props.history.push('/textos');
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: "Operación exitosa"
                    })

                }).catch(error => {
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: error.response.data.message
                    })
            });
    }     


    }

    onChange = (event, value) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    onSubmit = e => {

        if (!this.isEdit) {
            this.executeRESTCall("POST");
        }
        else {
            this.executeRESTCall("PUT");
        }
    
        e.preventDefault();
    }


    onChangeText = (evt) => {
        var newContent = evt.editor.getData();
        this.setState({
            text: newContent
        })
    }

    onChangeSecondaryText = (evt) => {
        var newContent = evt.editor.getData();
        this.setState({
            secondaryText: newContent
        })
    }

    renderOptions() {
        return this.state.siteTextTypes.map((dt, i) => {
            return (
                <MenuItem
                    label="Seleccione un tipo de texto"
                    value={dt.id}
                    key={i} name={dt.text}>{dt.text}
                </MenuItem>
            );
        });
    }

    async componentDidMount() {

        const siteTextTypeResponse = await axiosInstance.get(`/api/SiteText/GetSiteTextTypeForSelect`);               

        this.setState({
            siteTextTypes: siteTextTypeResponse.data,
        });


        if (this.isEdit) {
            await axiosInstance.get(`api/SiteText/GetByID/${this.siteTextID}`).then(
                response => {
                    this.setState({
                        siteTextID: response.data.id,
                        siteTextTypeID: response.data.siteTextTypeID,
                        text: response.data.text,
                        secondaryText: response.data.secondaryText,
                    });
                });
        }
    }

    CreateStyles() {
        return {            
            marginRight: '10px',
            marginBottom: '10px',
            float: 'right'
        }
    }

    HeaderTitle() {
        let title;

        if (this.isEdit) {
            title = 'Editando texto';
        }
        else {
            title ='Crea un nuevo texto';
        }

        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                        <h4>
                            {title}
                            <small >
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Typography color="inherit">
                                        Sitio
                                        </Typography>

                                    <Link color="inherit" to="/Textos" >
                                        <Typography color="textPrimary">Textos</Typography>
                                </Link>

                                </Breadcrumbs>
                            </small>
                        </h4>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <Link to="/Textos">
                            <Button              
                                color="default"
                                size="small"
                                startIcon={<CancelIcon />}
                                style={this.CreateStyles()}
                            >
                                Cancelar
                        </Button>
                        </Link>
                    </Grid>


                
                </Grid>

                <hr />
            </div>
        );

        
    }

    render() {


        return (

            <div>

                {this.HeaderTitle()}

               
            
                    <ValidatorForm ref="form"
                        onSubmit={this.onSubmit}
                        onError={errors => console.log(errors)}
                        style={{ padding: "10px" }}>               

                        <Grid container spacing={2} >

                            <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Paper style={{ padding: '15px' }} elevation={1}>

                                    <Grid container spacing={2} >

                                    <Grid item sm={12} xs={12}>

                                        <div className="padd50">
                                            <SelectValidator
                                                style={{ width: "100%" }}
                                                name="siteTextTypeID"
                                                variant="outlined"
                                                size="small"
                                                label="Tipo de texto"
                                                value={this.state.siteTextTypeID}
                                                validators={['required']}
                                                errorMessages={['El tipo de texto es requerido']}
                                                onChange={this.onChange}>
                                                {this.renderOptions()}

                                            </SelectValidator>
                                        </div>



                                    </Grid>

                                    <Grid item sm={12} xs={12}>
                                        <CKEditor
                                            content={this.state.text}
                                            //config={editorConfiguration}
                                            name="text"
                                            events={{
                                                //"blur": this.onBlur,
                                                //"afterPaste": this.afterPaste,
                                                "change": this.onChangeText
                                            }}
                                            config={{
                                                toolbar: [
                                                    ['Bold', 'Italic', 'Link', 'Line', 'Styles', 'Format', 'Font', 'FontSize', 'TextColor', 'BGColor'],
                                                    ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'],
                                                    ['About'],
                                                    ['Anchor', 'NumberedList', 'Outdent', 'Blockquote', 'JustifyLeft', 'BidiLtr', 'BidiRtl', 'JustifyRight', 'JustifyCenter', 'JustifyBlock', 'CreateDiv', 'Indent', 'BulletedList']
                                                ]
                                               
                                            }}
                                        />
                                        </Grid>

                                      
                                  
                                        <Grid item sm={12} xs={12}>
                  
                                        <CKEditor
                                            content={this.state.secondaryText}
                                            //config={editorConfiguration}
                                            name="secondaryText"
                                            events={{
                                                //"blur": this.onBlur,
                                                //"afterPaste": this.afterPaste,
                                                "change": this.onChangeSecondaryText
                                            }}
                                            config={{
                                                toolbar: [
                                                    ['Bold', 'Italic', 'Link', 'Line', 'Styles', 'Format', 'Font', 'FontSize', 'TextColor', 'BGColor'],
                                                    ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'],
                                                    ['About'],
                                                    ['Anchor', 'NumberedList', 'Outdent', 'Blockquote', 'JustifyLeft', 'BidiLtr', 'BidiRtl', 'JustifyRight', 'JustifyCenter', 'JustifyBlock', 'CreateDiv', 'Indent', 'BulletedList']
                                                ]

                                            }}
                                        />


                                    </Grid>

                               

                              

                                        <Grid item xs={12} md={12}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="medium"
                                                startIcon={<SaveIcon />}
                                                type="submit"
                                                style={this.CreateStyles()}
                                            >
                                                Guardar
                                        </Button>

                                </Grid>
                          
                          
                
                                </Grid>

                                </Paper>
                            </Grid>
                        </Grid>

                    </ValidatorForm>
            </div>
        )
    }
}