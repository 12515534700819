import React, { Component } from 'react';
//import authService from './api-authorization/AuthorizeService'

import { Add, Edit, AddCircleOutline } from '@material-ui/icons/';
import { CustomDataTable } from './UI/CustomDataTable';

import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { Delete } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import store from '../store';
import swal from 'sweetalert'



export class PaymentPlatform extends Component {

    constructor(props) {
        super(props);
        this.state = {
            columns:[],            
        };

        this.dataTableRef = React.createRef();
    } 
    
    async componentDidMount() {

        const columns = [
            {
                name: 'Id',
                selector: 'id',
                sortable: true,
                width: '90px',
                hide: 'sm',

            },
            {
                name: 'Nombre',
                selector: 'name',
                sortable: true,
            },

            {
                name: 'Habilitada',
                selector: 'enabled',
                sortable: true,
            },
          
            {
                name: 'Opciones',
                button: true,
                cell: row =>
                    <Link to={"/EditandoPlataforma de pago/" + row.id}>
                        <Tooltip title="Editar">
                            <IconButton aria-label="Editar">
                                <Edit />
                            </IconButton>
                        </Tooltip>
                    </Link>
                ,
                allowOverflow: true,
                width: '60px',
            },       
            {
                cell: row =>
                    <Tooltip title="Eliminar">
                        <IconButton
                            aria-label="delete"
                            onClick={this.handleDeleteClick}
                            data-row_id={row.id}
                            data-row_text={row.PaymentPlatformName}>
                            <Delete color="secondary" />
                        </IconButton>
                    </Tooltip>
                ,
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
                width: '60px',
            },
        ];

        this.setState({
            columns: columns,                              
        });

      
    }

    

    handleDeleteClick = async e =>{

 

        var id = e.currentTarget.dataset.row_id;
        var text = e.currentTarget.dataset.row_text;

        const deleteRow = await swal({
            title: "¿Confirma la operación?",
            text: text,
            icon: "warning",
            dangerMode: true,
            buttons: true,

        });

        if (deleteRow) {
            const response = await fetch('api/PaymentPlatform/delete/' + id, {
                method: "DELETE",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            });

            if (response.status == 400) {
                const jsonResponse = await response.json();

                swal({
                    icon: 'error',
                    title: 'Oops, algo sucedió...',
                    text: jsonResponse.message
                })
            }

            if (response.status == 200) {
                swal("Se eliminó el registro", {
                    icon: "success",
                });
                this.dataTableRef.current.refreshDataTable();
            }
        }
    }

    onChange = (event, value) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    onSubmit = e => {
        console.log("submiting");
        e.preventDefault();
    }

    render() {
      
        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                        <h4>
                            Plataformas de pago
                            <small >
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Typography color="inherit">
                                        Configuración
                                </Typography>
                                    <Typography color="textPrimary">Plataformas de pago</Typography>
                                </Breadcrumbs>
                            </small>
                        </h4>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <Link to="/NuevaPlataforma de pago">
                            <Button variant="contained" color="primary" startIcon={<AddCircleOutline />} style={{ float: 'right' }}>
                                Nueva Plataforma de pago
                        </Button>
                        </Link>
                    </Grid>
                </Grid>

                <hr/>

                <CustomDataTable
                    url="api/PaymentPlatform"
                    columns={this.state.columns}
                    handleOpenModal={this.handleOpenModal}
                    ref={this.dataTableRef}
                    showHeader={true}
                />
            </div>
        );
    }
}
