import React, { Component } from 'react';
import { axiosInstance } from '../../utils/axiosInstance';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import Grid from '@material-ui/core/Grid'
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import store from '../../store';
import { Paper } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';


export class ProductSegmentForm extends Component {

    constructor(props) {
        super(props);

        this.state = {

            productSubCategories: [],
            productSubCategoryID: 0,
            
            description: '',
            url: '',
        };;

        this.ProductSegmentID = this.props.match.params.value;
        this.isEdit = this.props.match.params.value != undefined;
    }


    async executeRESTCall(action) {
   
        const url = '/api/ProductSegment/';

        if (action === "POST") {
            await axiosInstance.post(url, this.state)
                .then(response => {

                    this.props.history.push('/segmentos');
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: "Operación exitosa"
                    })

                }).catch(error => {
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: error.response.data.message
                    })
                });
        }

        if (action === "PUT") {
            await axiosInstance.put(url, this.state)
                .then(response => {

                    this.props.history.push('/segmentos');
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: "Operación exitosa"
                    })

                }).catch(error => {
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: error.response.data.message
                    })
                });
        }     

     
    }
   

    onSubmit = e => {

        if (!this.isEdit) {
            this.executeRESTCall("POST");
        }
        else {
            this.executeRESTCall("PUT");
        }
    
        e.preventDefault();
    }


    onChange = (event, value) => {

        if (event.target.name === "description") {
            var str = event.target.value;
            str = str.replace(/\s+/g, '-').toLowerCase();

            this.setState({
                [event.target.name]: event.target.value,
                url: str,
            })
        }


        this.setState({
            [event.target.name]: event.target.value
        })
    }


    renderOptions() {
        return this.state.productSubCategories.map((dt, i) => {
            return (
                <MenuItem
                    label="Seleccione una subcategoría"
                    value={dt.id}
                    key={i} name={dt.text}>{dt.text}
                </MenuItem>
            );
        });
    }

    async componentDidMount() {

        const productSubCategoryResponse = await axiosInstance.get(`/api/ProductSubCategory/GetProductSubCategoryForSelect`);               

        this.setState({
            productSubCategories: productSubCategoryResponse.data,
        });


        if (this.isEdit) {
            await axiosInstance.get(`api/ProductSegment/GetByID/${this.ProductSegmentID}`).then(
                response => {
                    this.setState({
                        productSegmentID: response.data.id,
                        description: response.data.description,
                        productSubCategoryID: response.data.productSubCategoryID,
                        url: response.data.url,
                    });
                });
        }
    }

    CreateStyles() {
        return {            
            marginRight: '10px',
            marginBottom: '10px',
            float: 'right'
        }
    }

    HeaderTitle() {
        let title;

        if (this.isEdit) {
            title = 'Editando segmento';
        }
        else {
            title ='Crea un nuevo segmento';
        }

        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                        <h4>
                            {title}
                            <small >
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Typography color="inherit">
                                        Productos
                                        </Typography>

                                    <Link color="inherit" to="/segmentos" >
                                        <Typography color="textPrimary">Segmentos de productos</Typography>
                                </Link>

                                </Breadcrumbs>
                            </small>
                        </h4>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <Link to="/segmentos">
                            <Button
                                variant="default"
                                color="default"
                                size="small"
                                startIcon={<CancelIcon />}
                                style={this.CreateStyles()}
                            >
                                Cancelar
                        </Button>
                        </Link>
                    </Grid>


                
                </Grid>

                <hr />
            </div>
        );

        
    }

    render() {


        return (

            <div>

                {this.HeaderTitle()}

               
            
                    <ValidatorForm ref="form"
                        onSubmit={this.onSubmit}
                        onError={errors => console.log(errors)}
                        style={{ padding: "10px" }}>               

                        <Grid container spacing={2} >

                            <Grid item xs={12} sm={12} md={6} lg={4}>
                            <Paper style={{ padding: '15px' }} elevation={1}>

                                    <Grid container spacing={2} >
                       
                                        <Grid item sm={12} xs={12}>
                                            <TextValidator id="description"
                                                    style={{ width: "100%" }}
                                                    name="description"
                                                    label="Nombre"
                                                    variant="outlined"
                                                    size="small"
                                                    onChange={this.onChange}
                                                    value={this.state.description}
                                                    validators={['required', 'maxStringLength:50']}
                                            errorMessages={['El nombre de la categoría es requerido', 'Máximo 50 carácteres']}
                                            autoFocus 
                                            autoComplete="off"
                                            />
                                        </Grid>
                                  
                                        <Grid item sm={12} xs={12}>

                                            <TextValidator id="url"
                                                style={{
                                                    width: "100%"                                        
                                                }}
                                                name="url"
                                                label="Url"
                                                variant="outlined"
                                                size="small"
                                                onChange={this.onChange}
                                                value={this.state.url}
                                                    validators={[
                                                        'required'
                                                        , 'matchRegexp:^[a-zA-Z\-0-9]+$'
                                                    ]}
                                                errorMessages={['El url es requerido', 'Carácteres no válidos (& / ? . , _)']}
                                                autoComplete="off"
                                            />


                                    </Grid>

                                    <Grid item sm={12} xs={12}>

                                        <div className="padd50">
                                            <SelectValidator
                                                style={{ width: "100%" }}
                                                name="productSubCategoryID"
                                                variant="outlined"
                                                size="small"
                                                label="Subcategoría"
                                                value={this.state.productSubCategoryID}
                                                validators={['required']}
                                                errorMessages={['La subcategoría es requerida']}
                                                onChange={this.onChange}>
                                                {this.renderOptions()}

                                            </SelectValidator>
                                        </div>



                                    </Grid>

                              

                                        <Grid item xs={12} md={12}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="normal"
                                                startIcon={<SaveIcon />}
                                                type="submit"
                                                style={this.CreateStyles()}
                                            >
                                                Guardar
                                        </Button>

                                </Grid>
                          
                          
                
                                </Grid>

                                </Paper>
                            </Grid>
                        </Grid>

                    </ValidatorForm>
            </div>
        )
    }
}