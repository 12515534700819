/**
 * top products
 */
/* eslint-disable */
import React, { Fragment } from 'react';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

// helpers

//component
import Hidden from '@material-ui/core/Hidden';

class TopProductCard extends React.Component {






    render() {
        const { data } = this.props;

        let discountBadge;
        let priceWithOutDiscount;



        discountBadge =
            <div className="iron-offer-badge">
                <span>

                    <Typography style={{
                        color: '#fff'
                        , fontSize: '1.6rem'
                        , fontWeight: '800'
                        , textAlign: 'center'
                    }}>
                        {this.props.discountLabelFirstText}
                    </Typography>

                    {
                        this.props.discountLabelSecondText != null ?
                            <Typography style={{ color: '#fff', fontSize: '0.65rem' }}>
                                {this.props.discountLabelSecondText}
                            </Typography>
                            : null
                    }


                </span>

            </div>

            ;

        priceWithOutDiscount =
            <Typography className={"MuiTypography--subheading"} variant={"caption"} style={{ fontSize: '15px', color: '#444', fontWeight: '0' }}>
                <span className="discount">
                    $1500
                   </span>
            </Typography>;




        return (
            <Fragment>
                {data !== null ?

                    <Card className="iron-product-item p-20 iron-shadow position-relative hover-box-shadow" style={{ padding: '20px' }}>
                        <div style={{ display:'block'}}>

                            <CardMedia
                                height="50"
                                component="img"
                                image={"https://www.farmanobel.com/images/products/578/dc772864-7840-49cc-988d-9d2a09dac5eb_17723__65805_std.jpg"}
                                alt="das"
                                style={{height:'200px'}}
                            />

                        </div>

                        <CardContent className="iron-product-content pt-20 p-0 " style={{ paddingTop: '20px' }}>
                            <br/>
                            <h5 className="text-truncate mb-sm-15 mb-10" style={{ marginBottom: '10px' }}>
                                Nombre del producto
                        </h5>

                            <p style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                height: '2.7em',
                                display: '-webkit-box',

                            }}
                            >
                                Descripción del producto
                        </p>


                            <div className="price-wrap d-block mb-15" style={{ borderTop: '2px solid #f7f5f7', borderBottom: '2px solid #f7f5f7', marginTop: '15px',marginBottom:'15px' }}>

                                <br />


                                {
                                    this.props.minimumAmount > 1 ?
                                        <p>
                                            {priceWithOutDiscount} Llevando {this.props.minimumAmount} pagas <span> $ 1000</span> c/u
                                   </p>
                                        :
                                        <p>
                                            {priceWithOutDiscount}    <span> $   {1000} </span>
                                        </p>
                                }



                            </div>


                            <div className="iron-btn-grp2 mb-sm-0 mb-5" style={{marginBottom:'5px'}}>

                                <div>

                                    <Button
                                        className="btn-wrap button"
                                    >
                                        Agregar al carrito
                                    </Button>

                                </div>

                            </div>



                        </CardContent>
                        {discountBadge}


                    </Card>
                    :
                    null
                }
            </Fragment>
        )
    }

}

export default (TopProductCard);

