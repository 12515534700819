import React, { useContext } from "react";
import { Link } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Collapse from "@material-ui/core/Collapse";

import ProductIcon from '@material-ui/icons/LocalGroceryStoreOutlined';
import LocalShipping from '@material-ui/icons/LocalShipping';
import FolderIcon from '@material-ui/icons/FolderOpenOutlined';
import LocalOffer from '@material-ui/icons/LocalOfferOutlined';
import Web from '@material-ui/icons/Web';
import Home from '@material-ui/icons/Home';
import Settings from '@material-ui/icons/Settings';

import "./Sidebar.css";

import { LoginMenu } from '../auth/LoginMenu';



function onClick(e, item) {
    //window.alert(JSON.stringify(item, null, 2));
    //console.log(item);
    // use history.push('/some/path') here
    //const [history] = React.useHistory();
    //history.push(item.to);
}




const items = [
    //{ name: "home", label: "Home", Icon: HomeIcon, to: "/" },

    //"divider",
    {
        name: "pedidos",
        label: "Pedidos",
        Icon: FolderIcon,
        to: "",
        items: [
            { name: "admOrder", label: "Pedidos", to: "/Pedidos", onClick },

        ]
    },

    //"divider",
    {
        name: "Productos",
        label: "Productos",
        Icon: ProductIcon,
        to: "",
        items: [
            { name: "admProduct", label: "Productos", to: "/Productos", onClick },
            { name: "admProductCategory", label: "Categorías de productos", to: "/CategoriasDeProductos", onClick },
            { name: "admSubProductCategory", label: "Subcategorias de productos", to: "/SubcategoriasDeProductos", onClick },
            { name: "admSewgment", label: "Segmentos de productos", to: "/segmentos", onClick },
            { name: "admLaboratory", label: "Laboratorios", to: "/Laboratorios", onClick },
            { name: "admBrands", label: "Marcas", to: "/Marcas", onClick },
            { name: "admStock", label: "Stock", to: "/stock", onClick },
            { name: "admImages", label: "Imagenes", to: "/images", onClick },
            { name: "admPrice", label: "Precios", to: "/precios", onClick },
        ]
    },

    //"divider",
    {
        name: "promociones",
        label: "Promociones",
        Icon: LocalOffer,
        to: "",
        items: [
            { name: "admDiscounts", label: "Descuentos", to: "/Descuentos", onClick },
            { name: "admOffer", label: "Ofertas", to: "/Ofertas", onClick },
            { name: "admFlyer", label: "Flyer", to: "/flyers", onClick },            

        ]
    },




    {
        name: "Textos",
        label: "Farmanobel",
        Icon: Web,
        to: "",
        items: [
            { name: "admClient", label: "Clientes", to: "/Cliente", onClick },
            //{ name: "admRedesSociales", label: "Redes sociales", to: "/RedesSociales", onClick },
            { name: "admTextos", label: "Textos", to: "/Textos", onClick },
            { name: "admReservaConReceta", label: "Reserva con recetas", to: "/reservas-con-receta", onClick },
            { name: "admSucursales", label: "Sucursales", to: "/sucursales", onClick },
            { name: "admPrepegas", label: "Prepagas - Obras sociales", to: "/prepagas", onClick },



        ]
    },

    {
        name: "Home",
        label: "Home",
        Icon: Home,
        to: "",
        items: [
            { name: "admSlider", label: "Slider", to: "/Slider", onClick },
            { name: "admGallery", label: "Galería", to: "/Galeria", onClick },
            { name: "admGalleryBrandsOffers", label: "Ofertar por marcas", to: "/ofertas-por-marcas", onClick },
            { name: "admProductsGroup", label: "Productos", to: "/productos-en-home", onClick },



        ]
    },

    //"divider",
    //{
    //    name: "configuracion",
    //    label: "Configuración",
    //    Icon: SettingsIcon,
    //    to: "",
    //    items: [
    //        //{ name: "admPaymentPlatform", label: "Plataformas de pago", to: "/PlataformasDePago", onClick },
    //        { name: "admUser", label: "Usuarios", to: "/User", onClick },



    //    ]
    //},



    {
        name: "Correo",
        label: "Correo",
        Icon: LocalShipping,
        to: "",
        items: [
            { name: "admDeliveryZones", label: "Zonas de entrega propia", to: "/zonas-de-entrega-propia", onClick },
            { name: "admMailCompany", label: "Empresas de correo", to: "/empresas-de-correo", onClick },



        ]
    },


    {
        name: "Textos",
        label: "Configuración",
        Icon: Settings,
        to: "",
        items: [
            { name: "admUsuarios", label: "Usuarios", to: "/Usuarios", onClick },
            { name: "admPerfiles", label: "Perfiles", to: "/Perfiles", onClick },
        ]
    },




    //"divider",
    //{
    //    name: "settings",
    //    label: "Settings",
    //    Icon: SettingsIcon,
    //    items: [
    //        { name: "profile", label: "Profile" },
    //        { name: "insurance", label: "Insurance", onClick },
    //        "divider",
    //        {
    //            name: "notifications",
    //            label: "Notifications",
    //            Icon: NotificationsIcon,
    //            items: [
    //                { name: "email", label: "Email", onClick },
    //                {
    //                    name: "desktop",
    //                    label: "Desktop",
    //                    Icon: DesktopWindowsIcon,
    //                    items: [
    //                        { name: "schedule", label: "Schedule" },
    //                        { name: "frequency", label: "Frequency" }
    //                    ]
    //                },
    //                { name: "sms", label: "SMS" }
    //            ]
    //        }
    //    ]
    //}
];



function SidebarItem({ depthStep = 10, depth = 0, expanded, item, ...rest }) {




    const [collapsed, setCollapsed] = React.useState(true);
    const { label, items, Icon, onClick: onClickProp, to } = item;

    function toggleCollapse() {
        setCollapsed(prevValue => !prevValue);
    }

    function onClick(e) {
        if (Array.isArray(items)) {
            toggleCollapse();
        }
        if (onClickProp) {
            onClickProp(e, item);
        }
    }

    let expandIcon;

    if (Array.isArray(items) && items.length) {
        expandIcon = !collapsed ? (
            <ExpandLessIcon className={"sidebar-item-expand-arrow" + " sidebar-item-expand-arrow-expanded"} />
        ) : (
                <ExpandMoreIcon className="sidebar-item-expand-arrow" />
            );
    }

    const renderLink = (
        <Link to={to} style={{ color: "#44413b", textDecoration: "none" }}>
            <ListItem
                className="sidebar-item"
                onClick={onClick}
                button
                dense
                {...rest}
            >
                <div style={{ paddingLeft: depth * depthStep }} className="sidebar-item-content">

                    {Icon && <Icon className="sidebar-item-icon" fontSize="small" />}
                    <div className="sidebar-item-text"> {label}</div>
                </div>
                {expandIcon}
            </ListItem>
        </Link>
    );


    const renderTitle = (
        <ListItem
            className="sidebar-item"
            onClick={onClick}
            button
            dense
            {...rest}
        >
            <div style={{ paddingLeft: depth * depthStep }} className="sidebar-item-content">
                {Icon && <Icon className="sidebar-item-icon" fontSize="small" />}
                <div className="sidebar-item-text"> {label}</div>
            </div>
            {expandIcon}
        </ListItem>
    );



    return (

        

        <div>
            {Icon && renderTitle}
            {to && renderLink}




            <Collapse in={!collapsed} timeout="auto" unmountOnExit>
                {Array.isArray(items) ? (
                    <List disablePadding dense>
                        {items.map((subItem, index) => (

                            <React.Fragment key={`${subItem.name}${index}`}>

                                {subItem === "divider" ? (
                                    <Divider style={{ margin: "12px 0" }} />
                                ) : (



                                        <SidebarItem
                                            depth={depth + 1}
                                            depthStep={depthStep}
                                            item={subItem}
                                        />

                                    )}
                            </React.Fragment>
                        ))}
                    </List>
                ) : null}
            </Collapse>

        </div>
    );
}





function Sidebar({ depthStep, depth, expanded }) {
    

    return (
        <div className="sidebar">

            <LoginMenu />

            <List disablePadding dense>

                {items.map((sidebarItem, index) => (
                    <React.Fragment key={`${sidebarItem.name}${index}`}>
                        {sidebarItem === "divider" ? (
                            <Divider style={{ margin: "6px 0" }} />
                        ) : (
                                <SidebarItem
                                    depthStep={depthStep}
                                    depth={depth}
                                    expanded={expanded}
                                    item={sidebarItem}
                                />
                            )}
                    </React.Fragment>
                ))}
            </List>
        </div>
    );
}

export default Sidebar;
