import React, { Component } from 'react';
import { axiosInstance } from '../../utils/axiosInstance';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import SaveIcon from '@material-ui/icons/Save';
import Edit from '@material-ui/icons/Edit'
import CancelIcon from '@material-ui/icons/Cancel';
import Grid from '@material-ui/core/Grid'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import store from '../../store';
import { Paper } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';


export class EmailCompanyForm extends Component {


    constructor(props) {
        super(props);

        this.state = {
            id: 0,
            name: '',
            enabled: null,
            deliveryDayFrom: '',
            deliveryDayUntil: '',

            estimatedDeliveryTime: '',

            minimumWeight: 0,
            maximumWeight: 0,
            rateID:0,
            nationalRate: 0,
            regionalRate: 0,

            rates: [],
            dialogOpen: false,
        };

        this.deliveryZoneId = this.props.match.params.value;
        this.isEdit = this.props.match.params.value != undefined;

        this.onSubmitRate = this.onSubmitRate.bind(this);

        
    }


    async executeRESTCall(action) {
        const url = '/api/MailCompany/';

        if (action === "POST") {
            await axiosInstance.post(url, this.state)
                .then(response => {

                    this.props.history.push('/empresas-de-correo');
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: "Operación exitosa"
                    })

                }).catch(error => {
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: error.response.data.message
                    })
                });
        }

        if (action === "PUT") {
            await axiosInstance.put(url, this.state)
                .then(response => {

                    this.props.history.push('/empresas-de-correo');
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: "Operación exitosa"
                    })

                }).catch(error => {
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: error.response.data.message
                    })
                });
        }     
      
    }
   

    onSubmit = e => {

        if (!this.isEdit) {
            this.executeRESTCall("POST");
        }
        else {
            this.executeRESTCall("PUT");
        }
    
        e.preventDefault();
    }

    async onSubmitRate() {
        const url = '/api/MailCompany/EditRate';

        let data = {
            mailCompanyID: this.state.id,
            rateID: this.state.rateID,
            nationalRate: this.state.nationalRate,
            regionalRate: this.state.regionalRate,
		}

        await axiosInstance.put(url, data)
            .then(response => {

                store.dispatch({
                    type: "SNACKBAR_SUCCESS",
                    message: "Operación exitosa"
                })

                this.setState({
                    dialogOpen: false,
				})


                this.getMailCompany();

            }).catch(error => {
                store.dispatch({
                    type: "SNACKBAR_SUCCESS",
                    message: error.response.data.message
                })
            });
    }


    onChange =(event, value) => {
        if (event.target.type === "checkbox") {
            this.setState({
                [event.target.name]: event.target.checked,
            })
        }
        else {

            this.setState({
                [event.target.name]: event.target.value
            })
        }
    }

    handleClickDialogOpen(rate) {

       

        this.setState({      

            rateID: rate.id,
            nationalRate: rate.nationalRate,
            regionalRate: rate.regionalRate,
            minimumWeight: rate.minimumWeight,
            maximumWeight: rate.maximumWeight,
            dialogOpen: true,
        })
    };

    handleDialogClose = (event, newValue) => {

        this.setState({
            dialogOpen: false,
        })
    };

    async componentDidMount() {

        if (this.isEdit) {
            await this.getMailCompany();
        }
    }

    async getMailCompany() {
        await axiosInstance.get(`api/MailCompany/GetByID/${this.deliveryZoneId}`).then(
            response => {
                this.setState({
                    id: response.data.id,
                    name: response.data.name,
                    enabled: response.data.enabled,
                    estimatedDeliveryTime: response.data.estimatedDeliveryTime,
                    deliveryDayFrom: response.data.deliveryDayFrom,
                    deliveryDayUntil: response.data.deliveryDayUntil,

                    rates: response.data.rates,
                });
            });
	}

    CreateStyles() {
        return {            
            marginRight: '10px',
            marginBottom: '10px',
            float: 'right'
        }
    }

    HeaderTitle() {
        let title;

        if (this.isEdit) {
            title = 'Editando empresa de correo';
        }
        else {
            title ='Crea un nueva empresa de correo';
        }

        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                        <h4>
                            {title}
                            <small >
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Typography color="inherit">
                                        Correo
                                        </Typography>

                                    <Link color="inherit" to="/empresas-de-correo" >
                                        <Typography color="textPrimary">Empresas de correo</Typography>
                                </Link>

                                </Breadcrumbs>
                            </small>
                        </h4>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <Link to="/empresas-de-correo">
                            <Button
                                variant="default"
                                color="default"
                                size="small"
                                startIcon={<CancelIcon />}
                                style={this.CreateStyles()}
                            >
                                Cancelar
                        </Button>
                        </Link>
                    </Grid>
                </Grid>

                <hr />
            </div>
        );

        
    }

    render() {

        var dialogOpen = this.state.dialogOpen;
        return (

            <div>

                {this.HeaderTitle()}



                <Dialog
                    open={dialogOpen}
                    onClose={this.handleDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth="md"
                >
                    <DialogTitle id="alert-dialog-title">{"Tarifa"}</DialogTitle>

                    <ValidatorForm ref="form"
                        onSubmit={this.onSubmitRate}
                        onError={errors => console.log(errors)}
                        style={{ padding: "10px" }}>
                        <DialogContent>

               

                                <Grid container spacing={2} >

                                    <Grid item xs={12}>

                                            <Grid container spacing={2} >

                                            <Grid item sm={12} xs={12}>
                                                <b>Peso mínimo: </b>{this.state.minimumWeight}
                                            </Grid>

                                            <Grid item sm={12} xs={12}>
                                                <b>Peso máximo: </b> {this.state.maximumWeight}
                                            </Grid>

                                            <Grid item sm={12} xs={12}>
                                                <TextValidator id="name"
                                                    style={{ width: "100%" }}
                                                    name="regionalRate"
                                                    label="Tarifa regional"
                                                    variant="outlined"
                                                    size="small"
                                                    onChange={this.onChange}
                                                    value={this.state.regionalRate}
                                                validators={['required', 'minNumber:0','matchRegexp:^[0-9]+$']}
                                                errorMessages={['*', 'No puede ser 0', 'Solo números']}
                                                    autoFocus
                                                    autoComplete="off"
                                                />
                                            </Grid>

                                                <Grid item sm={12} xs={12}>
                                                    <TextValidator id="name"
                                                        style={{ width: "100%" }}
                                                        name="nationalRate"
                                                        label="Tarifa nacional"
                                                        variant="outlined"
                                                        size="small"
                                                        onChange={this.onChange}
                                                        value={this.state.nationalRate}
                                                        validators={['required', 'maxStringLength:50']}
                                                        errorMessages={['El nombre es requerida', 'Máximo 50 carácteres']}
                                                        autoFocus
                                                        autoComplete="off"
                                                    />
                                            </Grid>

                                       

                                            </Grid>
                                    </Grid>
                                </Grid>

                        </DialogContent>

                        <DialogActions>

                            <Button type="submit" color="primary" autoFocus>
                                <SaveIcon /> Guardar
                        </Button>

                            <Button onClick={this.handleDialogClose} color="default">
                                Cancelar
                        </Button>


                        </DialogActions>
                    </ValidatorForm>
                </Dialog>
            
                    <ValidatorForm ref="form"
                onSubmit={this.onSubmit}
                onError={errors => console.log(errors)}
                style={{ padding: "10px" }}>               

                        <Grid container spacing={2} >

                            <Grid item xs={12} sm={6} md={6} lg={4}>
                                <Paper style={{ padding: '15px' }} elevation={1}>

                                    <Grid container spacing={2} >
                       
                                        <Grid item sm={12} xs={12}>
                                            <TextValidator id="name"
                                                style={{ width: "100%" }}
                                                name="name"
                                                label="Nombre"
                                                variant="outlined"
                                                size="small"
                                                onChange={this.onChange}
                                                value={this.state.name}
                                                validators={['required', 'maxStringLength:50']}
                                                errorMessages={['El nombre es requerida', 'Máximo 50 carácteres']}
                                                autoFocus 
                                                autoComplete="off"
                                            />
                                        </Grid>
                                  
                                        <Grid item sm={12} xs={12}>

                                        <TextValidator id="estimatedDeliveryTime"
                                            style={{ width: "100%" }}
                                            name="estimatedDeliveryTime"
                                            label="Tiempo de entrega estimado"
                                            variant="outlined"
                                            size="small"
                                            onChange={this.onChange}
                                            value={this.state.estimatedDeliveryTime}
                                            validators={['required']}
                                            errorMessages={['El tiempo de entrega estimado es requerido']}
                                            autoComplete="off"
                                        />


                                    </Grid>


                                    <Grid item sm={12} xs={12}>

                                        <TextValidator id="deliveryDayFrom"
                                            style={{ width: "100%" }}
                                            name="deliveryDayFrom"
                                            label="Desde"
                                            variant="outlined"
                                            size="small"
                                            onChange={this.onChange}
                                            value={this.state.deliveryDayFrom}
                                            validators={['matchRegexp:^[0-9]+$']}
                                            errorMessages={['Solo números']}
                                            autoComplete="off"
                                        />


                                    </Grid>

                                    <Grid item sm={12} xs={12}>

                                        <TextValidator id="deliveryDayUntil"
                                            style={{ width: "100%" }}
                                            name="deliveryDayUntil"
                                            label="Hasta"
                                            variant="outlined"
                                            size="small"
                                            onChange={this.onChange}
                                            value={this.state.deliveryDayUntil}
                                            validators={['matchRegexp:^[0-9]+$']}
                                            errorMessages={['Solo números']}
                                            autoComplete="off"
                                        />


                                    </Grid>

                                  
                                  
                                    <Grid item sm={12} xs={12}>
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.enabled} onChange={this.onChange} name="enabled" />}
                                            label="Habilitado"
                                        />
                                    </Grid>
                              

                                        <Grid item xs={12} md={12}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="medioum"
                                                startIcon={<SaveIcon />}
                                                type="submit"
                                                style={this.CreateStyles()}
                                            >
                                                Guardar
                                        </Button>

                                </Grid>
                          
                          
                
                                </Grid>

                                </Paper>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={8}>
                                <Paper style={{ padding: '15px' }} elevation={1}>

                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="right">Peso mínimo</TableCell>
                                            <TableCell align="right">Peso máximo</TableCell>
                                            <TableCell align="right">Tarifa regional</TableCell>
                                            <TableCell align="right">Tarifa nacional</TableCell>
                                            <TableCell align="right">Editar</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>



                                        {


                                            this.state.rates.map((rate, index) => {

                                                return (
                                                    <TableRow key={index}>
                                                 
                                                        <TableCell align="right">{rate.minimumWeight}</TableCell>
                                                        <TableCell align="right">{rate.maximumWeight}</TableCell>
                                                        <TableCell align="right">{rate.regionalRate}</TableCell>
                                                        <TableCell align="right">{rate.nationalRate}</TableCell>
                                                        <TableCell align="right">
                                                            <Tooltip title="Editar tarifa">
                                                                 <IconButton onClick={() => this.handleClickDialogOpen(rate)}>
                                                                   <Edit/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }

                                     
                                    </TableBody>
                                </Table>


                             


                                </Paper>
                            </Grid>

                        </Grid>

                    </ValidatorForm>
            </div>
        )
    }
}