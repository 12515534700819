import React, { Component } from 'react';
import { axiosInstance } from '../../utils/axiosInstance';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import Grid from '@material-ui/core/Grid'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import store from '../../store';
import { Paper } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TopProductCard from '../../components/TopProductCard';

export class StoreForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            StoreID: 0,
            name: '',
            address: '',
            latitude: '',
            longitude: '',
            phoneNumber: '',
            retirePointEnabled: null,
        };

        this.StoreID = this.props.match.params.value;
        this.isEdit = this.props.match.params.value != undefined;
    }


    async executeRESTCall(action) {
        const url = '/api/Store/';


        if (action === "POST") {
            await axiosInstance.post(url, this.state)
                .then(response => {

                    this.props.history.push('/Sucursales');
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: "Operación exitosa"
                    })

                }).catch(error => {
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: error.response.data.message
                    })
                });
        }

        if (action === "PUT") {
            await axiosInstance.put(url, this.state)
                .then(response => {

                    this.props.history.push('/Sucursales');
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: "Operación exitosa"
                    })

                }).catch(error => {
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: error.response.data.message
                    })
                });
        }     

    
    }
   

    onSubmit = e => {

        if (!this.isEdit) {
            this.executeRESTCall("POST");
        }
        else {
            this.executeRESTCall("PUT");
        }
    
        e.preventDefault();
    }


    onChange = (event, value) => {

        if (event.target.type === "checkbox") {
            this.setState({
                [event.target.name]: event.target.checked,
            })
        }
        else {
            this.setState({
                [event.target.name]: event.target.value
            })
        }
    }

    async componentDidMount() {

        if (this.isEdit) {
            await axiosInstance.get(`api/Store/GetByID/${this.StoreID}`).then(
                response => {
                    this.setState({
                        StoreID: response.data.storeID,
                        name: response.data.name,
                        address: response.data.address,
                        latitude: response.data.latitude,
                        longitude: response.data.longitude,
                        phoneNumber: response.data.phoneNumber,
                        retirePointEnabled: response.data.retirePointEnabled,
                    });
                });
        }
    }

    CreateStyles() {
        return {            
            marginRight: '10px',
            marginBottom: '10px',
            float: 'right'
        }
    }

    HeaderTitle() {
        let title;

        if (this.isEdit) {
            title = 'Editando sucursal';
        }
        else {
            title ='Crea una nueva sucursal';
        }

        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                        <h4>
                            {title}
                            <small >
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Typography color="inherit">
                                        Farmanobel
                                        </Typography>

                                    <Link color="inherit" to="/Sucursales" >
                                        <Typography color="textPrimary">Sucursales</Typography>
                                </Link>

                                </Breadcrumbs>
                            </small>
                        </h4>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <Link to="/sucursales">
                            <Button
                                variant="default"
                                color="default"
                                size="small"
                                startIcon={<CancelIcon />}
                                style={this.CreateStyles()}
                            >
                                Cancelar
                        </Button>
                        </Link>
                    </Grid>
                </Grid>

                <hr />
            </div>
        );

        
    }

    render() {


        return (

            <div>

                {this.HeaderTitle()}

               
            
                    <ValidatorForm ref="form"
                onSubmit={this.onSubmit}
                onError={errors => console.log(errors)}
                style={{ padding: "10px" }}>               

                        <Grid container spacing={2} >

                            <Grid item xs={12} sm={6} md={6} lg={4}>
                            <Paper style={{ padding: '15px' }} elevation={1}>

                                    <Grid container spacing={2} >
                       
                                        <Grid item sm={12} xs={12}>
                                            <TextValidator id="name"
                                                style={{ width: "100%" }}
                                                name="name"
                                                label="Nombre"
                                                variant="outlined"
                                                size="small"
                                                onChange={this.onChange}
                                                value={this.state.name}
                                                validators={['required', 'maxStringLength:50']}
                                                errorMessages={['El nombre es requerido', 'Máximo 50 carácteres']}
                                                autoFocus 
                                                autoComplete="off"
                                            />
                                        </Grid>
                                  
                                        <Grid item sm={12} xs={12}>

                                        <TextValidator id="address"
                                            style={{ width: "100%" }}
                                            name="address"
                                            label="Dirección"
                                            variant="outlined"
                                            size="small"
                                            onChange={this.onChange}
                                            value={this.state.address}
                                            validators={['required', 'maxStringLength:50']}
                                            errorMessages={['La dirección es requerida', 'Máximo 50 carácteres']}
                                            autoComplete="off"
                                        />


                                    </Grid>

                                    <Grid item sm={12} xs={12}>

                                        <TextValidator id="latitude"
                                            style={{ width: "100%" }}
                                            name="latitude"
                                            label="Latitud"
                                            variant="outlined"
                                            size="small"
                                            onChange={this.onChange}
                                            value={this.state.latitude}
                                            validators={['maxStringLength:20']}
                                            errorMessages={['Máximo 20 carácteres']}
                                            autoComplete="off"
                                        />


                                    </Grid>

                                    <Grid item sm={12} xs={12}>

                                        <TextValidator id="longitude"
                                            style={{ width: "100%" }}
                                            name="longitude"
                                            label="Longitud"
                                            variant="outlined"
                                            size="small"
                                            onChange={this.onChange}
                                            value={this.state.longitude}
                                            validators={['maxStringLength:20']}
                                            errorMessages={['Máximo 20 carácteres']}
                                            autoComplete="off"
                                        />


                                    </Grid>

                                    <Grid item sm={12} xs={12}>

                                        <TextValidator id="phoneNumber"
                                            style={{ width: "100%" }}
                                            name="phoneNumber"
                                            label="Número de teléfono"
                                            variant="outlined"
                                            size="small"
                                            onChange={this.onChange}
                                            value={this.state.phoneNumber}
                                            validators={['maxStringLength:30']}
                                            errorMessages={['Máximo 30 carácteres']}
                                            autoComplete="off"
                                        />


                                    </Grid>


                                    <Grid item sm={12} xs={12}>
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.retirePointEnabled} onChange={this.onChange} name="retirePointEnabled" />}
                                            label="es punto de retiro "
                                        />
                                    </Grid>

                              

                                        <Grid item xs={12} md={12}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="medioum"
                                                startIcon={<SaveIcon />}
                                                type="submit"
                                                style={this.CreateStyles()}
                                            >
                                                Guardar
                                        </Button>

                                </Grid>
                          
                          
                
                                </Grid>

                                </Paper>
                        </Grid>

                   
                    </Grid>

                    </ValidatorForm>
            </div>
        )
    }
}