import React, { Component } from "react";
import { baseURL, axiosInstance } from '../utils/axiosInstance';

import store from '../store';
import { CustomImageUploader } from './customImageUploader';
import Button from '@material-ui/core/Button';
import PhotoCamera from '@material-ui/icons/PhotoCamera';

import { styled } from '@material-ui/core/styles';


import Grid from '@material-ui/core/Grid';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import Slider from "react-slick";


const Input = styled('input')({
    display: 'none',
  });

export class CustomImageGallery extends Component {

    constructor(props) {
        super(props);
        this.state = {
      
            action: this.props.action,
            deleteAction: this.props.deleteAction,
            updateOrderAction: this.props.updateOrderAction,
            cantidadLimite: this.props.cantidadLimite,
            dialogOpen: false,

            sliderSettings: {
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                initialSlide: 0,
             },
            
        };  

 
        this.updateDocumentCallBackRemove = this.updateDocumentCallBackRemove.bind(this)
        this.updateDocumentCallBack = this.updateDocumentCallBack.bind(this)
        this.handleClickOpen = this.handleClickOpen.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }

    updateDocumentCallBack(imagen){
       this.props.updateDocumentCallBack(imagen);     
    }
  
    updateDocumentCallBackRemove(imagen){       
        this.props.updateDocumentCallBackRemove(imagen);        
    }

    handleClickOpen = (name) => {
        console.log(name);

        this.setState(prevState => ({
            sliderSettings: {                 
                ...prevState.sliderSettings,  
                initialSlide: name       
            }
        }))


        this.setState({
            dialogOpen: true,
        })
    };
    
    handleClose = () => {
    this.setState({
        dialogOpen: false,
    })
    };

    async setSelectedImage(imagen){   

        this.setState({
            imagen: imagen,         
            imagenURL: URL.createObjectURL(imagen)
            
        })

        let form = new FormData();
        form.append('image', imagen);
        form.append('idTipoDocumento', 0);
        
        await axiosInstance.post('/api/Multimedia/' + this.state.action, form)
        .then(response => {
            if(response.status == "200"){           
   
                store.dispatch({
                    type: "SNACKBAR_SUCCESS",
                    message: "Operación exitosa"
                })

                this.setState({
                    id: response.data,
                })

            

                this.updateDocumentCallBack(response.data);
            }

        }).catch(error => {
            if (error.response) {   
                store.dispatch({
                    type: "SNACKBAR_SUCCESS",
                    message: error.response.statusText,
                    snackBarType: "error"
                })
            } 
        });
    }

    render() {
    
        return (
            <div>

                    <div className='paper-header'>
                    <Grid container
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={3}
                        >
                            <Grid item>
                                <h3 className="mt-0">
                                {  this.props.title } <small style={{color:'#666', fontSize:'0.75rem'}}> Hasta {this.props.cantidadLimite} imágenes</small>
                            </h3>                       
                            </Grid>
                            <Grid item>

                            { this.props.readOnly !== true && (this.props.imagenes.length < this.state.cantidadLimite) ?

                                <label htmlFor={"icon-button-file-"+ this.props.name}>
                                    <Input 
                                        accept="image/*" 
                                        id={"icon-button-file-"+ this.props.name}
                                        type="file" 
                                        onChange={(event) => this.setSelectedImage(event.target.files[0])}                                    
                                    />                                  

                                    <Button variant="outlined" startIcon={<PhotoCamera />}         component="span">
                                        Tomar / adjuntar imagen 
                                    </Button>
                                </label>
                                :
                                null
                                }
     

                            </Grid>
                        </Grid>
                       
              



                </div>
                <br />
                <br />
                <div className="p-10 mt-10">

                <Grid 
                    container 
                    spacing={1}   
                    alignItems="center">                
                
                    {
                        this.props.imagenes.map((idImagen, i) =>    
                        <Grid item lg={3} md={3} sm={4} xs={12} key={i}>     
                            <CustomImageUploader 
                                    key={i}
                                    updateDocumentCallBackRemove={this.updateDocumentCallBackRemove}
                                    action="SaveProspectoImage"
                                    deleteAction={this.state.deleteAction}
                                    updateOrderAction={this.state.updateOrderAction}
                                    name={i}
                                    idImagen={idImagen}
                                    handleClickOpen={this.handleClickOpen}
                                    readOnly={this.props.readOnly}
                                    order={i+1}
                            />     
                        </Grid>
                    )}
                </Grid>
                </div>

                <Dialog
                    open={this.state.dialogOpen}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullScreen={false}
                    fullWidth={true}
                    maxWidth={"md"}
                >
                    <DialogContent 
                    style={{paddingBottom:'20px'}}>

                        <Slider 
                            {...this.state.sliderSettings}
                            style={{width:'99%'
                            , marginBottom:'20px'
                        }}
                        >
                        { this.props.imagenes.map((imagen, index) => 
                            <center>
                                <img
                                style={{ maxWidth: "100%", maxHeight: "calc(75vh - 64px)" }}
                                src={`${baseURL + "/api/Multimedia/GetImage/"+ imagen }`}       
                                alt={imagen}
                                />
                            </center>
                        ) }                        
                        </Slider>                                    
                    </DialogContent>               
                </Dialog>
            </div>
        )
    }
}