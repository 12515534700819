import React, { Component } from 'react';
import { axiosInstance } from '../../utils/axiosInstance';

import { Visibility, Edit, Save, Payment } from '@material-ui/icons/';
import { CustomDataTableExpanded } from '../../components/UI/CustomDataTableExpanded';




import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import Grid from '@material-ui/core/Grid';
import store from '../../store';
import Done from '@material-ui/icons/Done';
import GetApp from '@material-ui/icons/GetApp';
import CancelIcon from '@material-ui/icons/Cancel';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';


export class Order extends Component {

    constructor(props) {
        super(props);
        this.state = {
            columns: [],            
            tabValue: '0',
            dialogOpen: false,


            selectedOrder: null,
            ordersStates: [],
            orderStateID: '',

            dialogOpenMP: false,

        };

        this.refStoreTable = React.createRef();
        this.refFarmanobelDeliveryTable = React.createRef();
        this.refCorreoArgentinoTable = React.createRef();
        this.refOcaTable = React.createRef();
    } 
    
    async componentDidMount() {

        const ordersStatesResponse = await axiosInstance.get('/api/Order/GetOrdersStatesForSelect');

        const columns = [
            {
                name: 'Id',
                selector: 'id',
                sortable: true,
                width: '90px',
                hide: 'sm',

            },

            {
                name: 'Fecha',
                selector: 'date',
                sortable: true,
            },
            {
                name: 'Cliente',
                selector: 'clientName',
                sortable: true,
            },

  

            {
                name: 'Farmacia',
                selector: 'store',
                sortable: true,
            },

            {
                name: 'Retira',
                selector: 'nameOfWhoWithdraws',
                sortable: true,
            },

            {
                name: 'Estado',
                selector: 'state',
                sortable: true,
            },

            {
                name: 'Pago',
                selector: 'paymentStatus',
                sortable: true,
                hidden: 'sm',
                maxwidth: '90px',
                cell: d => d.paymentStatus === 'approved' ? <div><Done style={{ color: 'green' }} /> <p>{d.paymentStatus}</p></div> : <div><CancelIcon color="secondary" /><p>{d.paymentStatus}</p></div>
            },

            {
                name: 'Total',
                selector: 'total',
                sortable: true,
            },


            {
                name: '',
                button: true,
                cell: row =>
                    <Tooltip title="Mercado pago Status">
                        <IconButton aria-label="habitaciones" onClick={() => this.handleClickDialogOpenMP(row)}>
                            <Payment />
                        </IconButton>
                    </Tooltip>
                ,
                allowOverflow: true,
                width: '50px',


            },

            {
                name: '',
                button: true,
                cell: row =>
                    <Tooltip title="Editar estado">
                        <IconButton aria-label="habitaciones" onClick={() => this.handleClickDialogOpen(row)}>
                            <Edit />
                        </IconButton>
                    </Tooltip>
                ,
                allowOverflow: true,
                width: '50px',


            },

            {
                name: 'Opciones',
                button: true,
                cell: row =>
                    <Link to={"/VerPedido/" + row.id}>
                        <Tooltip title="Ver pedido">
                            <IconButton aria-label="Editar">
                                <Visibility />
                            </IconButton>
                        </Tooltip>
                    </Link>
                ,
                allowOverflow: true,
                width: '90px',
            },       
           
        ];

        const columnsMail = [
            {
                name: 'Id',
                selector: 'id',
                sortable: true,
                width: '90px',
                hide: 'sm',

            },

            {
                name: 'Fecha',
                selector: 'date',
                sortable: true,
                width: '150px',

            },
            {
                name: 'Cliente',
                selector: 'clientName',
                sortable: true,
            },



        
            {
                name: 'Código postal',
                selector: 'postalCode',
                sortable: true,
                width: '125px',

            },

            {
                name: 'Entrega estimada',
                selector: 'estimatedDeliveryDate',
                sortable: true,
            },

            {
                name: 'Estado',
                selector: 'state',
                sortable: true,
            },

            {
                name: 'Pago',
                selector: 'paymentStatus',
                sortable: true,
                hidden: 'sm',
                maxwidth: '90px',
                cell: d => d.paymentStatus === 'approved' ? <div><Done style={{ color: 'green' }} /> <p>{d.paymentStatus}</p></div> : <div><CancelIcon color="secondary" /><p>{d.paymentStatus}</p></div>
            },

            {
                name: 'Total',
                selector: 'total',
                sortable: true,
                width: '125px',

            },

            {
                name: '',
                button: true,
                cell: row =>
                    <Tooltip title="Mercado pago Status">
                        <IconButton aria-label="habitaciones" onClick={() => this.handleClickDialogOpenMP(row)}>
                            <Payment />
                        </IconButton>
                    </Tooltip>
                ,
                allowOverflow: true,
                width: '50px',


            },

            {
                name: '',
                button: true,
                cell: row =>
                    <Tooltip title="Editar estado">
                        <IconButton aria-label="habitaciones" onClick={() => this.handleClickDialogOpen(row)}>
                            <Edit />
                        </IconButton>
                    </Tooltip>
                ,
                allowOverflow: true,
                width: '50px',


            },

            {
                name: 'Opciones',
                button: true,
                cell: row =>
                    <Link to={"/VerPedido/" + row.id}>
                        <Tooltip title="Ver pedido">
                            <IconButton aria-label="Editar">
                                <Visibility />
                            </IconButton>
                        </Tooltip>
                    </Link>
                ,
                allowOverflow: true,
                width: '90px',
            },

        ];

        this.setState({
            columns: columns,                              
            columnsMail: columnsMail,    
            ordersStates: ordersStatesResponse.data,
        });

      
    }

    renderOrdersStatesOptions() {
        return this.state.ordersStates.map((dt, i) => {
            return (


                <MenuItem
                    label="Seleccione un estado"
                    value={dt.id}
                    key={i} name={dt.text}>{dt.text}
                </MenuItem>

            );
        });
    }

    handleTabChange = (event, newValue) => {

        this.setState({
            tabValue: newValue,
        })
    };
  
    handleClickDialogOpen (selectedRow){

        console.log("selectedRow: " , selectedRow);

        this.setState({
            selectedOrder: selectedRow, 
            orderStateID: selectedRow.stateID,
            dialogOpen: true,
        })
    };

    handleDialogClose = (event, newValue) => {

        this.setState({
            dialogOpen: false,
        })
    };

    handleClickDialogOpenMP(selectedRow) {


        this.setState({
            selectedOrder: selectedRow,
            orderStateID: selectedRow.stateID,
            dialogOpenMP: true,
        })
    };

    handleDialogCloseMP = (event, newValue) => {

        this.setState({
            dialogOpenMP: false,
        })
    };

    onChange = (event, value) => {

        this.setState({
            [event.target.name]: event.target.value
        })
    }



    async executeRESTCall() {

        let formData = {
            orderID: this.state.selectedOrder.id,
            orderStateID: this.state.orderStateID,
        };

        await axiosInstance.put('/api/Order/UpdateOrderState', formData)
            .then(response => {

                this.setState({
                    dialogOpen: false,
                });



                store.dispatch({
                    type: "SNACKBAR_SUCCESS",
                    message: "Operación exitosa"
                })

                switch (this.state.tabValue) {
                    case "0":
                        this.refStoreTable.current.refreshDataTable();
                        break;
                    case "1":
                        this.refFarmanobelDeliveryTable.current.refreshDataTable();
                        break;
                    case "2":
                        this.refCorreoArgentinoTable.current.refreshDataTable();
                        break;
                    case "3":
                        this.refOcaTable.current.refreshDataTable();
                        break;
                }

                store.dispatch({
                    type: "SNACKBAR_SUCCESS",
                    message: "Operación exitosa"
                })

            }).catch(error => {
                store.dispatch({
                    type: "SNACKBAR_SUCCESS",
                    message: error.response.data.message
                })
            });

      




    }   

    onSubmit = e => {
  
        this.executeRESTCall();

        e.preventDefault();
    }


    onSubmitMP = e => {

        let formData = {
            orderID: this.state.selectedOrder.id,
            orderStateID: this.state.orderStateID,
        };

        axiosInstance.put('/api/Order/UpdateOrderMercadoPagoState', formData)
            .then(response => {

                this.setState({
                    dialogOpenMP: false,
                });



                store.dispatch({
                    type: "SNACKBAR_SUCCESS",
                    message: "Operación exitosa"
                })

                switch (this.state.tabValue) {
                    case "0":
                        this.refStoreTable.current.refreshDataTable();
                        break;
                    case "1":
                        this.refFarmanobelDeliveryTable.current.refreshDataTable();
                        break;
                    case "2":
                        this.refCorreoArgentinoTable.current.refreshDataTable();
                        break;
                    case "3":
                        this.refOcaTable.current.refreshDataTable();
                        break;
                }

                store.dispatch({
                    type: "SNACKBAR_SUCCESS",
                    message: "Operación exitosa"
                })

            }).catch(error => {
                store.dispatch({
                    type: "SNACKBAR_SUCCESS",
                    message: error.response.data.message
                })
            });




        e.preventDefault();
    }



    render() {

        var { dialogOpen, dialogOpenMP } = this.state;

        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={7} md={8} lg={10}>

                        <h4>
                            Pedidos
                            <small >
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Typography color="inherit">
                                        Ecommerce
                                </Typography>
                                    <Typography color="textPrimary">Pedidos</Typography>
                                </Breadcrumbs>
                            </small>
                        </h4>
                    </Grid>
                    <Grid item xs={12} sm={5} md={4} lg={2}>

                    
                    </Grid>
                </Grid>

                <hr />

                <Dialog
                    open={dialogOpen}
                    onClose={this.handleDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth="md"
                >
                    <DialogTitle id="alert-dialog-title">{"Estado del pedido"}</DialogTitle>
                    <ValidatorForm ref="form"
                        onSubmit={this.onSubmit}
                        onError={errors => console.log(errors)}
                    >
                    <DialogContent>
               
                            <p>
                                Pedido: # {this.state.selectedOrder !== null ? this.state.selectedOrder.id : null}
                                </p>

                            <SelectValidator
                                style={{ width: "100%" }}
                                name="orderStateID"
                                variant="outlined"
                                size="small"
                                label="Estado"
                                value={this.state.orderStateID}
                                validators={['required']}
                                errorMessages={['El estado es requerido']}
                                onChange={this.onChange}>
                                {this.renderOrdersStatesOptions()}

                            </SelectValidator>
                    </DialogContent>

                    <DialogActions>
                 
                            <Button type="submit" color="primary" autoFocus
                                variant="contained"

                            >
                            <Save /> Guardar
                        </Button>

                        <Button onClick={this.handleDialogClose} color="default">
                            Cancelar
                        </Button>


                    </DialogActions>
                        </ValidatorForm>
                </Dialog>


                <Dialog
                    open={dialogOpenMP}
                    onClose={this.handleDialogCloseMP}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth="md"
                >
                    <DialogTitle id="alert-dialog-title">{"Actualizar mercado pago status"}</DialogTitle>
                    <ValidatorForm ref="form"
                        onSubmit={this.onSubmitMP}
                        onError={errors => console.log(errors)}
                    >
                        <DialogContent>

                            <p>
                                Pedido: # {this.state.selectedOrder !== null ? this.state.selectedOrder.id : null}
                            </p>

                        </DialogContent>

                        <DialogActions>

                            <Button type="submit" color="primary" autoFocus
                                variant="contained"

                            >
                                <Save /> Actualizar
                        </Button>

                            <Button onClick={this.handleDialogCloseMP} color="default">
                                Cancelar
                        </Button>


                        </DialogActions>
                    </ValidatorForm>
                </Dialog>

                <Tabs
                    value={this.state.tabValue}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={this.handleTabChange}
                    aria-label="disabled tabs example"
                    scrollButtons="on"
                >
                    <Tab label="Retiro por farmacia" value="0" />
                    <Tab label="Envío propio" value="1" />
                    <Tab label="Correo argentino" value="2" />
             
                    </Tabs>

                {this.state.tabValue == 0 ?

                    <div>

                        <Link to="/api/Order/ExportStoreOrders" target="_blank" download>
                            <Button
                                variant="contained"
                                size="small"
                                startIcon={<GetApp />}
                                type="button"
                                style={{ backgroundColor: '#217346', color:'#FFF', margin:'10px 0px 10px 0px' }}
                            >
                                Generar archivo excel
                            </Button>
                        </Link>

                    <CustomDataTableExpanded
                        url="api/Order/GetStoreOrders"
                        columns={this.state.columns}
                        handleOpenModal={this.handleOpenModal}
                            ref={this.refStoreTable}
                        showHeader={true}
                    />
                        </div>
                    :

                    null
                }



                {this.state.tabValue == 1 ?

                    <div>

                        <Link to="/api/Order/ExportFarmanobelDeliveryOrders" target="_blank" download>
                            <Button
                                variant="contained"
                                size="small"
                                startIcon={<GetApp />}
                                type="button"
                                style={{ backgroundColor: '#217346', color: '#FFF', margin: '10px 0px 10px 0px' }}
                            >
                                Generar archivo excel
                            </Button>
                        </Link>

                        <CustomDataTableExpanded
                            url="api/Order/GetFarmanobelDeliveryOrders"
                            columns={this.state.columnsMail}
                            handleOpenModal={this.handleOpenModal}
                            ref={this.refFarmanobelDeliveryTable}
                            showHeader={true}
                        />
                    </div>
                    :

                    null
                }


                {this.state.tabValue == 2 ?
                    <div>

                        <Link to="/api/Order/ExportCorreoArgentinoOrders" target="_blank" download>
                            <Button
                                variant="contained"
                                size="small"
                                startIcon={<GetApp />}
                                type="button"
                                style={{ backgroundColor: '#217346', color: '#FFF', margin: '10px 0px 10px 0px' }}
                            >
                                Generar archivo excel
                            </Button>
                        </Link>

                        <CustomDataTableExpanded
                            url="api/Order/GetCorreoArgentinoDeliveryOrders"
                            columns={this.state.columnsMail}
                            handleOpenModal={this.handleOpenModal}
                            ref={this.refCorreoArgentinoTable}
                            showHeader={true}
                        />
                    </div>
                    :

                    null
                }


            </div>
        );
    }
}
