import React, { Component } from "react";
import { baseURL, axiosInstance } from '../utils/axiosInstance';
import store from '../store';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';

import Delete from '@material-ui/icons/Delete';
import Autorenew from '@material-ui/icons/Autorenew';
import RemoveRedEye from '@material-ui/icons/RemoveRedEye';
import { Tooltip } from "@material-ui/core";

export class CustomImageUploader extends Component {
    constructor(props) {
        super(props);
        this.state = {        
            idTipoDocumento: this.props.idTipoDocumento !== undefined ? this.props.idTipoDocumento : 0,
            action: this.props.action,
            deleteAction: this.props.deleteAction,
            updateOrderAction: this.props.updateOrderAction,
            imagen: null,
            imagenURL: null, 
            imageHash: Date.now(),
            order: this.props.order,
        };  

        this.deleteImagen = this.deleteImagen.bind(this);
        this.rotateImage = this.rotateImage.bind(this);
        this.handleClickOpen = this.handleClickOpen.bind(this);     
        this.onChange = this.onChange.bind(this);
        this.onBlurOrder = this.onBlurOrder.bind(this);

    }

    async deleteImagen(){       
      
        await axiosInstance.delete('/api/Multimedia/' + this.state.deleteAction + '/' + this.props.idImagen)
        .then(response => {
            if(response.status == "200"){           
   
                store.dispatch({
                    type: "SNACKBAR_SUCCESS",
                    message: "Operación exitosa"
                })

                this.props.updateDocumentCallBackRemove(this.props.idImagen);
            }
        }).catch(error => {
            if (error.response) {   
                store.dispatch({
                    type: "SNACKBAR_SUCCESS",
                    message: error.response.statusText,
                    snackBarType: "error"
                })
            } 
        });
    }

    handleClickOpen(){
        this.props.handleClickOpen(this.props.name);
    }

    async onBlurOrder(e) {
        console.log(e.target.value);

        var data = {
            order: this.state.order
		}

        await axiosInstance.put('/api/Multimedia/' + this.state.updateOrderAction + '/' + this.props.idImagen, data)
            .then(response => {
                if (response.status == "200") {

                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: "Operación exitosa"
                    })

                    //this.props.updateDocumentCallBackRemove(this.props.idImagen);
                }
            }).catch(error => {
                if (error.response) {
                    store.dispatch({
                        type: "SNACKBAR_SUCCESS",
                        message: error.response.statusText,
                        snackBarType: "error"
                    })
                }
            });

    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value

		})

	}

    async rotateImage(){
        await axiosInstance.put('/api/Multimedia/RotateProspectoImage/' + this.props.idImagen)
        .then(response => {
            if(response.status == "200"){           
   
                store.dispatch({
                    type: "SNACKBAR_SUCCESS",
                    message: "Operación exitosa"
                })

                this.setState({
                    imageHash: Date.now()
                })

            }

        }).catch(error => {
            if (error.response) {   
                store.dispatch({
                    type: "SNACKBAR_SUCCESS",
                    message: error.response.statusText,
                    snackBarType: "error"
                })
            } 
        });
    }
   
    render() {
        return (
        
                <Card style={{minHeight:'70px'}}>
                    <div>               
                        { 
                            this.props.idImagen !== 0 ?
                                <img
                                width="100%"
                                src={`${baseURL + "/api/Multimedia/GetImage/"+ this.props.idImagen + "?" + this.state.imageHash }`}                        
                                alt={"imagen-" + this.props.idImagen}                                        
                            />
                        : 
                        null
                        }
                    
                </div>

                    <div style={{padding:'10px'}}>
                    <TextField variant="outlined" label="Orden" fullWidth name="order" value={this.state.order} onBlur={this.onBlurOrder} onChange={this.onChange}/>
                    </div>
                    <CardActions disableSpacing  style={{paddingTop:'0px'}}>

                    { this.state.id == 0 ?
                          null
                        : 
                        <div>

                    { this.props.readOnly !== true ?
                       <Tooltip title="Rotar hacia la derecha">
                            <IconButton 
                            aria-label="rotar"
                            size="large"
                            color="primary" 
                            onClick={this.rotateImage}
                            >
                                <Autorenew />
                            </IconButton>  
                        </Tooltip>
                        :
                        null
                        }

                        <Tooltip title="Ver imagen">
                            <IconButton 
                                aria-label="Ver imagen"
                                size="large"
                                color="primary" 
                                onClick={this.handleClickOpen}
                            >
                                <RemoveRedEye />
                            </IconButton> 
                        </Tooltip>

                        { this.props.readOnly !== true ?

                        <Tooltip title="Eliminar imagen">
                            <IconButton            
                                aria-label="Eliminar" onClick={this.deleteImagen}
                                color="error"
                                size="large"
                            >
                                <Delete />
                            </IconButton>
                        </Tooltip>
                        :
                        null
                        }
                        </div>
                        }                     
                    </CardActions>                    
                </Card>                
        )
    }
}