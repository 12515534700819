import React, { Component } from 'react';
import { axiosInstance } from '../../utils/axiosInstance';
import { AddCircleOutline, Edit, Delete, Done, Close } from '@material-ui/icons';


import { CustomDataTable } from '../../components/UI/CustomDataTable';

import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import swal from 'sweetalert'



export class Flyer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            columns:[],            
        };

        this.dataTableRef = React.createRef();
    } 


    async componentDidMount() {

        const columns = [
            {
                name: 'Id',
                selector: 'id',
                sortable: true,
                width: '90px',
                hide: 'sm',

            },
            {
                name: 'Nombre',
                selector: 'name',
                sortable: true,
            },
            {
                name: 'Habilitado',
                selector: 'habilit',
                sortable: true,
                cell: d => d.habilit === true ? <Done style={{ color: 'green' }} /> : <Close style={{ color: 'red' }} />
            },

            {
                name: 'Habilitado',
                selector: 'habilit',
                sortable: true,
                cell: d => d.habilit === true ?

                    <Button
                        variant="outlined"
                        onClick={() => this.handleDisableClick(d)}
                    >
                        Deshabilitar
                    </Button>
                    :

                    <Button
                        variant="outlined"
                        onClick={() => this.handleEnableClick(d)}
                    >
                        Habilitar
                    </Button>
            },
          

            {
                name: 'Opciones',
                button: true,
                cell: row =>
                    <Link to={"/editando-flyer/" + row.id}>
                        <Tooltip title="Editar">
                            <IconButton aria-label="Editar">
                                <Edit />
                            </IconButton>
                        </Tooltip>
                    </Link>
                ,
                allowOverflow: true,
                width: '60px',
            },       
            {
                cell: row =>
                    <Tooltip title="Eliminar">
                        <IconButton
                            aria-label="delete"
                            onClick={this.handleDeleteClick}
                            data-row_id={row.id}
                            data-row_text={row.name}>
                            <Delete color="secondary" />
                        </IconButton>
                    </Tooltip>
                ,
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
                width: '60px',
            },
        ];

        this.setState({
            columns: columns,                              
        });

      
    }

    

    handleDeleteClick = async e =>{

 

        var id = e.currentTarget.dataset.row_id;
        var text = e.currentTarget.dataset.row_text;

        const deleteRow = await swal({
            title: "¿Confirma la operación?",
            text: text,
            icon: "warning",
            dangerMode: true,
            buttons: true,

        });

        if (deleteRow) {

            await axiosInstance.delete('api/Flyer/delete/' + id)
                .then(response => {

                    swal("Se eliminó el registro", {
                        icon: "success",
                    });
                    this.dataTableRef.current.refreshDataTable();

                }).catch(error => {

                    swal({
                        icon: 'error',
                        title: 'Oops, algo sucedió...',
                        text: error.response.data.message
                    })

                });


         
        }
    }


    handleEnableClick = async (row) => {

        var id = row.id;

        const deleteRow = await swal({
            title: "¿Confirma la operación?",
            text: "Se crearan las ofertas del flyer " + row.name + " ",
            icon: "warning",
            dangerMode: true,
            buttons: true,

        });

        if (deleteRow) {

            await axiosInstance.put('api/Flyer/Habilitar/' + id)
                .then(response => {

                    swal("Ofertas creadas", {
                        icon: "success",
                    });
                    this.dataTableRef.current.refreshDataTable();

                }).catch(error => {
                    console.log(error.response);
                    swal({
                        icon: 'error',
                        title: 'Oops, algo sucedió...',
                        text: error.response.data
                    })

                });



        }
    }

    handleDisableClick = async (row) => {


        var id = row.id;

        const deleteRow = await swal({
            title: "¿Confirma la operación?",
            text: "Deshabilitando " + row.name,
            icon: "warning",
            dangerMode: true,
            buttons: true,

        });

        if (deleteRow) {

            await axiosInstance.put('api/Flyer/DesHabilitar/' + id)
                .then(response => {

                    swal("Se deshabilitó el flyer", {
                        icon: "success",
                    });
                    this.dataTableRef.current.refreshDataTable();

                }).catch(error => {
                    console.log(error);
                    swal({
                        icon: 'error',
                        title: 'Oops, algo sucedió...',
                        text: error.response
                    })

                });



        }


    }

    onChange = (event, value) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    onSubmit = e => {

        e.preventDefault();
    }

    render() {


      
        return (



            <div>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                        <h4>
                            Flyers
                            <small >
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Typography color="inherit">
                                        Promociones
                                </Typography>
                                    <Typography color="textPrimary">Flyers</Typography>
                                </Breadcrumbs>
                            </small>
                        </h4>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <Link to="/nuevo-flyer">
                            <Button variant="contained" color="primary" startIcon={<AddCircleOutline />} style={{ float: 'right' }}>
                                Nuevo flyer
                        </Button>
                        </Link>
                    </Grid>
                </Grid>

                <hr/>

                <CustomDataTable
                    url="api/Flyer"
                    columns={this.state.columns}
                    handleOpenModal={this.handleOpenModal}
                    ref={this.dataTableRef}
                    showHeader={true}
                />
            </div>
        );
    }
}
