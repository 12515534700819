import React, { Component } from 'react';
import { axiosInstance } from '../../utils/axiosInstance';


import MenuItem from '@material-ui/core/MenuItem';
import { SelectValidator } from 'react-material-ui-form-validator';
import Grid from '@material-ui/core/Grid'


export class RedirectTypeSelector extends Component {
    constructor(props) {
        super(props);     

        this.state = {
            products: [],            
            productCategories: [],
            productSubcategories: [],
            productLaboratories: [],
            productBrands: [],
        }
    }

    async componentDidMount() {
        await this.getProducts();
        await this.getProductCategory();
        await this.getProductSubCategory();
        await this.getProductBrand();
        await this.getProductLaboratory();
    }

    async getProducts() {
        const productResponse = await axiosInstance.get(`/api/select/GetProductsForSelect`);       

        this.setState({
            products: productResponse.data,
        });
    }

    async getProductCategory() {
        const productCategoryResponse = await axiosInstance.get(`/api/select/GetproductCategoryForSelect`);

        this.setState({
            productCategories: productCategoryResponse.data,
        });
    }

    async getProductSubCategory() {
        const productSubCategoryResponse = await axiosInstance.get(`/api/select/GetProductSubCategoryForSelect`);

        this.setState({
            productSubcategories: productSubCategoryResponse.data,
        });
    }

    async getProductBrand() {
        const productBrandResponse = await axiosInstance.get(`/api/select/GetBrandsForSelect`);     

        this.setState({
            productBrands: productBrandResponse.data,
        });
    }

    async getProductLaboratory() {
        const productLaboratoryResponse = await axiosInstance.get(`/api/select/GetlaboratoriesForSelect`);

        this.setState({
            productLaboratories: productLaboratoryResponse.data,
        });
    }



    renderProductOptions() {
        return this.state.products.map((dt, i) => {
            return (
                <MenuItem
                    label="Seleccione un producto"
                    value={dt.id}
                    key={i} name={dt.text}>{dt.text}
                </MenuItem>
            );
        });
    }

    renderCategoryOptions() {
        return this.state.productCategories.map((dt, i) => {
            return (
                <MenuItem
                    label="Seleccione una categoría"
                    value={dt.id}
                    key={i} name={dt.text}>{dt.text}
                </MenuItem>
            );
        });
    }

    renderSubCategoryOptions() {
        return this.state.productSubcategories.map((dt, i) => {
            return (
                <MenuItem
                    label="Seleccione una subcategoría"
                    value={dt.id}
                    key={i} name={dt.text}>{dt.text}
                </MenuItem>
            );
        });
    }

    renderLaboratoryOptions() {
        return this.state.productLaboratories.map((dt, i) => {
            return (
                <MenuItem
                    label="Seleccione un laboratorio"
                    value={dt.id}
                    key={i} name={dt.text}>{dt.text}
                </MenuItem>
            );
        });
    }

    renderBrandOptions() {
        return this.state.productBrands.map((dt, i) => {
            return (
                <MenuItem
                    label="Seleccione una marca"
                    value={dt.id}
                    key={i} name={dt.text}>{dt.text}
                </MenuItem>
            );
        });
    }


    render() {

        var pageTypeRedirectID = this.props.pageTypeRedirectID;


        return (
        <div>
            {
                pageTypeRedirectID == 1
                    ?

                        <div className="padd50">
                            <SelectValidator
                                style={{ width: "100%" }}
                                name="productCategoryID"
                                variant="outlined"
                                size="small"
                                label="Categoría"
                                value={this.props.productCategoryID}
                                validators={['required']}
                                errorMessages={['La categoría es requerida']}
                                onChange={this.props.onChange}>
                                {this.renderCategoryOptions()}
                            </SelectValidator>
                        </div>
                    :
                    null
            }

                {
                    pageTypeRedirectID === 8
                        ?
                        <Grid item sm={12} xs={12}>
                            <div className="padd50">
                                <SelectValidator
                                    style={{ width: "100%" }}
                                    name="productSubCategoryID"
                                    variant="outlined"
                                    size="small"
                                    label="Subcategoría"
                                    value={this.props.productSubCategoryID}
                                    validators={['required']}
                                    errorMessages={['La subcategoría es requerida']}
                                    onChange={this.props.onChange}>
                                    {this.renderSubCategoryOptions()}
                                </SelectValidator>
                            </div>
                        </Grid>
                        :
                        null
                }
                {
                    pageTypeRedirectID === 2
                        ?
                        <Grid item sm={12} xs={12}>
                            <div className="padd50">
                                <SelectValidator
                                    style={{ width: "100%" }}
                                    name="productBrandID"
                                    variant="outlined"
                                    size="small"
                                    label="Marca"
                                    value={this.props.productBrandID}
                                    validators={['required']}
                                    errorMessages={['La marca es requerida']}
                                    onChange={this.props.onChange}>
                                    {this.renderBrandOptions()}
                                </SelectValidator>
                            </div>
                        </Grid>
                        :
                        null
                }

                {
                    pageTypeRedirectID === 4
                        ?
                        <Grid item sm={12} xs={12}>
                            <SelectValidator
                                style={{ width: "100%" }}
                                name="productID"
                                variant="outlined"
                                size="small"
                                label="Producto"
                                value={this.props.productID}
                                validators={['required']}
                                errorMessages={['El producto es requerido']}
                                onChange={this.props.onChange}>
                                {this.renderProductOptions()}
                            </SelectValidator>
                        </Grid>
                        :
                        null

                }         

                {
                    pageTypeRedirectID === 9
                        ?
                        <Grid item sm={12} xs={12}>
                            <SelectValidator
                                style={{ width: "100%" }}
                                name="laboratoryID"
                                variant="outlined"
                                size="small"
                                label="Laboratorio"
                                value={this.props.laboratoryID}
                                validators={['required']}
                                errorMessages={['El laboratorio es requerido']}
                                onChange={this.props.onChange}>
                                {this.renderLaboratoryOptions()}
                            </SelectValidator>
                        </Grid>
                        :
                        null

                }     

            </div>

      

        
               

            );
        
    }
}