import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { ClearCacheProvider, useClearCacheCtx } from 'react-clear-cache';




const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

const rootElement = document.getElementById('root');


const theme = createMuiTheme({
    palette: {
        //type:'dark',
        background: {
            default: '#f5f6f8',
        },
        primary: {
            //light: '#ff8a50',
            light: '#ff5003',
            main: '#ff5003',
            //main: '#ff5722',
            //dark: '#c41c00',
            dark: '#0074bc',
            contrastText: '#fff',
            inherit: '#FFF'
        },

      

        secondary: {
            main: '#ee3767'
        },


        
    },
    status: {
        danger: 'orange',
    },
});



ReactDOM.render(
    <ClearCacheProvider auto={true}>

    <BrowserRouter basename={baseUrl}>
        <ThemeProvider theme={theme}>
            <App />
        </ThemeProvider>
        </BrowserRouter>
    </ClearCacheProvider>
        ,
  rootElement);

// Uncomment the line above that imports the registerServiceWorker function
// and the line below to register the generated service worker.
// By default create-react-app includes a service worker to improve the
// performance of the application by caching static assets. This service
// worker can interfere with the Identity UI, so it is
// disabled by default when Identity is being used.
//
//registerServiceWorker();

